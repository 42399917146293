class providecnic {
  constructor(msg) {
    this.items = {
      id: 30001,
      name: "Custom Vehicle Make",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40012,
          text: "Submit",
          name: "Submit",
          type: "providecnicConfirm",
          desc: msg.desc,
          inputtype: "textinput",
        },
      ],
      backtostart: false,
      text: [`<p>Please provide your CNIC Number.</p> `],
    };
  }

  getAllItems() {
    console.log(sessionStorage.getItem("car_information"));
    return this.items;
  }
}

export default providecnic;
