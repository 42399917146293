class paymentoptions {
  constructor(data) {
    const price = data.price;
    if (data.quotetype) {
      sessionStorage.setItem("insuranceType", data.quotetype);
    }
    this.price = price;
    console.log("data ==>", data);
    if (data.quote) {
      console.log("This is my date", data);
      let makeSession = [
        { key: "type", value: data.quote.type },
        { key: "make", value: data.quote.make },
        { key: "model", value: data.quote.model },
        { key: "year", value: data.quote.year },
        { key: "carvalue", value: data.quote.carvalue },
        { key: "is_gapinsurance", value: data.quote.is_gapinsurance },
      ];
      this.quotetype = data.quotetype;
      sessionStorage.setItem("car_information", JSON.stringify(makeSession));
    }
    this.items = {
      id: 30001,
      name: "Payment Options",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40012,
          text: "Pay Now in Full",
          name: "Pay Now in Full",
          price: price,
          type: "insurancepayment",
          // type: "additionalinfo",
          desc: "Pay Now in Full",
          inputtype: "button",
          key: "is_installment",
          value: 0,
        },
        {
          id: 40012,
          text: "Pay by Installments",
          name: "Pay by Installments",
          price: price,
          type: "insurancepayment",
          // type: "additionalinfo",
          desc: "Pay by Installments",
          inputtype: "button",
          key: "is_installment",
          value: 1,
        },
      ],
      backtostart: false,
      text: [`<p>Please choose your payment option:</p>`],
    };
  }

  getAllItems() {
    console.log("type ", this.quotetype);
    if (this.quotetype === "thirdparty") {
      this.items.options = [
        {
          id: 40012,
          text: "Buy Now",
          name: "Buy Now",
          price: this.price,
          type: "insurancepayment",
          // type: "additionalinfo",
          desc: "Buy Now",
          inputtype: "button",
          key: "is_installment",
          value: 0,
        },
      ];
      return this.items;
    } else return this.items;
  }
}

export default paymentoptions;
