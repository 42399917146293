import { sendEmail } from "../../services/api";
class feedbackBad {
  constructor() {
    this.items = {
      id: 50001,
      name: "About",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 71100,
          text: "Un-satisfactory Expert behaviour",
          name: "Un-satisfactory",
          type: "ComplaintEnd",
          desc: "Un-satisfactory Expert behaviour",
        },
        {
          id: 71101,
          text: "Poor Video or Voice quality",
          name: "Poor Video or Voice quality",
          type: "ComplaintEnd",
          desc: "Poor Video or Voice quality",
        },
        {
          id: 71102,
          text: "Technical issue",
          name: "Technical issue",
          type: "ComplaintEnd",
          desc: "Technical issue",
        },
      ],
      backtostart: true,
      text: `I am sorry for your experience. We are doing our best to improve our services. You can make a complaint, and my manager will investigate this and revert. `,
    };
  }
  async getAllItems() {
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));

    try {
      if (!sessionUser) {
        return this.items;
      }

      const message = `
           Please call the 
           mentioned number and resolve the complaint.
           User Number: ${sessionUser.userMsisdn}
           User Name: ${sessionUser.userName}
         `.trim();

      const response = await sendEmail(
        "support@webdoc.com.pk",
        "Agri Expert Complaint",
        message
      );
      console.log("Complaint generated message:", message);
      console.log("Complaint generated successfully:", response);
      return this.items;
    } catch (error) {
      console.error("Failed to generate complaint:", error);
      // You might want to modify this.items to show an error message
      this.items.text = "Failed to generate complaint. Please try again later.";
      return this.items;
    }
  }
}

export default feedbackBad;
