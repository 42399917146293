import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import Header_packages_chat from "../components/Header_package_chat";
import axios from "axios";
import ChatLoader from "../components/ChatLoader";
import HandleMessage from "../components/carinsurance/HandleMessage";
import HandleOptions from "../components/carinsurance/HandleOptions";
import CameraComponent from "../components/carinsurance/camera";
import getDataCarInsurance from "../modals/carinsurance/getcarinsurancemodel";
import Swal from "sweetalert2";
import getPakistanGreeting from "../components/rsachat/greetingMessage";
import { useLocation } from "react-router-dom";
import { checkInsurance } from "../services/api";

const CarInsuranceChat = () => {
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [userData, setUserData] = useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const [sideimage, setSideImage] = useState("/doctorassistant/dia.jpg");
  const [healthData, setHealthData] = useState(null);
  const [error, setError] = useState(null);

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const openId = params.get("openId");
    if (openId) {
      console.log("OpenId from URL:", openId);
      // You can use the openId here as needed
    }
  }, [location]);

  // useEffect(() => {
  //   // sessionStorage.removeItem("user");
  //   const sessionUser = JSON.parse(sessionStorage.getItem("user"));
  //   // console.log(sessionUser);
  //   if (sessionUser) {
  //     setUserData(sessionUser);
  //   } else {
  //     authenticateUser();
  //   }
  // }, []);

  const checkInsuranceStatus = async (openId) => {
    try {
      const response = await checkInsurance(openId, "Car");
      console.log("Insurance check response:", response);
      setHealthData(response);
      return response; // Return the response so we can check it in useEffect
    } catch (err) {
      console.error("Insurance check error:", err);
      setError(err.message);
      return null;
    }
  };

  useEffect(() => {
    const verifyInsuranceAuth = async () => {
      try {
        const params = new URLSearchParams(location.search);
        const openId = params.get("openId");

        if (!openId) {
          console.log("No openId found, proceeding with authentication");
          authenticateUser();
          return;
        }

        const response = await checkInsuranceStatus(openId);
        console.log("Insurance Status:", response);

        // Check if we have a valid response with openId
        if (response && response.openId) {
          console.log("Valid user data found, setting session");
          sessionStorage.setItem("user", JSON.stringify(response));
          setUserData(response);
          await loadData("start");
        } else {
          console.log(
            "No valid user data found, proceeding with authentication"
          );
          authenticateUser();
        }
      } catch (error) {
        console.error("Error during insurance verification:", error);
        authenticateUser();
      }
    };

    verifyInsuranceAuth();
  }, [location.search]);

  const authenticateUser = () => {
    const scopes = [
      "USER_MSISDN",
      "USER_NAME",
      "OPEN_ID",
      "UNION_ID",
      "USER_BIRTHDAY",
    ];

    const getUserAuthCodeResult = (jsonResult) => {
      const result = JSON.parse(jsonResult);
      try {
        if (result.status === "SUCCESS") {
          const form = new FormData();
          form.append("value", result.authCode);
          form.append("apikey", "Car");

          axios
            .post(
              "https://miniappeasypaisa.webddocsystems.com/public/api/v1/insurance-auth",
              form,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              if (response.data.openId) {
                sessionStorage.setItem("user", JSON.stringify(response.data));
                setUserData(response.data);
                setTimeout(async () => {
                  await loadData("start");
                }, 2000);
              } else {
                Swal.fire({
                  icon: "info",
                  allowOutsideClick: false,
                  title: "Something went wrong. Please try again later!",
                  confirmButtonText: "Retry",
                }).then(() => {
                  authenticateUser();
                });
              }
              // sessionStorage.setItem("user", JSON.stringify(response.data));
              // setUserData(response.data);
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          Swal.fire({
            allowOutsideClick: false,
            title: "Please Share the information for Services",
            confirmButtonText: "Retry",
          }).then((result) => {
            if (result.isConfirmed) {
              window.mp.getUserAuthCode(scopes);
            } else {
              console.log("User denied the request");
            }
          });
        }
      } catch (error) {
        console.error("Error fetching doctor profile:", error);
      }
    };

    window.mp.getUserAuthCode(scopes);

    window.getUserAuthCodeResult = getUserAuthCodeResult;
  };

  useEffect(() => {
    let item = {
      id: 1,
      name: "Start Text",
      type: "text",
      options: [
        {
          id: 40012,
          text: "Connect to the Doctor",
          name: "Connect to the Doctor",
          price: "",
          type: "doctor",
          desc: "I’d like to connect with a doctor.",
        },
        {
          id: 40012,
          text: "About us",
          name: "About us",
          price: "",
          type: "about",
          desc: "About us",
        },
        {
          id: 40011,
          text: "FAQs",
          name: "FAQ",
          price: "",
          type: "faqs",
          desc: "FAQ",
        },
      ],
      backtostart: false,
      img: "/doctorassistant/dia.jpg",
      slideimg: "/doctorassistant/dia.jpg",
      display: "Natasha",
      position: "Customer Executive",
      text: [
        `<p>${getPakistanGreeting()}! I’m Natasha, your car insurance expert. Vehicles are a major investment, and car insurance provides essential financial protection against accidents, theft, or damages. It covers repair costs, medical expenses, and legal liabilities, ensuring peace of mind during unexpected events. Let me help you secure your valuable asset!</p>`,
      ],
    };

    setMessages((prevMessages) => [...prevMessages, item]);
    // setIsLoading(true)
    setLoading(true);
  
  }, []);

  const startagain = async (type) => {
    let item = {
      id: 1,
      name: "Start Text",
      type: "text",
      options: [
        {
          id: 40012,
          text: "Connect to the Doctor",
          name: "Connect to the Doctor",
          price: "",
          type: "doctor",
          desc: "I’d like to connect with a doctor.",
        },
        {
          id: 40012,
          text: "About us",
          name: "About us",
          price: "",
          type: "about",
          desc: "About us",
        },
        {
          id: 40011,
          text: "FAQs",
          name: "FAQ",
          price: "",
          type: "faqs",
          desc: "FAQ",
        },
      ],
      backtostart: false,
      slideimg: "",
      img: "/doctorassistant/dia.jpg",
      slideimg: "/doctorassistant/dia.jpg",
      display: "Natasha",
      position: "Customer Executive",
      text: [
        `<p>${getPakistanGreeting()}! I’m Natasha, your car insurance expert. Vehicles are a major investment, and car insurance provides essential financial protection against accidents, theft, or damages. It covers repair costs, medical expenses, and legal liabilities, ensuring peace of mind during unexpected events. Let me help you secure your valuable asset!</p>`,
      ],
    };

    setMessages([]); // Clears all messages
    setMessages((prevMessages) => [...prevMessages, item]);
    await loadData(type, null);
  };
  const loadData = async (type, data) => {
    //alert(diseasename)
    const newMessage = await getDataCarInsurance(type, data);
    // console.log(newMessage.modal);
    setLoading(false);

    setMessages((prevMessages) => [...prevMessages, newMessage.modal]);

    if (newMessage.modal.options && newMessage.modal.options.length > 0) {
      newMessage.modal.options.forEach((option) => {
        option.isOption = true;
        option.sender = "Receiver";
        setMessages((prevMessages) => [...prevMessages, option]);
      });
    }
  };

  const handleOptionSelect = async (msg) => {
    setLoading(true); // Show loader first

    console.log(msg);
    setMessages((prevMessages) => prevMessages.filter((msg) => !msg.isOption));
    if (msg.key) {
      if (sessionStorage.getItem("car_information")) {
        // Retrieve and parse the existing data
        let temp = JSON.parse(sessionStorage.getItem("car_information"));
        if (
          msg.type == "thirdpartytheft" ||
          msg.type == "thirdparty" ||
          msg.type == "comprehensive"
        ) {
          sessionStorage.setItem("insuranceType", msg.type);
        }

        // Ensure temp is an array before pushing
        if (Array.isArray(temp)) {
          temp.push({
            key: msg.key,
            value: msg.value,
          });
        } else {
          // If it's not an array (just in case), convert it to an array first
          temp = [
            {
              key: msg.key,
              value: msg.value,
            },
          ];
        }

        sessionStorage.setItem("car_information", JSON.stringify(temp));
      } else {
        // If "car_information" doesn't exist, initialize the array and store it
        const temp = [
          {
            key: msg.key,
            value: msg.value,
          },
        ];
        sessionStorage.setItem("car_information", JSON.stringify(temp));

        if (
          msg.type == "thirdpartytheft" ||
          msg.type == "thirdparty" ||
          msg.type == "comprehensive"
        ) {
          sessionStorage.setItem("insuranceType", msg.type);
        }
      }
    }

    if (msg.type === "opencamera") {
      setShowCamera(true);
      setLoading(false);
    } else {
      // Remove all option messages before doing anything

      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "You", text: [msg.desc] },
      ]);

      setTimeout(() => {
        loadData(msg.type, msg);
      }, 2000); // 2 second delay
    }
  };

  const handleTextSubmit = async (msg, text) => {
    setLoading(true);
    // console.log(text);
    setMessages((prevMessages) => prevMessages.filter((msg) => !msg.isOption));
    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: "You", text: [text] },
    ]);
    //msg.desc=text;
    msg.inputtext = text;
    if (msg.type === "confirmprice") {
      msg.price = text;
    }

    setTimeout(() => {
      loadData(msg.type === "vehicleprice" ? "confirmprice" : msg.type, msg);
    }, 2000);
  };
  return (
    <>
      <Header_packages_chat title="car" />
      <Container>
        <Row>
          <Col className="chat_container" md={12}>
            <div className="chat_window pt-2">
              <div className="messages">
                <HandleMessage messages={messages} startagain={startagain} />
                <>
                  <HandleOptions
                    messages={messages}
                    handleOptionSelect={handleOptionSelect}
                    handleTextSubmit={handleTextSubmit}
                  />
                </>
                {showCamera && (
                  <CameraComponent
                    messages={messages}
                    setMessages={setMessages}
                    setShowCamera={setShowCamera}
                    setLoading={setLoading}
                  />
                )}
                {loading && <ChatLoader />}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CarInsuranceChat;
