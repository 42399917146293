import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BsDownload } from "react-icons/bs";
import { Container, Row, Col } from "react-bootstrap";
import Header_packages from "../components/Header_packages";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "./prescription.css";

import getPrescriptionmodals from "../modals/agrichatmodal/precription";

const AgriChatPrescription = ({ messages, setMessages }) => {
  const [prescriptionData, setPrescriptionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
  const componentRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      const session = JSON.parse(sessionStorage.getItem("user"));
      let sessionUserId = session?.applicationUserId;
      // let sessionUserId = "3fc1468d-6568-447f-bb0f-59c52d3022bd";

      try {
        const response = await fetch(
          `https://miniappeasypaisa.webddocsystems.com/public/api/v1/agri-consultation?customer_id=${sessionUserId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch prescription data");
        }
        const data = await response.json();
        if (data.payLoad) {
          setPrescriptionData(data.payLoad);
        } else if (data.statusCode === 3) {
          setPrescriptionData(null);
        } else {
          throw new Error("Invalid response format");
        }
      } catch (error) {
        console.error("Error fetching prescription data", error);
      } finally {
        let obj = new getPrescriptionmodals();
        let msg = await obj.getAllItems();
        console.log(obj);
        console.log("this is message ", msg);
        setTimeout(() => {
          setMessages((prevMessages) => [...prevMessages, msg.modal]);

          if (msg.modal.options && msg.modal.options.length > 0) {
            msg.modal.options.forEach((option) => {
              option.isOption = true;
              option.sender = "Receiver";
              setMessages((prevMessages) => [...prevMessages, option]);
            });
          }
        }, 2000);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const downloadPdf = async () => {
    try {
      const element = componentRef.current;
      const canvas = await html2canvas(element, {
        scrollY: -window.scrollY,
      });
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const pdf = new jsPDF({
        orientation: "p",
        unit: "px",
        format: "a4",
      });

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = pdfWidth - 20;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let heightLeft = imgHeight;
      let position = 0;

      const headerText = "Prescription Details";
      const margin = 20;
      pdf.text(headerText, 10, 10);
      pdf.addImage(imgData, "JPEG", 10, margin, imgWidth, imgHeight);
      heightLeft -= pdfHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "JPEG", 10, position + 10, imgWidth, imgHeight);
        heightLeft -= pdfHeight;
      }

      pdf.save("prescription.pdf");
    } catch (error) {
      console.error("Error generating PDF", error);
    }
  };
  console.log("this is agri prescription data", prescriptionData);
  return (
    <Container className="overflow-auto border">
      <div className="mt-2 border border-dark">
        <Row className="p-2">
          <div ref={componentRef}>
            {loading ? (
              <p>Preparing consultation...</p>
            ) : prescriptionData ? (
              <section className="inner-frame1">
                <div className="sub-frame-a1">
                  <div className="now-previous-frames">
                    <div className="video-call-frame1">
                      <Col xs={12}>
                        <div className="a-f-b-myco-frames">
                          <Row xs={12}>
                            <Col xs={4}>
                              <p className="diagnosis1">Doctor Name</p>
                              <p className="diagnosis1">Time</p>
                            </Col>
                            <Col xs={8}>
                              {prescriptionData && (
                                <>
                                  <p className="diagnosisClass text-capitalize">
                                    {`${prescriptionData.FirstName} ${prescriptionData.LastName}`}
                                  </p>
                                  <p className="diagnosisClass text-capitalize">
                                    {prescriptionData.DateTimeStamp}
                                  </p>
                                </>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      {prescriptionData && (
                        <div className="complaint-consultation-test-fr">
                          <Row xs={12}>
                            <Col xs={6}>
                              <p className="diagnosis1">Title</p>
                              <p className="diagnosis1">Diagnosis</p>
                              <p className="diagnosis1">Medication & Herbs</p>
                            </Col>
                            <Col xs={6}>
                              <p className="diagnosisClass  text-capitalize">
                                {prescriptionData.Title || "N/A"}
                              </p>
                              <p className="diagnosisClass text-capitalize">
                                {prescriptionData.Diagnosis || "N/A"}
                              </p>
                              <p className="diagnosisClass text-capitalize">
                                {prescriptionData.MedicationAndHerbal || "N/A"}
                              </p>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              <p>No prescription data available.</p>
            )}
          </div>
        </Row>
      </div>
    </Container>
  );
};

export default AgriChatPrescription;
