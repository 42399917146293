import axios from "axios";
class registration {
  constructor(data) {
    this.items = {
      id: 30001,
      name: "Start Text",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      // options: [
      //   // {
      //   //   id: 40012,
      //   //   text: "Share Location",
      //   //   name: "Share Location",
      //   //   price: "",
      //   //   type: "location",
      //   //   desc: "Share Location",
      //   //   key: "location",
      //   // },
      //   {
      //     id: 40012,
      //     text: "Share Address",
      //     name: "Share Address",
      //     price: "",
      //     type: "location",
      //     desc: "",
      //     inputtype: "textinput",
      //     key: "address",
      //   },
      // ],
      options: [
        {
          id: 40012,
          text: "Registration No",
          name: "Registration No",
          price: "",
          type: "payment",
          desc: "",
          inputtype: "textinput",
          key: "registration_no",
        },
      ],

      backtostart: false,
      text: ["Please Share your vehicle registration number!"],
    };
  }

  async getAllItems() {
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    const existingChat = JSON.parse(sessionStorage.getItem("RSA-Chat"));

    try {
      console.log(existingChat);
      const response = await axios.post(
        "https://webservice.rsapakistan.com/api/Account/GetActiveInstantPackages",
        { customerId: sessionUser.applicationUserId },
        { headers: { "Content-Type": "application/json" } }
      );
      if (response.data.responseCode === "0000") {
        console.log(response.data?.packagesDetails[0]);
        existingChat.push({
          key: "registration_no",
          value: response.data?.packagesDetails[0].registrationNumber,
        });
        sessionStorage.setItem("RSA-Chat", JSON.stringify(existingChat));
        sessionStorage.setItem(
          "payment-info",
          JSON.stringify({
            buyPackageId: response.data?.packagesDetails[0].buyPackageId,
          })
        );
        this.items = {
          id: 40012,
          name: "Start Text",
          type: "text",
          slideimg: "/doctorassistant/dia.jpg",
          options: [
            {
              id: 40012,
              text: "Share Address",
              name: "Share Address",
              price: "",
              type: "location",
              desc: "",
              inputtype: "textinput",
              key: "address",
            },
          ],
          backtostart: false,
          text: [
            `No need to worry, we're on our way. If possible, please park to the side. If you're on the roadside, stay in a safe place—your safety is our top priority.`,
            ` Please share your location ! `,
          ],
        };
        return this.items;
      } else return this.items;
    } catch (error) {
      console.log("registration error", error);
      return this.items;
    }
  }
}

export default registration;
