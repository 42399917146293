import apiPayload from "../../components/rsachat/apiPayload";
class good {
    constructor(data) {
      const sessionUser = JSON.parse(sessionStorage.getItem("user"));
      this.items = {
        id: 30001,
        name: "Start Text",
        type: "text",
        slideimg: "/doctorassistant/dia.jpg",
        options: [
        ],
        backtostart: true,
        text: [
          `<p>Thank you for sharing your feedback with RSA Pakistan. We strive to improve our services and would love to know how we can make your experience even better. Feel free to share your thoughts!</p> `,
        ],
      };
    }
  
    async getAllItems() {
      try {
        const sessionUser = JSON.parse(sessionStorage.getItem("user"));
        const chatDetails = JSON.parse(sessionStorage.getItem("RSA-Chat"));
        apiPayload.applicationUserId = sessionUser.applicationUserId;
        apiPayload.misidn = sessionUser.userMsisdn;
        chatDetails.forEach(({ key, value }) => {
          apiPayload[key] = value;
        });
        console.log("API payload Response:>", JSON.stringify(apiPayload));
  
        const apiUrl =
          "https://Digital.webdoc.com.pk/Promotion/public/api/v1/chat/rsa-insurance/save";
        const response = await fetch(apiUrl, {
          method: "POST",
          body: JSON.stringify(apiPayload),
        });
        console.log("API Response:", response);
      } catch (error) {
        console.error("Error sending data to API:", error);
      }
  
      return this.items;
    }
  }
  
  export default good;
  