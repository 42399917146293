import { toWords } from "number-to-words";

class gapinsurance {
  constructor(data) {
    const insType = sessionStorage.getItem("insuranceType");
    this.insType = insType;
    const price = data.price;
    const marketValue = parseFloat(price);
    let gapInsuranceCost;
    let installmentCost;
    if (insType == "comprehensive") {
      if (data.text == "Yes, I need GAP insurance") {
        gapInsuranceCost = marketValue * 0.015;
      } else {
        gapInsuranceCost = marketValue * 0.013;
      }
      installmentCost = (gapInsuranceCost * 1.22) / 12;
    } else if (insType == "thirdpartytheft") {
      // if (data.text == "Yes, I need GAP insurance") {
      //   gapInsuranceCost = marketValue * 0.012 + 2000;
      // } else {
      //   gapInsuranceCost = marketValue * 0.01;
      // }
      gapInsuranceCost = marketValue * 0.01;
      installmentCost = (gapInsuranceCost * 1.22) / 12;
    } else if (insType == "thirdparty") {
      gapInsuranceCost = 5000;
    }

    const formattedInsuranceCost =
      Math.round(gapInsuranceCost).toLocaleString("en-US");
    const insuranceCostInWords = toWords(Math.round(gapInsuranceCost));
    let textArray = [
      `<p>
        The cost of insurance is calculated as Rs. ${formattedInsuranceCost} (Rupees ${insuranceCostInWords} Only)
      </p>`,
    ];

    // Conditionally add the installment cost paragraph if insType is not "thirdparty"
    if (insType !== "thirdparty") {
      textArray.push(
        `<p>
          If you choose to pay in monthly instalments over a year, each payment will be ${Math.round(
            installmentCost
          ).toLocaleString("en-US")} (Rupees ${toWords(
          Math.round(installmentCost)
        )} Only). This amount will be deducted on recurring basis .
        </p>`
      );
    }
    this.items = {
      id: 30001,
      name: "GAP Insurance",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: this.getOptions(insType, price, gapInsuranceCost),
      backtostart: true,
      text: textArray,
    };
  }

  // Get options dynamically based on the insurance type
  getOptions(insType, price, gapInsuranceCost) {
    if (insType === "comprehensive" || insType === "thirdpartytheft") {
      return [
        {
          id: 40012,
          text: "Buy Now",
          name: "Buy Now",
          price: Math.round(price),
          type: "paymentoptions",
          desc: "Buy Now",
          inputtype: "button",
        },
        {
          id: 40012,
          text: "Find me a better price",
          name: "Find me a better price",
          price: Math.round(price),
          type: "promotions",
          desc: "Find me a better price",
          inputtype: "button",
          key: "is_luckydraw",
          value: 1,
        },
        {
          id: 40012,
          text: "Save Quote",
          name: "Save Quote",
          price: Math.round(price),
          type: "savequotation",
          desc: "Save Quote",
          inputtype: "button",
          key: "qouteprice",
          value: Math.round(gapInsuranceCost),
        },
      ];
    } else if (insType === "thirdparty") {
      return [
        {
          id: 40012,
          text: "Buy Now",
          name: "Buy Now",
          price: price,
          type: "insurancepayment",
          // type: "additionalinfo",

          desc: "Buy Now",
          inputtype: "button",
        },
        {
          id: 40012,
          text: "Save Quote",
          name: "Save Quote",
          price: price,
          type: "savequotation",
          desc: "Save Quote",
          inputtype: "button",
          key: "qouteprice",
          value: gapInsuranceCost,
        },
      ];
    }
    // Default case if insType is not recognized
    return [];
  }

  getAllItems() {
    console.log(sessionStorage.getItem("car_information"));
    return this.items;
  }
}

export default gapinsurance;
