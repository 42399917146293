import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row, Button, Form, Card } from "react-bootstrap";
import TextInputOption from "./TextInputOption";
import "./rsa-chat.css";
const HandleRsaOptions = ({
  messages,
  handleOptionSelect,
  handleTextSubmit,
}) => {
  const [loading, setLoading] = useState(false);
  // console.log(messages);

  const optionMessages = messages.filter((msg) => msg.isOption);

  if (!optionMessages || optionMessages.length === 0) {
    return null;
  }
  return (
    <>
      <div className="row">
        {optionMessages.map((msg, index) => (
          <React.Fragment key={index}>
            {msg.inputtype === "button" ? (
              <Button
                key={index}
                variant="secondary"
                style={{ backgroundColor: "#1bb367" }}
                className="border-0 w-75 mb-2 chatOptions"
                onClick={() => handleOptionSelect(msg)}
              >
                {msg.text}
              </Button>
            ) : msg.inputtype === "textinput" ? (
              <TextInputOption
                key={index}
                msg={msg}
                handleTextSubmit={handleTextSubmit}
              />
            ) : msg.price && msg.type === "updatePlan" ? (
              <div
                className={`col-12 col-md-6 col-lg-4 d-flex justify-content-center px-4 gy-2 mb-2`}
                onClick={() => handleOptionSelect(msg)}
              >
                <Card
                  className="border border-success"
                  style={{
                    width: "40rem",
                    height: "14rem",
                  }}
                >
                  <Card.Body className="text-center d-flex flex-column">
                    {/* Package Name */}
                    <Card.Title className="text-truncate">
                      {msg.name}
                    </Card.Title>

                    {/* Price */}
                    <Card.Text className="mt-1">
                      <strong style={{ fontSize: "15px" }}>
                        Rs {msg.price}
                      </strong>
                    </Card.Text>

                    {/* Description */}
                    <div
                      className="text-muted text-start card-description"
                      dangerouslySetInnerHTML={{ __html: msg.desc }}
                    />
                  </Card.Body>
                </Card>
              </div>
            ) : (
              <div className="col-12">
                <div className="p-0">
                  {/* Dynamically render button or input */}
                  {msg.inputtype === "text" ? (
                    <Form.Control
                      type="text"
                      placeholder="Enter your text here"
                      className="w-75 mb-2"
                      onBlur={() => handleOptionSelect(msg)} // Handle when user finishes input
                    />
                  ) : (
                    <Button
                      variant="secondary"
                      style={{ backgroundColor: "#1bb367" }}
                      className="border-0 w-75 mb-2 chatOptions"
                      onClick={() => handleOptionSelect(msg)}
                    >
                      <p className="mb-0">{msg.name}</p>
                    </Button>
                  )}
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default HandleRsaOptions;
