class comprehensive {
  constructor() {
    this.items = {
      id: 30001,
      name: "Comprehensive",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40012,
          text: "Toyota",
          name: "Toyota",
          type: "vehiclemake",
          desc: "Toyota",
          inputtype: "button",
          key: "make",
          value: "Toyota",
        },
        {
          id: 40012,
          text: "Audi",
          name: "Audi",
          type: "vehiclemake",
          desc: "Audi",
          inputtype: "button",
          key: "make",
          value: "Audi",
        },
        {
          id: 40012,
          text: "Mercedez",
          name: "Mercedez",
          type: "vehiclemake",
          desc: "Mercedez",
          inputtype: "button",
          key: "make",
          value: "Mercedez",
        },
        {
          id: 40012,
          text: "Honda",
          name: "Honda",
          type: "vehiclemake",
          desc: "Honda",
          inputtype: "button",
          key: "make",
          value: "Honda",
        },
        {
          id: 40011,
          text: "Suzuki",
          name: "Suzuki",
          type: "vehiclemake",
          desc: "Suzuki",
          inputtype: "button",
          key: "make",
          value: "Suzuki",
        },
        {
          id: 40011,
          text: "Kia",
          name: "Kia",
          type: "vehiclemake",
          desc: "Kia",
          inputtype: "button",
          key: "make",
          value: "Kia",
        },
        {
          id: 40011,
          text: "Hyundai",
          name: "Hyundai",
          type: "vehiclemake",
          desc: "Hyundai",
          inputtype: "button",
          key: "make",
          value: "Hyundai",
        },
        {
          id: 40011,
          text: "Other",
          name: "Other",
          type: "vehiclemake",
          desc: "Other",
          inputtype: "textinput",
          key: "make",
          value: "Other",
        },
      ],
      backtostart: false,
      text: [
        `<p>It is a type of car insurance that provides extensive coverage, protecting both the policyholder’s vehicle and third parties. It covers damages caused by accidents, theft, natural disasters, vandalism, and other unforeseen incidents. This is high-end insurance policy</p> `,
        `<p>Great! You clearly care a lot about your vehicle, and we understand it’s a valuable investment. To assist you better, could you please tell us the make of your vehicle? `,
      ],
    };
  }

  getAllItems() {
    console.log(sessionStorage.getItem("car_information"));
    return this.items;
  }
}

export default comprehensive;
