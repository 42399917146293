class comprehensiveinfo {
  constructor(data) {
    // const price = data.desc;
    console.log("This is my date", data);
    this.items = {
      id: 30001,
      name: "Additional Cover",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [],
      backtostart: true,
      text: [
        `<p>Thank you! We are now preparing your car insurance policy. Inspection of your vehicle is the mandatory
        process. We will connect you soon.</p>`,
        `<p>Congratulation! Your car insurance cover note is now ready.</p>`,
        `<p>Please provide your email address so we can share the details of the insurance policy you’ve purchased.</p>`,

        //   `<p><a href="https://www.example.com" target="_blank">Click here</a>.</p>`,
        //   `<p>Please provide feedback to improve our services</p> `,
      ],
    };

    // Retrieve car information from sessionStorage
    const carInformation = JSON.parse(
      sessionStorage.getItem("car_information")
    );
    const user = JSON.parse(sessionStorage.getItem("user"));

    if (carInformation && user) {
      this.carInformation = {
        userMsisdn: user.userMsisdn,
        applicationUserId: user.applicationUserId,
        ...carInformation.reduce((acc, item) => {
          acc[item.key] = item.value;
          return acc;
        }, {}),
        status: "Active",
      };
    }
  }

  async getAllItems() {
    const savedItems = await this.saveToAPI();
    return savedItems;
  }

  getFaildOptions() {
    return [
      {
        id: 40012,
        text: "Buy Now",
        name: "Buy Now",
        price: "",
        type: "paymentoptions",
        desc: "Buy Now",
        inputtype: "button",
      },
    ];
  }

  getSuccessOptions() {
    return [
      {
        id: 40012,
        text: "Excellent",
        name: "Excellent 🙂",
        price: "",
        type: "isexcellent",
        desc: "Excellent 🙂",
      },
      {
        id: 40012,
        text: "Good",
        name: "Satisfactory 😐",
        price: "",
        type: "isgood",
        desc: "Satisfactory 😐",
      },
      {
        id: 40012,
        text: "Bad",
        name: "Unsatisfactory 🙁",
        price: "",
        type: "isbad",
        desc: "Unsatisfactory 🙁",
      },
    ];
  }

  async saveToAPI() {
    try {
      const response = await fetch(
        "https://Digital.webdoc.com.pk/Promotion/public/api/v1/chat/car-insurance/update-policy",
        {
          method: "POST",
          body: JSON.stringify(this.carInformation),
        }
      );
      console.log("response =>", response);
      if (response.ok) {
        // console.log("Data saved successfully", this.getSuccessOptions());
        const result = await response.json();
        console.log("result =>", result);

        const baseUrl = window.location.origin;
        this.items.text = [
          `<p>Thank you! We are now preparing your car insurance policy. Inspection of your vehicle is the mandatory
        process. We will connect you soon.</p>`,
          `<p>Congratulation! Your car insurance cover note is now ready.</p>`,
          `Please provide your email address so we can share the details of the insurance policy you’ve purchased.`,
          // `<p><a href="${baseUrl}/car-insurance-policy-paper/1" target="_blank">Click here</a>.</p>`,
          // `<p>Please provide feedback to improve our services</p> `,
        ];
        this.items.issuccess = true;
        if (this.insType === "thirdparty") {
          this.items.options = this.getFaildOptions();
        } else {
          this.items.options = [
            {
              id: result?.payLoad.id,
              text: "Submit",
              name: "New Policy",
              type: "policy",
              desc: this.items.desc,
              inputtype: "textinput",
            },
          ];
        }
        return this.items;
      } else {
        this.items.text = ["Failed to save data."];
        this.items.issuccess = false;
        if (this.insType === "thirdparty") {
          this.items.options = this.getFaildOptions();
        } else {
          this.items.options = [
            {
              id: 40012,
              text: "Confirm",
              name: "Confirm",
              price: "",
              type: "providecnic",
              desc: "Confirm",
              inputtype: "button",
              key: "car_color",
            },
          ];
        }
        return this.items;
      }
    } catch (error) {
      console.error("Error saving data:", error);
      this.items.text = ["Failed to save policy."];
      this.items.issuccess = false;
      if (this.insType === "thirdparty") {
        this.items.options = this.getFaildOptions();
      }
      this.items.options = [
        {
          id: 40012,
          text: "Confirm",
          name: "Confirm",
          price: "",
          type: "providecnic",
          desc: "Confirm",
          inputtype: "button",
          key: "car_color",
        },
      ];
      return this.items;
    }
  }
}

export default comprehensiveinfo;
