class about {
  constructor(data) {
    this.items = {
      id: 30001,
      name: "About",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40012,
          text: "Need RSA mechanic services",
          name: "Need RSA mechanic services",
          price: "",
          type: "car",
          desc: "I’d like to get the RSA mechanic services",
        },
      ],
      backtostart: true,
      text: [
        `<p><strong><span style="color: #ef7e0e;">RSA</span></strong> 
       Roadside assistance is a service designed to help drivers when their
vehicle breaks down or encounters issues while on the road. It
typically includes, Towing, Jump-start, Flat tyre assistance, Fuel
delivery, Lockout service, Minor mechanical repairs.
</br>
<strong>Towing –</strong> If your car cannot be repaired on the spot, it will be towed to a garage or service center.<br>
<strong>Jump-starting –</strong> If your battery is dead, the service provider can jump-start it.<br>
<strong>Flat tire assistance –</strong> Help with changing a flat tire or inflating it if needed.<br>
<strong>Fuel delivery –</strong> If you run out of fuel, they can bring enough to get you to the nearest station.<br>
<strong>Lockout service –</strong> If you're locked out of your car, they can unlock it for you.<br>
<strong>Minor mechanical repairs –</strong> Quick fixes that can be done on the spot to get your car moving again.
</p>`,
      ],
    };
  }

  getAllItems() {
    return this.items;
  }
}

export default about;
