class Location {
  constructor(data) {
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));

    this.items = {
      id: 30001,
      name: data.name,
      type: data.type,
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40012,
          text: "Once your car is fixed, please continue!",
          name: "Once your car is fixed, please continue!",
          price: "",
          type: "arrived",
          desc: `<p>continue</p>`,
        },
        {
          id: 40012,
          text: "Read more safety instructions",
          name: "Read more safety instructions",
          price: "",
          type: "safety",
          desc: "Read more safety instructions",
        },
      ],
      backtostart: false,
      desc: data.desc,
      coords: data.coords,
      text: [`<p>Hold Tight! Don't worry. We are on the way.</p>`],
    };
  }

  async getAllItems() {
    const info = JSON.parse(sessionStorage.getItem("payment-info"));
    const users = JSON.parse(sessionStorage.getItem("user"));
    const services = JSON.parse(sessionStorage.getItem("RSA-Chat"));
    const serviceId = services?.find((service) => service.key === "issue")?.id;
    const regNo = services?.find(
      (service) => service.key === "registration_no"
    )?.value;

    const rescuePayload = {
      serviceId: serviceId,
      customerId: users.applicationUserId,
      buyPackageRemarks: regNo,
      buyPackageId: info.buyPackageId,
      additionalNotes: this.items.desc,
      lat: String(this.items.coords.latitude),
      lng: String(this.items.coords.longitude),
    };
    try {
      const res = await fetch(
        "https://webservice.rsapakistan.com/api/Account/RescueRequest",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(rescuePayload),
        }
      );
      console.log("resposne ==", await res.json());
      return this.items;
    } catch (error) {
      console.error("Error in rescueAPI:", error);
      // this.items.text = [`<p>Unable to fetch your location.</p>`];
      this.items.options = this.locationOptions();
      return this.items; // Return items even if location fetching fails
    }
  }
  locationOptions() {
    return [
      {
        id: 40012,
        text: "Share Address",
        name: "Share Address",
        price: "",
        type: "location",
        desc: "",
        inputtype: "textinput",
        key: "address",
      },
    ];
  }
}

export default Location;
