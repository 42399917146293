class safety {
  constructor(data) {
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    this.items = {
      id: 30001,
      name: "Start Text",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40012,
          text: "Once your car is fixed, please continue!",
          name: "Once your car is fixed, please continue!",
          price: "",
          type: "arrived",
          desc: `<p>continue</p>`,
        },
      ],
      backtostart: false,
      text: [
        `<p><p>Breakdowns are never ideal, but if you find yourself stuck on the side of the road, don&rsquo;t panic. You can protect yourself and the vehicle while waiting for assistance. Follow these six tips to stay safe:</p>
<ol>
<li><strong>Get Off the Road</strong></li>
<ol>
<li>When you notice an issue, find a safe spot to pull over. An emergency breakdown lane or other open area is ideal. Getting the truck off the road ensures traffic can continue normally, minimizes accident risk, and allows roadside assistance to reach you easily.</li>
</ol>
<li><strong>Make Yourself Visible</strong></li>
<ol>
<li>Once off the road, make sure the truck is visible to other drivers. Turn on hazard lights, activate parking lights, and raise the hood if possible. Place red warning triangles outside only if you can do so safely. These measures alert other drivers to your presence and help assistance find you.</li>
</ol>
<li><strong>Exit the Vehicle Carefully</strong></li>
<ol>
<li>Exit the vehicle with caution, ideally through the passenger side door to avoid traffic. If you feel unsafe, remain inside with your seat belt on.</li>
<li>If the engine is smoking, get as far away from the car as possible and call 1122 or 16 for help.</li>
</ol>
<li><strong>Call Roadside Assistance</strong></li>
<ol>
<li>Call for emergency breakdown service as soon as it&rsquo;s safe. Provide the dispatcher with details of what happened and your location. Have important numbers and policy information ready. If temperatures are below freezing, call 911 before contacting roadside assistance.</li>
</ol>
<li><strong>Stay Away from the Road</strong></li>
<ol>
<li>To avoid being hit by passing traffic, remain as far from the road as possible. Stand behind a barrier or move away from the road to stay safe.</li>
</ol>
<li><strong>Wait for Help</strong></li>
<ol>
<li>Always wait for professional assistance before attempting any repairs. Don&rsquo;t flag down other vehicles or accept help from passing motorists. Roadside assistance crews have the proper tools and knowledge to get you back on the road safely.</li>
</ol>
</ol></p> `,
      ],
    };
  }

  getAllItems() {
    return this.items;
  }
}

export default safety;
