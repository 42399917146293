import apiPayload from "../../components/rsachat/apiPayload";
class Excellent {
  constructor(data) {
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    this.items = {
      id: 30001,
      name: "Start Text",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [],
      backtostart: true,
      text: [
        `<p>We're glad to have served you! Your satisfaction is our top priority, and we appreciate your support. If there's anything we can do to make your experience even better, let us know. Drive safe!`,
      ],
    };
  }

  async getAllItems() {
    try {
      const sessionUser = JSON.parse(sessionStorage.getItem("user"));
      const chatDetails = JSON.parse(sessionStorage.getItem("RSA-Chat"));
      apiPayload.applicationUserId = sessionUser.applicationUserId;
      apiPayload.misidn = sessionUser.userMsisdn;
      chatDetails.forEach(({ key, value }) => {
        apiPayload[key] = value;
      });
      console.log("API payload Response:>", JSON.stringify(apiPayload));

      const apiUrl =
        "https://Digital.webdoc.com.pk/Promotion/public/api/v1/chat/rsa-insurance/save";
      const response = await fetch(apiUrl, {
        method: "POST",
        body: JSON.stringify(apiPayload),
      });
      console.log("API Response:", response);
    } catch (error) {
      console.error("Error sending data to API:", error);
    }

    return this.items;
  }
}

export default Excellent;
