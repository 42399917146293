import { sendEmail } from "../../services/api";
class isbad {
  constructor(data) {
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    this.items = {
      id: 30001,
      name: "Start Text",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40012,
          text: "Submit",
          name: "Submit",
          type: "unstaisfiedinfo",
          desc: "",
          inputtype: "textinput",
        },
      ],
      backtostart: true,
      text: [
        `<p>Sorry for your experience. We are trying our best to improve our services. Your suggestion matters.</p> `,
      ],
    };
  }

  async getAllItems() {
    return this.items;
  }
}

export default isbad;
