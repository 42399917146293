class about {
  constructor() {
    this.items = {
      id: 30001,
      name: "About",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40012,
          text: "Connect to the vet Doctor",
          name: "Connect to the vet Doctor",
          price: "",
          type: "doctor",
          desc: "I’d like to connect with a vet doctor.",
        },
        // {
        //   id: 40011,
        //   text: "FAQs",
        //   name: "FAQ",
        //   price: "",
        //   type: "faqs",
        //   desc: "FAQs",
        // },
      ],
      backtostart: true,
      text: `<p><strong>WEB<span style="color: #ff0000;">DOC</span></strong> is Pakistan&rsquo;s top Health Tech platform, offering a comprehensive digital solution for trusted online vet/pet doctor consultations with the highest level of security.
       Whether you're in any corner of the country, WEBDOC connects you with 
      veterinary medical professionals, providing seamless digital health advice. Our platform lets you schedule video or audio consultations at your convenience, ensuring your pet get the medical support they need, anytime and anywhere.</p>`,
    };
  }

  getAllItems() {
    return this.items;
  }
}

export default about;
