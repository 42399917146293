class issue {
  constructor(data) {
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    this.items = {
      id: 30001,
      name: "Start Text",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 5,
          text: "Need Fuel for car",
          name: "Need Fuel for car",
          price: "",
          type: "registration",
          desc: "Need Fuel for car",
          key: "issue",
        },
        {
          id: 7,
          text: "Electric breakdown",
          name: "Electric breakdown",
          price: "",
          type: "registration",
          desc: "Electric breakdown",
          key: "issue",
        },
        {
          id: 7,
          text: "Mechanical breakdown",
          name: "Mechanical breakdown",
          price: "",
          type: "registration",
          desc: "Mechanical breakdown",
          key: "issue",
        },
        {
          id: 3,
          text: "Flat Tyre",
          name: "Flat Tyre",
          price: "",
          type: "registration",
          desc: "Flat Tyre",
          key: "issue",
        },
        {
          id: 9,
          text: "Locked out of car",
          name: "Locked out of car",
          price: "",
          type: "registration",
          desc: "Locked out of car",
          key: "issue",
        },
        {
          id: 16,
          text: "Not sure what happened",
          name: "Not sure what happened",
          price: "",
          type: "registration",
          desc: "Not sure what happened",
          key: "issue",
        },
        {
          id: 2,
          text: "Other",
          name: "Other",
          price: "",
          type: "registration",
          desc: "",
          inputtype: "textinput",
          key: "issue",
        },
      ],
      backtostart: false,
      text: [
        `<p>One time service is a package where your vehicle may be broken down and you need immediate
          service without being member.</p> `,
        `<p>We can provide our technician he isn't far away. He can get your car fixed or can advise as
          per situation. Having trouble with your car? let us take care of it.</p> `,
      ],
    };
  }

  getAllItems() {
    const subscription = JSON.parse(sessionStorage.getItem("activation"));
    // if (subscription?.value === "Active") {
    //   this.items.text = [
    //     `<p>We can provide our technician he isn't far away. He can get your car fixed or can advise as
    //       per situation. Having trouble with your car? let us take care of it.</p> `,
    //   ];
    //   return this.items;
    // }

    return this.items;
  }
}

export default issue;
