class allowcamera {
  constructor(data) {
    this.items = {
      id: 30001,
      name: "Personal Info",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40017,
          text: "Open Camera",
          name: "Open Camera",
          price: "",
          type: "opencamera",
          desc: "Open Camera",
          inputtype: "button",
        },
      ],
      backtostart: false,
      text: [`<p>We need your camera permissions.</p> `],
    };

    // Retrieve car information from sessionStorage
    const carInformation = JSON.parse(
      sessionStorage.getItem("car_information")
    );
    const user = JSON.parse(sessionStorage.getItem("user"));

    // Ensure both user and car information exist in sessionStorage
    if (carInformation && user) {
      // Merge user information with car information
      this.carInformation = {
        ...user, // Spread user data
        userMsisdn: user.userMsisdn,
        applicationUserId: user.applicationUserId,
        ...carInformation.reduce((acc, item) => {
          acc[item.key] = item.value; // Map each key-value pair from carInformation to the object
          return acc;
        }, {}),
      };
    }
  }

  getAllItems() {
    return this.items;
  }

  getPackageOptions() {
    return [
      {
        id: 40017,
        text: "Open Camera",
        name: "Open Camera",
        price: "",
        type: "opencamera",
        desc: "Open Camera",
        inputtype: "button",
      },
    ];
  }

  // Method to send the car information to the API
  async saveToAPI() {
    try {
      const response = await fetch(
        "https://Digital.webdoc.com.pk/Promotion/public/api/v1/chat/car-insurance/save-policy",
        {
          method: "POST",
          body: JSON.stringify(this.carInformation),
        }
      );

      if (response.ok) {
        console.log("Data saved successfully", this.getPackageOptions());
        const result = await response.json();
        this.items.text =
          "Your quotation saved successfully. Please choose your payment option:";
        this.items.issuccess = true;
        if (this.insType === "thirdparty") {
          this.items.options = this.getTheftOnlyOptions();
        } else {
          this.items.options = this.getPackageOptions();
        }
        return this.items;
      } else {
        console.error("Failed to save data");
        this.items.text = "Your quotation save failed.";
        this.items.issuccess = false;
        if (this.insType === "thirdparty") {
          this.items.options = this.getTheftOnlyOptions();
        } else {
          this.items.options = this.getPackageOptions();
        }
        return this.items;
      }
    } catch (error) {
      console.error("Error saving data:", error);
      this.items.text = "Your quotation save failed.";
      this.items.issuccess = false;
      if (this.insType === "thirdparty") {
        this.items.options = this.getTheftOnlyOptions();
      } else {
        this.items.options = this.getPackageOptions();
      }
      return this.items;
    }

    return this.items;
  }
}

export default allowcamera;
