import axios from "axios";

const BASE_URL = "https://miniappeasypaisa.webddocsystems.com/public/api/v1";

const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});


export const checkInsurance = async (value, key) => {
  try {
    const formData = new FormData();
    formData.append("value", value);
    formData.append("apikey", key);

    const response = await apiClient.post(
      "/checkregistration/insurance-check",
      formData
    );
    return response.data;
  } catch (error) {
    console.error("Insurance check error:", error);
    throw error;
  }
};

export const checkHealth = async (value) => {
  try {
    const formData = new FormData();
    formData.append("value", value);

    const response = await apiClient.post(
      "/checkregistration/health-check",
      formData
    );
    return response.data;
  } catch (error) {
    console.error("Insurance check error:", error);
    throw error;
  }
};

export const checkAgri = async (value) => {
  try {
    const formData = new FormData();
    formData.append("value", value);

    const response = await apiClient.post(
      "/checkregistration/agri-check",
      formData
    );
    return response.data;
  } catch (error) {
    console.error("Insurance check error:", error);
    throw error;
  }
};

export const checkVet = async (value) => {
  try {
    const formData = new FormData();
    formData.append("value", value);

    const response = await apiClient.post(
      "/checkregistration/vet-check",
      formData
    );
    return response.data;
  } catch (error) {
    console.error("Insurance check error:", error);
    throw error;
  }
};

export const checkRSA = async (value) => {
  try {
    const formData = new FormData();
    formData.append("value", value);

    const response = await apiClient.post(
      "/checkregistration/rsa-check",
      formData
    );
    return response.data;
  } catch (error) {
    console.error("Insurance check error:", error);
    throw error;
  }
};

export const sendEmail = async (to, subject, message) => {
  try {
    const formData = new FormData();
    formData.append("to", to);
    formData.append("subject", subject);

    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    const body = `Dear CX Manager,
This complaint has been forwarded to you for resolution in collaboration with the concerned department. Kindly ensure that a response is provided to the customer within 24 hours. Please confirm compliance at the earliest.
Complaint : ${message}
Name : ${sessionUser.userName}
Number : ${sessionUser.userMsisdn}
Thank you for your prompt attention to this matter.
         `.trim();
    formData.append("message", body);

    const response = await apiClient.post("/send-email", formData);
    return response.data;
  } catch (error) {
    console.error("Insurance check error:", error);
    throw error;
  }
};
