import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const CarInsurancePolicyThirdParty = () => {
   const { id } = useParams();
  const [policy, setPolicy] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const data = {
      id: id,
    };
    fetch("https://Digital.webdoc.com.pk/Promotion/public/api/v1/chat/car-insurance/get-policy", {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.statusCode === 1) {
          setPolicy(data.payLoad);
        } else {
          throw new Error("Failed to fetch policy details");
        }
      })
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) return <p className="text-center text-lg font-semibold">Loading...</p>;
  if (error) return <p className="text-center text-red-500 font-semibold">Error: {error}</p>;

  return (
    <div className="max-w-4xl mx-auto p-8 bg-white shadow-md rounded-lg border">
      <h2 className="text-2xl font-bold text-center uppercase">East West Insurance Co. Ltd.</h2>
      <p className="text-lg text-center font-semibold">MOTOR COVER NOTE - THIRD PARTY LIABILITY ONLY</p>
      <hr className="my-4 border-gray-300" />
      
      <div className="grid grid-cols-2 gap-4 text-sm">
        <p><strong>COVER NOTE DATE:</strong> {policy.created_at}</p>
        <p><strong>CNIC NO:</strong> {policy.cnic_number}</p>
        <p><strong>Cover Note No:</strong> CMVP/04/000004/02/2025</p>
        <p><strong>Insured Name:</strong> XXXXXXXXX</p>
        <p><strong>Address:</strong> XXXXxXXXXX</p>
        <p><strong>Period of Insurance:</strong> 04-02-2025 to 03-02-2026</p>
        <p><strong>Reg No:</strong> {policy.car_registration_no}</p>
        <p><strong>Engine No:</strong> G6DCMS315502</p>
        <p><strong>Chassis No:</strong> NNAUM53BZPP7016</p>
        <p><strong>Make:</strong> KIA</p>
        <p><strong>Body:</strong> SALOON</p>
        <p><strong>Model:</strong> 2024</p>
        <p><strong>CC Power:</strong> 3500</p>
        <p><strong>Seats:</strong> 5</p>
        <p><strong>Colour:</strong> {policy.car_color}</p>
      </div>

      <hr className="my-4 border-gray-300" />
      
      <div className="bg-gray-200 p-4 mt-6 rounded-lg">
        <p className="font-bold text-center uppercase">Attached Clauses & Endorsements:</p>
        <ul className="list-disc pl-6">
          <li>Transfer of Interest</li>
          <li>Jurisdiction Clause</li>
          <li>Premium Payment Clause</li>
          <li>Subject to No Loss till date</li>
        </ul>
      </div>

      <div className="bg-gray-200 p-4 mt-6 rounded-lg">
        <p className="font-bold text-center uppercase">IMPORTANT NOTICE</p>
        <p>The insured will not be indemnified if the vehicle is used or driven otherwise than in accordance with this schedule.</p>
      </div>

      <p className="font-bold mt-6 text-lg text-center">PREMIUM CALCULATION</p>
      <div className="grid grid-cols-2 gap-4 text-sm">
        <p><strong>Insured Estimated Value:</strong> 000,000,000 PKR</p>
        <p><strong>Gross Premium:</strong> 000,000</p>
        <p><strong>ASC:</strong> 000,000</p>
        <p><strong>CED:</strong> 000,000</p>
        <p><strong>FIF:</strong> 000,000</p>
        <p><strong>Stamp Duty:</strong> 000,000</p>
        <p><strong>Advance Tax:</strong> 000,000</p>
      </div>
      
      <p className="mt-6">This Cover Note is valid up to auto fetch date 15 days after issuance of cover note.</p>
      <p>This contract is deemed to have been made on this Cover Note issuance date at Islamabad, and claims arising thereunder, if any, shall be at Islamabad.</p>
      
      <p className="font-bold text-center mt-6">AUTHORIZED SIGNATORY</p>
    </div>
  );
};

export default CarInsurancePolicyThirdParty;
