class notfixed {
  constructor(data) {
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    this.items = {
      id: 30001,
      name: "Start Text",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40012,
          text: "Yes",
          name: "Yes",
          price: "",
          type: "recover",
          desc: "Yes"
        },
        {
          id: 40012,
          text: "No",
          name: "No",
          price: "",
          type: "recover",
          desc: "No",
        },
      ],
      backtostart: false,
      text: [
        `<p>This is sad. He has tried his best. We do offer a recovery service. We can
get you to the workshop as your vehicle is not in position to be fixed
on road. Do you need a discounted recover service. </p> `,
      ],
    };
  }

  getAllItems() {
    return this.items;
  }
}

export default notfixed;
