class updatePlan {
    constructor(data) {
      const sessionUser = JSON.parse(sessionStorage.getItem("user"));
      this.items = {
        id: 30001,
        name: "Start Text",
        type: "text",
        slideimg: "/doctorassistant/dia.jpg",
        options: [
          
          { id: 40012, text: "Buy Now", name:"Buy Now", price: data.price, type: "membershipPayment",desc:"I want to buy this plan",key:"member_amount" },
          { id: 40011, text: "Change Mind", name: "Change Plan", price: "", type: "membership", desc: "Change of Plan" },
    ],
         backtostart: false,
         
        text: [
          `<p>Take your next step—what feels right for you? Choose an option below to continue.   </p> `,
        ],
      };
    }
  
    getAllItems() {
      return this.items;
    }
  }
  
  export default updatePlan;
  