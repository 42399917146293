class monthly_mobile_insurance {
  constructor() {
    this.items = [
      {
        id: 1248,
        name: "Monthly Basic",
        badge: "Monthly",
        coverage: "50000",
        // price: "200",
        price: "0",
        bgColorClass: "bg-warning",
        description: `<p><strong>Instant Protection:</strong> Safeguard your phone in seconds with our easy online sign-up, just a click and you&rsquo;re covered!. Premium is for an annum paid monthly, Claims are pro-rata basis.</p>
        <p><strong>Coverage: </strong>All-Inclusive Security: Rest easy with excellent coverage against:</p>
        <ul>
        <li>Theft</li>
        <li>Burglary</li>
        </ul>
        <p><strong>Swift Claims:</strong> Experience hassle-free claims processing through our friendly support team and instant&nbsp;payouts.</p>
        <p>Stay connected, stay insured, and never worry about losing your digital lifeline again</p>`,
        type: "insurance",
        apiKey: "Mobile",
        isfree: true,
      },
      {
        id: 1246,
        name: "Monthly Silver",
        badge: "Monthly",
        coverage: "100000",
        price: "500",
        bgColorClass: "bg-warning",
        description: `<p><strong>Instant Protection:</strong> Safeguard your phone in seconds with our easy online sign-up, just a click and you&rsquo;re covered!. Premium is for an annum paid monthly, Claims are pro-rata basis.</p>
        <p><strong>Coverage: </strong>All-Inclusive Security: Rest easy with excellent coverage against:</p>
        <ul>
        <li>Theft</li>
        <li>Burglary</li>
        </ul>
        <p><strong>Swift Claims:</strong> Experience hassle-free claims processing through our friendly support team and instant&nbsp;payouts.</p>
        <p>Stay connected, stay insured, and never worry about losing your digital lifeline again</p>`,
        type: "insurance",
        apiKey: "Mobile",
      },
      {
        id: 1247,
        name: "Monthly Gold",
        badge: "Monthly",
        coverage: "150000",
        price: "750",
        bgColorClass: "bg-warning",
        description: `<p><strong>Instant Protection:</strong> Safeguard your phone in seconds with our easy online sign-up, just a click and you&rsquo;re covered!. Premium is for an annum paid monthly, Claims are pro-rata basis.</p>
        <p><strong>Coverage: </strong>All-Inclusive Security: Rest easy with excellent coverage against:</p>
        <ul>
        <li>Theft</li>
        <li>Burglary</li>
        </ul>
        <p><strong>Swift Claims:</strong> Experience hassle-free claims processing through our friendly support team and instant&nbsp;payouts.</p>
        <p>Stay connected, stay insured, and never worry about losing your digital lifeline again</p>`,
        type: "insurance",
        apiKey: "Mobile",
      },
    ];
    this.terms = `
    <b style="font-size: 12px;">Disclaimer: </b><small style="font-size: 10px;"> Webdoc in partnership with our underwriter East West insurance company is providing the product. This is a recurring payment model.</small><br></br>
    <p><strong>Key Policy Terms &amp; Exclusions</strong></p>
<ul>
<li>Only one subscription per phone and per person at a time</li>
<li>Claim should be made within 15 days for burglary &amp; theft</li>
<li>SIM/MSISDN should be in the name of the claimant</li>
</ul>
<p><strong>Exclusions</strong></p>
<p>Payment of claims is subject to exclusions, as outlined below. Mobile Insurance shall not cover.</p>
<ol>
<li>Any Pre-damaged Screen.</li>
<li>Already Lost or Stolen phones.</li>
<li>Forcedly or purposely broken phones.</li>
<li>Customer Account numbers and Phone IMEI required</li>
<li>Waiting period of 7 days is applicable</li>
<li>Only one subscription per phone and per person. After claim, insurance will be terminated</li>
<li>Claim should be made within 15 days</li>
<li>Theft &amp; Burglary are covered &ndash; First investigation report Required</li>
<li>Coverage is for new as well as old phones on pro rata basis</li>
<li>Non PTA phones are not covered</li>
</ol>
<p><strong>Eligibility &amp; Enrolment:</strong></p>
<ul>
<li>Applicants are eligible to apply for a Mobile Insurance Plan under the Policy if Applicants meet all the criteria set out below:</li>
<li>All Eligible Applicants shall be natural persons. Corporations, partnerships and businesses shall not be eligible for coverage under the Policy.</li>
<li>All Eligible Applicants shall be a minimum of <strong>eighteen (18)</strong> years of age.</li>
<li>If the Applicant wishes to apply for and subscribe to a Plan under this Insurance Policy, the Applicant will be required during the registration process to:</li>
<li>acknowledge that the Applicant has read and understood the terms of the Policy.</li>
<li>Confirm that the Applicant meets the eligibility criteria.</li>
<li>The Insurance Benefits payable are subject to the Applicant&rsquo;s confirmations being true and correct; and</li>
<li>If the Applicant&rsquo;s confirmation is untrue or incorrect, no Insurance Benefits will be payable, and the End User Price the Subscriber paid will not be refunded.</li>
<li>After purchasing the Insurance Policy, you need to send IMEI number along with your MSISDN number to given WhatsApp 0343-5619973. This should be a picture of the IMEI number taken from your phone. Only IMEI number is not acceptable. Alternatively, you may contact the helpline and register your IMEI number against your MSISDN number to be eligible for the claim.</li>
<li><strong>Waiting period of 7 days apply</strong>.</li>
</ul>
<p><strong>Notice Of Claims:</strong></p>
<p>The Company shall be notified of the occurrence of the Insured Claim as soon as possible, but not later than <strong>15 (FIFTEEN days)</strong> from the date of burglary or theft, which shall be treated as time‑barred and the Company shall not be bound to pay the Claim.</p>
<p><strong>Required Documents/Detail for claims:</strong></p>
<p>For each Claim reported, the Company shall obtain From the Claimant:</p>
<ul>
<li>CNIC copy Both Sides</li>
<li>Picture of IMEI Number of the phone or pre-registration before the event of claim</li>
<li>Police Report &ndash; In case of theft &amp burglary</li>
</ul>
<p>If there is a dispute, suspected fraudulent activity on the claim or a unique situation which requires further clarification, the payment period can be extended but shall not exceed <strong>ten (10) working days</strong>, or as long as the dispute takes to resolve in the legal system.</p>
<p><strong>Termination Of Individual Insurance</strong></p>
<ul>
<li>The insurance of an Insured shall automatically terminate at the earliest time below:</li>
<li>Upon payment of the claim or</li>
<li>Upon cancellation or withdrawal of subscription</li>
</ul>
<p>Customer will call on 3737 / 042-111-003-737 / 0518493737 intimate about&nbsp;<strong>claim/complaint</strong>&nbsp;or can email all these documents on whsclaims@webdoc.com.pk</p>`;
  }

  getAllItems() {
    return this.items;
  }

  getTerms() {
    return this.terms;
  }
}

export default monthly_mobile_insurance;
