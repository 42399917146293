class payment {
  constructor(data) {
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    this.items = {
      id: 30001,
      name: "Start Text",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 37,
          text: "Pay Now",
          name: "Pay Now",
          price: "2000",
          type: "confirmpayment",
          desc: "Pay Now",
        },
      ],
      backtostart: false,
      text: [
        `<p>We charge a nominal fee of Rs. 2000 to send our mechanic or technician to you. This doesn't include any parts/Fuel required.</p> `,
      ],
      key: "payment",
    };
  }

  getAllItems() {
    const subscription = JSON.parse(sessionStorage.getItem("activation"));
    return this.items;
    // if (subscription?.value !== "Active") {
    //   this.items.options = this.locationOption();
    //   this.items.text = [
    //     `Thank you! No need to worry, we're on our way. If possible, please park to the side. If you're on the roadside, stay in a safe place—your safety is our top priority.`,
    //     "Please Share your Location or Address!",
    //   ];
    //   return this.items;
    // } else return this.items;
  }
  locationOption() {
    return [
      {
        id: 40012,
        text: "Share Location",
        name: "Share Location",
        price: "",
        type: "location",
        desc: "Share Location",
        key: "location",
      },
      {
        id: 40012,
        text: "Share Address",
        name: "Share Address",
        price: "",
        type: "location",
        desc: "",
        inputtype: "textinput",
        key: "address",
      },
    ];
  }
}

export default payment;
