class sendtosurveyor {
  constructor() {
    this.items = {
      id: 30001,
      name: "Personal Info",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [],
      backtostart: true,
      text: [
        `<p>We will connect you once supervisor is available. Drive safe</p> `,
      ],
    };
  }

  getAllItems() {
    console.log(sessionStorage.getItem("car_information"));
    return this.items;
  }
}

export default sendtosurveyor;
