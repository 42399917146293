import React, { useState } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import "./rsa-chat.css";
import { usePlacesWidget } from "react-google-autocomplete";

const TextInputOption = ({ msg, handleTextSubmit }) => {
  const [text, setText] = useState("");
  const [error, setError] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateInput()) {
      handleTextSubmit(msg, text);
      setText("");
      setError("");
    }
  };

  const validateInput = () => {
    if (msg.type === "vehiclemake" && typeof text !== "string") {
      setError("Please enter a valid vehicle make.");
      return false;
    }

    if (msg.type === "vehiclemodel" && typeof text !== "string") {
      setError("Please enter a valid vehicle model.");
      return false;
    }
    if (msg.type === "confirmprice" && isNaN(text)) {
      setError("Please enter a valid price.");
      return false;
    }
    if (msg.type === "vehicalcolor" && typeof text !== "string") {
      setError("Please enter a valid price.");
      return false;
    }
    if (msg.type === "providecnic" && typeof text !== "string") {
      setError("Please enter valid value.");
      return false;
    }
    if (msg.type === "comprehensiveinfo" && !/^\d{13}$/.test(text)) {
      setError("Please enter a valid 13-digit CNIC number.");
      return false;
    }

    return true;
  };
  let placeholder;
  let intype = "text";
  if (msg.key === "issue") {
    placeholder = "Describe your issue";
    intype = "text";
  } else if (msg.type === "location") {
    placeholder = "Please enter the full address";
    intype = "text";
  } else if (msg.key === "registration_no") {
    placeholder = "Please enter the registration no";
    intype = "text";
  } else placeholder = "Enter text";

  const { ref } = usePlacesWidget({
    apikey: "AIzaSyCTMZHlmNvPYuWVs66cbwuW1XO8O2VWeb4",
    onPlaceSelected: (place) => {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      const city = place.address_components.find((component) =>
        component.types.includes("locality")
      )?.long_name;

      // ✅ Filter only Islamabad & Rawalpindi
      if (city === "Islamabad" || city === "Rawalpindi") {
        setText(place.formatted_address);
        msg.coords = { latitude: lat, longitude: lng };
        console.log("Latitude:", lat);
        console.log("Longitude:", lng);
      } else {
        alert("Please select a location in Islamabad or Rawalpindi!");
      }
    },
    options: {
      types: ["establishment"],
      componentRestrictions: { country: "PK" },
      bounds: {
        east: 73.2, // Extending to the right
        west: 72.85, // Extending to the left
        north: 33.8, // Extending to the top (Islamabad)
        south: 33.45, // Extending to the bottom (Rawalpindi)
      },
      strictBounds: true,
    },
  });
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3 mx-2">
        <InputGroup>
          <Form.Control
            ref={msg.type === "location" ? ref : null}
            type={intype}
            placeholder={placeholder}
            className="rsa-text"
            value={text}
            onChange={(e) => {
              if (msg.key === "registration_no") {
                const regex = /^[A-Za-z0-9 -]{0,7}$/;
                if (regex.test(e.target.value)) {
                  setText(e.target.value);
                }
              } else {
                setText(e.target.value);
              }
            }}
            required
            style={{ "::placeholder": { fontWeight: "100", color: "#ccc" } }}
            onFocus={() => {
              setTimeout(() => {
                window.scrollTo({
                  top: document.body.scrollHeight,
                  behavior: "smooth",
                });
              }, 300);
            }}
          />
          <Button
            variant="secondary"
            style={{ backgroundColor: "#1bb367" }}
            className="border-0 chatOptions"
            type="submit"
          >
            Submit
          </Button>
        </InputGroup>
        {error && <div style={{ color: "red" }}>{error}</div>}
      </Form.Group>
    </Form>
  );
};

export default TextInputOption;
