class needto_see_doc {
  constructor() {
    this.items = {
      id: 30003,
      name: "calladoc",
      slideimg: "/doctorassistant/dia.jpg",
      type: "text",
      options: [],
      backtostart: false,
      text: ``,
    };
  }

  // Method to make the API call, now accepting customerNumber as a parameter
  async getAllItems() {
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    let customerNumber = sessionUser.userMsisdn;

    // Check if customerNumber is provided
    if (!customerNumber) {
      console.error("Customer number is required.");
      return;
    }

    // if (sessionUser.package) {
    //   this.items.text =
    //     "Your package is activated we are transfering your data to Doctor Assitant. please wait ";
    //   // If responseCode is '0000', mark the options as activated
    //   return this.items;
    // } else {
    //   this.items.options = [
    //     {
    //       id: 1,
    //       text: "1 month package",
    //       name: "1 Month package",
    //       price: "0",
    //       type: "payment",
    //       desc: "<p>Unlock the ultimate convenience with unlimited audio and video advice, giving you direct access to expert care &ndash; tailored to fit your busy lifestyle!</p>",
    //     },
    //     {
    //       id: 2,
    //       text: "12 month package",
    //       name: "12 Month package",
    //       price: "700",
    //       type: "payment",
    //       desc: "<p>Enjoy unlimited audio and video advice for your entire family, providing you with expert care, all from the comfort of home!</p>",
    //     },
    //   ];
    //   this.items.text =
    //     "Absolutely! To proceed, please select one of the following vet's/pet's care packages that best suits your needs:";
    //   return this.items;
    // }

    // // Prepare the API URL and headers
    const url = `https://insuranceservice.webddocsystems.com/api/MiniApp/CheckCategoryPackages?id=1001&customerNumber=${customerNumber}&service=vets`;
    const headers = {
      Authorization:
        "Basic ZlJqZGZVbWFzOmFmMzNhZmNhODAwNzZkOWMxMTViOGU0NjAyZjc4YTIx",
    };

    try {
      const response = await fetch(url, { method: "GET", headers: headers });

      const data = await response.json(); // Assuming the response is JSON

      if (data.responseCode === "0000") {
        this.items.text =
          "Your package is activated, we are transfering your data to vet doctors. Please wait. ";
        // If responseCode is '0000', mark the options as activated
        return this.items;
      } else {
        // If responseCode is not '0000', mark the options as not activated
        this.items.options = [
          {
            id: 1,
            text: "1 month package",
            name: "1 Month package",
            price: "0",
            type: "payment",
            isfree: true,
            desc: "<p>Unlock the ultimate convenience with unlimited audio and video advice, giving you direct access to vet expert care &ndash; tailored to fit your busy lifestyle!</p>",
          },
          {
            id: 2,
            text: "12 month package",
            name: "12 Month package",
            price: "700",
            type: "payment",
            desc: "<p>Unlock the ultimate convenience with unlimited audio and video advice, giving you direct access to vet expert care &ndash; tailored to fit your busy lifestyle!</p>",
          },
        ];
        this.items.text =
          "Absolutely! To proceed, please select one of the following vet's/pet's care packages that best suits your needs:";
        return this.items;
      }
    } catch (error) {
      console.error("Error making API call:", error);
      this.items.options = [
        {
          id: 1,
          text: "1 month package",
          name: "1 Month package",
          price: "0",
          isfree: true,
          type: "payment",
          desc: "<p>Unlock the ultimate convenience with unlimited audio and video advice, giving you direct access to expert care &ndash; tailored to fit your busy lifestyle!</p>",
        },
        {
          id: 2,
          text: "12 month package",
          name: "12 Month package",
          price: "700",
          type: "payment",
          desc: "<p>Unlock the ultimate convenience with unlimited audio and video advice, giving you direct access to vet expert care &ndash; tailored to fit your busy lifestyle!</p>",
        },
      ];
      this.items.text =
        "Absolutely! To proceed, please select one of the following vet's/pet's care packages that best suits your needs:";
      return this.items;

      // Handle error case (e.g., show error message, etc.)
    }
  }
}

export default needto_see_doc;
