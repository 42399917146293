import React from "react";
import { Link } from "react-router-dom";
import Advisory2 from "./advisory2";
import "./advisory1.css";

const protectionData = [
  {
    to: "/insurancescreen/agri/1002",
    image: "/assets/AppIcons/WHS.png",
    title: "WHS",
  },
  {
    to: "/insurancescreen/agri/1002",
    image: "/assets/AppIcons/WHSPlus.png",
    title: "WHS Plus",
  },
  {
    to: "/insurancescreen/vets/1001",
    image: "/assets/AppIcons/IncomeProtection.png",
    title: "Income Protection",
  },
];

const ProtectSection = () => {
  return (
    <div className="mb-3">
      <div className="protect-card d-flex px-3 py-2 justify-content-between">
        {protectionData.map((item, index) => (
          <div className="col-4 col-md-2" key={index}>
            <Link to={item.to} className="text-decoration-none">
              <Advisory2 image={item.image} title={item.title} />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProtectSection;
