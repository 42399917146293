class End {
    constructor(data) {
      const sessionUser = JSON.parse(sessionStorage.getItem("user"));
      this.items = {
        id: 30001,
        name: "Start Text",
        type: "text",
        slideimg: "/doctorassistant/dia.jpg",
        options: [
            
            ],
        backtostart: true,
        text: [
          
        ],
      };

      this.data=data;
    }
  
    getAllItems() {
        if(this.data.desc=="Yes")
        {
            this.items.text=['We will call you shortly'];
        }
        else
        {
            this.items.text=['How would then take it. Lets get you a good price' , 'We will call you shortly'];
        }
      return this.items;
    }
  }
  
  export default End;
  