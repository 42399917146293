import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import getData from "../modals/chatmodalss/getchatmodalsWeb"; // Assuming this function fetches your modal data
import axios from "axios";
import ChatDoctorList from "../components/ChatDoctorList";
import ChatLoader from "../components/ChatLoader";
import Header_packages_chat from "../components/Header_package_chat";
import Swal from "sweetalert2";
import { useLoading } from "../contexts/LoadingContext";
import getPakistanGreeting from "../components/rsachat/greetingMessage";
const ChatWeb = () => {
  const [showList, setShowList] = useState(false);
  const [messages, setMessages] = useState([]); // Store all chat messages
  const messagesEndRef = useRef(null); // Ref to scroll to the end of the chat
  const [userData, setUserData] = useState(null); // Store user data from sessionStorage
  const [customerNumber, setCustomerNumber] = useState(null); // Store customer number
  const [loading, setLoading] = useState(false);
  const [diseasename, setdiseasename] = useState(null);
  const [sideimage, setSideImage] = useState("/doctorassistant/dia.jpg");
  const [expandedMessages, setExpandedMessages] = useState({});
  // const { isLoading, setIsLoading } = useLoading();

  const handleReadMoreClick = (index) => {
    setExpandedMessages((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle the state for this specific message
    }));
  };

  const user = {
    openId: "5da34d83de18ba92f44040c10b406c8ad4bb1375",
    unionId: "5ba759f37b91d2a51f20c014a7c3ba2a504055c7",
    userMsisdn: "0345000014",
    userName: "Khumash",
    applicationUserId: "27C62D9C-AB40-450C-A6CE-7851F47190FA",
    // applicationUserId: "2F02828E-B0E0-4570-9815-CDFBFB7D6DD0",
    userEmail: "03145362496@webdoc.com.pk",
    // applicationUserId: "B11000A1-70CA-4E67-A2A1-11DF65FEE0D9",
    package: "",
    message: "User registered and authenticated successfully  .",
  };

  sessionStorage.setItem("user", JSON.stringify(user));
  console.log(sessionStorage.getItem("user"));

  useEffect(() => {
    sessionStorage.removeItem("user");
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    console.log(sessionUser);
    if (sessionUser) {
      setTimeout(async () => {
        await loadData("start");
      }, 2000);
      setUserData(sessionUser);
    } else {
      authenticateUser();
    }
  }, []);

  const authenticateUser = () => {
    const scopes = [
      "USER_MSISDN",
      "USER_NAME",
      "OPEN_ID",
      "UNION_ID",
      "USER_BIRTHDAY",
    ];
  };

  useEffect(() => {
    let item = {
      id: 1,
      name: "Start Text",
      type: "text",
      options: [
        {
          id: 40012,
          text: "Connect to the Doctor",
          name: "Connect to the Doctor",
          price: "",
          type: "doctor",
          desc: "I’d like to connect with a doctor.",
        },
        {
          id: 40012,
          text: "About us",
          name: "About us",
          price: "",
          type: "about",
          desc: "About us",
        },
        {
          id: 40011,
          text: "FAQs",
          name: "FAQ",
          price: "",
          type: "faqs",
          desc: "FAQ",
        },
      ],
      backtostart: false,
      img: "/doctorassistant/dia.jpg",
      slideimg: "/doctorassistant/dia.jpg",
      display: "Natasha",
      position: "Customer Executive",
      text: `<p>${getPakistanGreeting()}! I&rsquo;m Natasha, your virtual health assistant at <strong>WEB<span style='color: #ff0000;'>DOC</span></strong>. Give me just a moment while I fetch your details, this won&rsquo;t take long!</p>`,
    };

    setMessages((prevMessages) => [...prevMessages, item]);
    // setIsLoading(true)
    setLoading(true);

    setTimeout(async () => {
      await loadData("start");
    }, 2000);
  }, []);

  const activePackage = () => {
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    sessionUser["package"] = null;
    sessionUser["applicationUserId"] = "27C62D9C-AB40-450C-A6CE-7851F47190FA";
    const updatedItem = JSON.stringify(sessionUser);
    sessionStorage.setItem("user", updatedItem);
    console.log(sessionStorage.getItem("user"));
  };

  // Scroll to the bottom whenever messages are updated
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    if (diseasename) {
      // Only call loadData if diseasename has been updated
      loadData("diseases", null, null);
    }
  }, [diseasename]);

  // Load modal data (e.g., faqs, about, doctor) based on type
  const loadData = async (type, price, pid) => {
    //alert(diseasename)
    const newMessage = await getData(type, price, pid, diseasename);

    setLoading(false);

    if (newMessage.modal.text == "") {
      //alert ("empty");
    }

    //alert(newMessage.modal);
    setMessages((prevMessages) => [...prevMessages, newMessage.modal]);

    if (newMessage.doctorassistant) {
      setSideImage(newMessage.doctorassistant.img);
      setLoading(true);
      //setLoading(true);
      setTimeout(() => {
        let image = "";
        if (newMessage.doctorassistant.img) {
          image = newMessage.doctorassistant.img;
        }
        setMessages((prevMessages) => [
          ...prevMessages,
          newMessage.doctorassistant,
        ]);

        if (
          newMessage.doctorassistant.options &&
          newMessage.doctorassistant.options.length > 0
        ) {
          newMessage.doctorassistant.options.forEach((option) => {
            option.isOption = true;
            option.sender = "Receiver";
            setMessages((prevMessages) => [...prevMessages, option]);
          });

          console.log(messages);
        }
        setLoading(false);
      }, 2000);
    }

    if (newMessage.modal.options && newMessage.modal.options.length > 0) {
      newMessage.modal.options.forEach((option) => {
        option.isOption = true;
        option.sender = "Receiver";
        setMessages((prevMessages) => [...prevMessages, option]);
      });

      console.log(messages);
    }
  };

  const startagain = async (type) => {
    let item = {
      id: 1,
      name: "Start Text",
      type: "text",
      options: [
        {
          id: 40012,
          text: "Connect to the Doctor",
          name: "Connect to the Doctor",
          price: "",
          type: "doctor",
          desc: "I’d like to connect with a doctor.",
        },
        {
          id: 40012,
          text: "About us",
          name: "About us",
          price: "",
          type: "about",
          desc: "About us",
        },
        {
          id: 40011,
          text: "FAQs",
          name: "FAQ",
          price: "",
          type: "faqs",
          desc: "FAQ",
        },
      ],
      backtostart: false,
      slideimg: "",
      img: "/doctorassistant/dia.jpg",
      slideimg: "/doctorassistant/dia.jpg",
      display: "Natasha",
      position: "Customer Executive",
      text: `<p>${getPakistanGreeting()}! I&rsquo;m Natasha, your virtual health assistant at <strong>WEB<span style='color: #ff0000;'>DOC</span></strong>. Give me just a moment while I fetch your details, this won&rsquo;t take long!</p>`,
    };

    setdiseasename(null);
    setMessages([]); // Clears all messages
    setShowList(false);
    setMessages((prevMessages) => [...prevMessages, item]);
    await loadData(type);
  };

  // Handle selection of an option
  const handleOptionSelect = async (msg) => {
    setLoading(true); // Show loader first

    // Remove all option messages before doing anything
    setMessages((prevMessages) => prevMessages.filter((msg) => !msg.isOption));
    if (msg.type !== "calltype") {
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "You", text: msg.desc },
      ]);
    }

    // Simulate delay and then show the message after the loader
    setTimeout(() => {
      if (msg.type === "calltype") {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            sender: "Receiver",
            slideimg: "/doctorassistant/dia.jpg",
            text: "Please hold on while I find an available expert for you.",
          },
        ]);
        setShowList(true);
        setLoading(false); // Show doctor list if calltype is selected
      } else {
        if (msg.type == "diseases") {
          //alert(msg.desc)
          setdiseasename(msg.desc);
          //diseasename=msg.desc;
        } else {
          loadData(msg.type, msg.price, msg.id);
        }
      }

      //setLoading(false); // Hide loader after the messages are updated
    }, 2000); // 2 second delay
  };

  return (
    <>
      <Header_packages_chat title="Chat" />
      {/* <img src="/doctorassistant/dia.jpg" alt=""></img> */}
      <Container>
        <Row>
          <Col className="chat_container" md={12}>
            <div className="chat_window pt-2">
              <div className="messages">
                {messages.map((msg, index) => (
                  <>
                    {/* this is main image */}
                    {msg.img && msg.img !== "" && (
                      <div key={index} className="row mt-2">
                        <div className="col-12 text-center">
                          <img
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                            }}
                            className="rounded-circle img-fluid"
                            src={msg.img}
                            alt=""
                          ></img>
                          <br></br>
                          <strong class="mt-2">{msg.display}</strong>
                          <br></br>
                          <small>{msg.position}</small>
                        </div>
                      </div>
                    )}

                    <div class="d-flex">
                      {/* this is side image on all chats*/}
                      {!msg.isOption && msg.sender !== "You" && (
                        <div class="flex-shrink-0 mt-2">
                          <img
                            src={msg.slideimg}
                            alt="customer executive"
                            style={{
                              width: "25px",
                              height: "30px",
                              objectFit: "cover",
                            }}
                            class="rounded-circle img-fluid"
                          />
                        </div>
                      )}

                      {/* this is chat started*/}
                      <div
                        style={{ minWidth: "30%" }}
                        className={
                          msg.sender !== "You"
                            ? "flex-grow-1 ms-2"
                            : "ms-auto justify-content-end"
                        }
                      >
                        {msg.text && msg.text !== "" && !msg.isOption && (
                          <div
                            key={index}
                            className={`message shadow-sm ${
                              msg.sender === "You" ? "sender" : "receiver"
                            }`}
                          >
                            <div className="message_text">
                              {msg.sender !== "Receiver" && (
                                <div className="message_sender"></div>
                              )}

                              <div
                                className={`message-content ${
                                  expandedMessages[index] ? "expanded" : ""
                                }`}
                                dangerouslySetInnerHTML={{ __html: msg.text }}
                              ></div>
                            </div>

                            {/* Read more button */}
                            {msg.text.length > 210 &&
                              !expandedMessages[index] && (
                                <>
                                  <a
                                    href="#"
                                    className="text-decoration-none read-more-btn"
                                    onClick={() => handleReadMoreClick(index)}
                                  >
                                    Read More
                                  </a>
                                  <br></br>
                                </>
                              )}

                            {msg.backtostart && (
                              <a
                                href
                                className="text-decoration-none"
                                style={{ cursor: "pointer" }}
                                onClick={() => startagain("start")}
                              >
                                Start Again
                              </a>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                ))}
                {loading && <ChatLoader />}
              </div>
              <div className="row">
                {messages.map((msg, index) => (
                  <>
                    {msg.isOption && (
                      <>
                        {msg.price && msg.price ? (
                          <div
                            className="col-6"
                            onClick={() => handleOptionSelect(msg)}
                          >
                            <div className="card border border-success">
                              <div className="card-body text-center">
                                {/* Package Name */}
                                <h6>{msg.name}</h6>

                                {/* Price */}
                                <h6 className="mt-2 mb-2">
                                  <strong style={{ fontSize: "15px" }}>
                                    Rs {msg.price}
                                  </strong>
                                </h6>

                                {/* Description */}

                                <small
                                  className="text-muted text-start"
                                  dangerouslySetInnerHTML={{ __html: msg.desc }}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div key={index} className={`col-12`}>
                            <div className="p-0">
                              <Button
                                variant="secondary"
                                style={{ backgroundColor: "#1bb367" }}
                                className="border-0 w-75 mb-2 chatOptions" // Use 'me-2' for spacing between buttons
                                onClick={() => handleOptionSelect(msg)}
                              >
                                <>
                                  {!msg.price ? (
                                    <p className="mb-0">{msg.name}</p>
                                  ) : null}{" "}
                                </>
                              </Button>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ))}
                <div ref={messagesEndRef} />
              </div>

              {showList && (
                <ChatDoctorList
                  messages={messages}
                  setMessages={setMessages}
                  setShowList={setShowList}
                />
              )}
            </div>
            {/* Button triggers to load data */}
            <Row className="py-3 justify-content-center">
              {/* <Col md={5} sm={6} className="col-6"> */}
              {/*                 
                <Button
                  className="w-100 chatOptions rounded-pill"
                  onClick={() => setShowList(true)}
                >
                  Load Doctors List
                </Button>
              </Col> */}
              {/* <Col md={5} sm={6} className="col-6">
                <Button
                  className="w-100 chatOptions rounded-pill"
                  onClick={activePackage}
                >
                  De-activate Package
                </Button>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ChatWeb;
