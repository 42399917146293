import apiPayload from "../../components/rsachat/apiPayload";
class membershipPayment {
  constructor(data) {
    this.items = {
      id: 30001,
      name: "Start Text",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [],
      backtostart: true,
      price: data.price,
      text: [`<p</p> `],
    };
  }
  async getAllItems() {
    try {
      const sessionUser = JSON.parse(sessionStorage.getItem("user"));
      const chatDetails = JSON.parse(sessionStorage.getItem("RSA-Chat"));
      const form = new FormData();
      form.append("value", this.items.price);
      form.append("apikey", "RSA");
      const settings = {
        method: "POST",
        body: form,
      };
      apiPayload.applicationUserId = sessionUser.applicationUserId;
      apiPayload.misidn = sessionUser.userMsisdn;
      chatDetails.forEach(({ key, value }) => {
        apiPayload[key] = value;
      });
      apiPayload["payment_status"] ="Active"
      const waitingDate = new Date().toLocaleDateString("en-US", {
        weekday: "long",
        month: "long",
        day: "numeric",
        year: "numeric",
      });
      const response = await fetch(
        "https://miniappeasypaisa.webddocsystems.com/public/api/v1/create-payment",
        settings
      );
      const result = await response.json();
      console.log(result)
      if (result.paymentTransactionID) {
        const paymentStatus = await this.handlePaymentResult(
          result.paymentTransactionID
        );

        if (paymentStatus === "success") {
          const apiUrl =
          "https://Digital.webdoc.com.pk/Promotion/public/api/v1/chat/rsa-insurance/save";
       await fetch(apiUrl, {
          method: "POST",
          body: JSON.stringify(apiPayload),
        });
        this.items.text = [
          `<p>Your Payment is successful, you are now part of the RSA family. <br/>
         7 Days waiting period starts on <strong>${waitingDate}</strong>.</p>`,
        ];

          return this.items;
        } else {
          this.items.text = [
            "<p>Payment failed. Please recharge easypaisa account and try again.<p>",
          ];
          this.items.options = [
            {
              id: 40012,
              text: "Retry",
              name: "Retry",
              price: "",
              type: "membershipPayment",
              desc: "Retry",
            },
          ];
          return this.items;
        }
      }
    return this.items;
    } catch (error) {
      console.log(error)
      this.items.text = [
        "<p>Payment failed. Please recharge easypaisa account and try again.<p>",
      ];
      this.items.options = [
        {
          id: 40012,
          text: "Retry",
          name: "Retry",
          price: "",
          type: "membershipPayment",
          desc: "Retry",
        },
      ];
    return this.items;

    }
  }
  handlePaymentResult = async (paymentTransactionID) => {
    return new Promise((resolve, reject) => {
      try {
        window.mp.createPayment(paymentTransactionID);

        window.createPaymentResult = async (jsonResult) => {
          const result = JSON.parse(jsonResult);
          const status = result.paymentStatus;
          if (status === "S") {
            const item = sessionStorage.getItem("user");
            const sess = JSON.parse(item);
            sess["package"] = "true";
            const updatedItem = JSON.stringify(sess);
            sessionStorage.setItem("user", updatedItem);
            resolve("success");
          } else {
            resolve("fail");
          }
        };
      } catch (error) {
        console.log(error)
        reject("fail");
      }
    });
  };
}
export default membershipPayment;
