class needto_see_doc {
  constructor() {
    this.items = {
      id: 30003,
      name: "calladoc",
      slideimg: "/doctorassistant/dia.jpg",
      type: "text",
      options: [],
      backtostart: true,
      text: ``,
    };
  }

  // Method to make the API call, now accepting customerNumber as a parameter
  async getAllItems() {
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    let customerNumber = sessionUser.userMsisdn;

    if (!customerNumber) {
      console.error("Customer number is required.");
      return;
    }

    // const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    // if (sessionUser.package) {
    //     this.items.text = "Your package is activated we are transfering your data to Doctor Assitant. please wait ";
    //     // If responseCode is '0000', mark the options as activated
    //     return this.items;

    // } else {
    //     this.items.options = [
    //         { id: 70, text: "1 month package", name: "1 Month package", price: "150", type: "payment", desc: "<p>Unlock the ultimate convenience with unlimited audio and video consultations, giving you direct access to expert care &ndash; tailored to fit your busy lifestyle!</p>" },
    //         { id: 72, text: "12 month package", name: "12 Month package", price: "999", type: "payment", desc: "<p>Enjoy unlimited audio and video consultations for your entire family, providing you with expert care, all from the comfort of home!</p>" }
    //     ];
    //     this.items.text = "Absolutely! To proceed, please select one of the following healthcare packages that best suits your needs:";
    //     return this.items;
    // }

    // // Prepare the API URL and headers
    const url = `https://insuranceservice.webddocsystems.com/api/MiniApp/CheckCategoryPackages?id=1027&customerNumber=${customerNumber}&service=Health`;
    const headers = {
      Authorization:
        "Basic ZlJqZGZVbWFzOmFmMzNhZmNhODAwNzZkOWMxMTViOGU0NjAyZjc4YTIx",
    };

    try {
      const response = await fetch(url, { method: "GET", headers: headers });

      const data = await response.json(); // Assuming the response is JSON

      if (data.responseCode === "0000") {
        this.items.text =
          "Your package is activated we are transfering your data to Doctor Assitant. please wait ";
        // If responseCode is '0000', mark the options as activated
        return this.items;
      } else {
        // If responseCode is not '0000', mark the options as not activated
        this.items.options = [
          {
            id: 70,
            text: "1 month package",
            name: "1 Month package",
            price: "0",
            isfree: true,
            type: "payment",
            desc: "<p>Unlock the ultimate convenience with unlimited audio and video consultations, giving you direct access to expert care &ndash; tailored to fit your busy lifestyle!</p>",
          },
          {
            id: 72,
            text: "12 month package",
            name: "12 Month package",
            price: "999",
            type: "payment",
            desc: "<p>Enjoy unlimited audio and video consultations for your entire family, providing you with expert care, all from the comfort of home!</p>",
          },
        ];
        this.items.text =
          "Absolutely! To proceed, please select one of the following healthcare packages that best suits your needs:";
        return this.items;
      }
    } catch (error) {
      console.error("Error making API call:", error);
      return this.items;
      // Handle error case (e.g., show error message, etc.)
    }
  }

  // getAllItems() {
  //     return this.items;
  // }
}

export default needto_see_doc;
