class calladoc_packages {
  constructor() {
    this.items = [
      {
        id: 70,
        name: "Monthly Package",
        badge: "Monthly",
        coverage: "-",
        price: "150",
        description: `Unlimited Video Calls. Audio Calls Unlimited`,
        type: "calladoc",
      },
      {
        id: 72,
        name: "Annual Package",
        badge: "Annual",
        coverage: "-",
        price: "999",
        description: `Unlimited Video Calls. Audio Calls Unlimited`,
        type: "calladoc",
      },
    ];

    //   this.terms = `<p>Digital doctors are available either through application or by dialing the helpline number. Fair usage policy strictly applies.:&nbsp;</p>
    // <p><strong>Personal data collection:&nbsp;</strong></p>
    //   <ul style="margin-left:0;padding-left:0">
    //   <li>To fully utilize our services, registration with the website is necessary.&nbsp;</li>
    //   <li>During signup, the website will request your data and information. This data is securely stored.</li>
    //   <li>The personal data collected is used solely to facilitate access to our services. No data or personal information will be collected without your explicit consent.&nbsp;</li>
    //   <li>This may include your name, CNIC, email address, and contact number.</li>
    //   <li>We do not ask for your passport numbers, driver's license numbers, bank card numbers, or any other registration numbers provided by third parties.</li>
    //   <li>You'll need to create a password for your private account. It's crucial to safeguard this password and avoid sharing it with any other entities or using it for third-party services.</li>
    //   </ul>
    //   <p><strong>Encryption:&nbsp;</strong></p>
    //   <ul style="margin-left:0;padding-left:0">
    //   <li>For enhanced security and uninterrupted service, we encrypt your data and password.&nbsp;</li>
    //   <li>By using our services, you agree to our privacy policy. We reserve the right to change the encryption policy without prior notice, ensuring maximum security.</li>
    //   </ul>
    //   <p><strong>Personal information we may use:&nbsp;</strong></p>
    //   <ul style="margin-left:0;padding-left:0">
    //   <li>Information provided during signup is only used for notifications and enhancing user experience, safeguarded and not shared without permission.&nbsp;</li>
    //   <li>The website is not liable for data loss or security breaches.</li>
    //   </ul>
    //   <p><strong>Queries and complaints:&nbsp;</strong></p>
    //   <ul style="margin-left:0;padding-left:0">
    //   <li>For inquiries about our services or website content, please contact our customer service department.&nbsp;</li>
    //   </ul>
    //   <p><strong>Content creation/copyrights</strong></p>
    //   <ul style="margin-left:0;padding-left:0">
    //   <li>All content uploaded on the website is copyrighted and owned by us, aimed at enhancing user services.&nbsp;</li>
    //   <li>Users can create and manage content, and they are solely responsible for it.&nbsp;</li>
    //   <li>We are not liable for any reported or banned user-generated content.&nbsp;</li>
    //   <li>Users must take responsibility for their personal information and created content.&nbsp;</li>
    //   <li>Our privacy policy applies to all users, ensuring the best services and user experience.&nbsp;</li>
    //   <li>The privacy policy may change without prior notice.&nbsp;</li>
    //   <li>For inquiries about the privacy policy, contact&nbsp;<a href="mailto:support@webdoc.com.pk">support@webdoc.com.pk</a>.&nbsp;</li>
    //   <li>We use cookies to enhance user experience and track navigation and search information.&nbsp;</li>
    //   </ul>`;

    this.terms = `
<p>Basi Fact Sheet</p>
<div style="max-width: 425px; max-height: 584px; overflow: auto; border: 1px solid; ">
  <table style="width: 100%; border-collapse: collapse; text-align: left;">
    <tbody>
      <tr>
        <td colspan="3" style="padding: 10px; font-weight: bold;">
        Call A Doc – Tele doctor service.
      </td>
    </tr>
    <tr style="background-color: #f1f1f1; font-weight: bold;">
      <td style="width: 100px; padding: 8px; border: 1px solid #ddd;">Plan</td>
      <td style="width: 50px; padding: 8px; border: 1px solid #ddd;">Price</td>
      <td style="width: 150px; padding: 8px; border: 1px solid #ddd;">Features</td>
    </tr>
    <tr>
      <td rowspan="2" style="width: 100px; padding: 8px; border: 1px solid #ddd;"">Monthly</td>
      <td rowspan="2" style="width: 50px; padding: 8px; border: 1px solid #ddd;"">PKR 150</td>
      <td style="width: 100px; padding: 8px; border: 1px solid #ddd;">Unlimited Video Calls</td>
    </tr>
    <tr>
      <td style="width: 100px; padding: 8px; border: 1px solid #ddd;">Audio Calls Unlimited</td>
    </tr>
    <tr>
      <td rowspan="2" style="width: 100px; padding: 8px; border: 1px solid #ddd;"; text-align: center;">Yearly</td>
      <td rowspan="2" style="width: 50px; padding: 8px; border: 1px solid #ddd;"; text-align: center;">PKR 999</td>
      <td style="width: 100px; padding: 8px; border: 1px solid #ddd;">Unlimited Video Calls</td>
    </tr>
    <tr>
      <td style="width: 100px; padding: 8px; border: 1px solid #ddd;">Audio Calls Unlimited</td>
    </tr>
  </tbody>
</table>
</div>
<p>&nbsp;</p>
<p><strong>Product Details</strong> / <strong></strong><strong>پروڈکٹ</strong> <strong>کی</strong> <strong>تفصیلات</strong></p>
<p>online doctor consultation, a platform to access doctors throughout the day from 9 am - 9 pm.</p>
<p>, ڈاکٹر کنسلٹیشن پیش کرتا ہے، جہاں آپ صبح 9 بجے سے رات 9 بجے تک ڈاکٹرز تک رسائی حاصل کر سکتے ہیں۔</p>
<p>All our Doctors are GP&rsquo;s (General Practitioners) &amp; have plenty of experience.</p>
<p>ہمارے تمام ڈاکٹرز جنرل پریکٹیشنرز (GP) ہیں اور ان کے پاس کافی تجربہ ہے۔</p>
<p>All Doctors are registered with Pakistan Medical Council.</p>
<p>تمام ڈاکٹرز پاکستان میڈیکل کونسل کے ساتھ رجسٹرڈ ہیں۔</p>
<p>The Doctors are trained to serve over the phone, online.</p>
<p>ڈاکٹرز کو فون اور آن لائن طریقے سے خدمات فراہم کرنے کی تربیت دی گئی ہے۔</p>
<p>For complete understanding of how we diagnose health issues, the process is explained below.</p>
<p>صحت کے مسائل کی تشخیص کے طریقہ کار کو بہتر طور پر سمجھنے کے لیے، عمل نیچے بیان کیا گیا ہے۔</p>
<p><strong>Details</strong></p>
<p><strong>تفصیلات</strong></p>
<p>Customers can use the Easypaisa app for video calls or dial 051-849-3737 to avail the facility.</p>
<p>کسٹمرز ویڈیو کال کے لیے Easypaisa ایپ استعمال کر سکتے ہیں یا 7 3 7 3- 9 4 8- 1 5 0 پر کال کر کے اس سہولت سے فائدہ اٹھا سکتے ہیں۔</p>
<p>We provide initial consultation, counseling, and prescriptions where required.</p>
<p>ہم ابتدائی کنسلٹیشن، کاؤنسلنگ، اور ضرورت پڑنے پر نسخے فراہم کرتے ہیں۔</p>
<p>In case we need to refer the patient, please note that we don&rsquo;t have contracts but we refer to the best physicians.</p>
<p>اگر مریض کو کسی اور ڈاکٹر کے پاس بھیجنے کی ضرورت ہو، تو براہ کرم نوٹ کریں کہ ہمارے پاس کسی کے ساتھ معاہدے نہیں ہیں، لیکن ہم بہترین فزیشنز کی طرف رجوع کراتے ہیں۔</p>
<p>Where required, we may also ask for special investigations to understand the case fully, which includes Blood Tests, MRI&rsquo;s, or any other procedures which may be required.</p>
<p>ضرورت پڑنے پر، ہم کیس کو بہتر طور پر سمجھنے کے لیے خصوصی ٹیسٹس کی درخواست کر سکتے ہیں، جیسے بلڈ ٹیسٹ، MRI یا کوئی دیگر ضروری طریقہ کار۔</p>
<p>The doctor services are for the family. Any member of the family can be seen on the phone.</p>
<p>ڈاکٹر کی خدمات خاندان کے لیے ہیں۔ خاندان کا کوئی بھی فرد فون پر ڈاکٹر سے مشورہ کر سکتا ہے۔</p>
<p>Please NOTE that the calls access to doctors are unlimited; however, in events where we believe that the customer(s) are misusing the lines, we may refuse to further facilitate the customers.</p>
<p>براہ کرم نوٹ کریں کہ ڈاکٹرز تک کالز کی رسائی لامحدود ہے؛ تاہم، اگر ہمیں لگے کہ کسٹمر لائن کا غلط استعمال کر رہا ہے، تو ہم مزید سہولت فراہم کرنے سے انکار کر سکتے ہیں۔</p>
<p>Any bad language to our doctors is taken very strictly, and we reserve the right to block such customers despite their subscription.</p>
<p>ہمارے ڈاکٹرز کے ساتھ کسی بھی قسم کی بدتمیزی کو سخت طور پر لیا جائے گا، اور ہمارے پاس ایسے کسٹمرز کو بلاک کرنے کا حق محفوظ ہے، چاہے ان کی سبسکرپشن ہی کیوں نہ ہو۔</p>
<p><strong>DISCLAIMER:</strong></p>
<p><strong>انتباہ:</strong></p>
<p>It is to notify that Please DON&rsquo;T use us in emergency conditions.</p>
<p>براہ کرم نوٹ کریں کہ ہمیں ایمرجنسی حالات میں استعمال نہ کریں۔</p>
<p>Please NOTE that WE ARE NON-EMERGENCY SERVICES &amp; DOCTORS ARE GENERAL PRACTITIONERS ONLY.</p>
<p>براہ کرم نوٹ کریں کہ ہم ایمرجنسی سروسز نہیں ہیں اور ہمارے ڈاکٹرز صرف جنرل پریکٹیشنرز ہیں۔</p>
<p>FOR ANY EMERGENCIES, PLEASE CONTACT THE NEAREST HOSPITALS.</p>
<p>کسی بھی ایمرجنسی کے لیے، براہ کرم قریبی ہسپتال سے رابطہ کریں۔</p> 
  `;
  }

  getAllItems() {
    return this.items;
  }

  getTerms() {
    return this.terms;
  }
}

export default calladoc_packages;
