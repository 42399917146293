export const PaymentInquiry = async (apiKey, transactionId) => {
  const formData = new FormData();
  formData.append("transactionId", transactionId);
  formData.append("apikey", apiKey);

  try {
    const response = await fetch(
      `https://miniappeasypaisa.webddocsystems.com/public/api/v1/inquire-paymentNew`,
      {
        method: "POST",
        body: formData,
      }
    );

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Payment Inquiry Error:", error);
    throw error; // Re-throw error to allow the caller to handle it
  }
};
