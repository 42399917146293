import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useLoading } from "../contexts/LoadingContext";
import { PaymentInquiry } from "../components/inquire-payment";
const PaymentConfirm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [params, setParams] = useState({});
  const { isLoading, setIsLoading } = useLoading();

  useEffect(() => {
    const state = location.state || {};
    setIsLoading(true);
    setParams(state);
    console.log(state.tid);
    const userData = JSON.parse(sessionStorage.getItem("user"));

    //if free package subscription ends then coment this if condition
    //===============>
    if (state.pid == 1248) {
      const payload = {
        openId: userData.openId,
        orderId: state.tid,
        Price: '200',
        coverage: String(state.coverage),
        transactionStatus: "Success",
        mobileNumber: userData.userMsisdn,
        name: userData.userName,
        transactionId: state.tid,
        packageId: parseInt(state.pid, 10),
      };

      const handleSubscription = (url, payload) => {
        fetch(url, {
          method: "POST",
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
            Authorization:
              "Basic ZlJqZGZVbWFzOmFmMzNhZmNhODAwNzZkOWMxMTViOGU0NjAyZjc4YTIx",
          },
          body: JSON.stringify(payload),
        })
          .then((response) => response.json())
          .then((data) => {
            //alert(data);
            if (data.responseCode === "0000") {
              sessionStorage.setItem("redirectFromPaymentSuccess", "true");
              setIsLoading(true);
              navigate(-1);
            } else {
              console.error("Transaction failed:", data.message);
              sessionStorage.setItem("redirectFromPaymentFailed", "true");
              setIsLoading(true);
              navigate(-1);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            sessionStorage.setItem("redirectFromPaymentFailed", "true");
            setIsLoading(true);
            navigate(-1);
          });
      };

      handleSubscription(
        "https://insuranceservice.webddocsystems.com/api/MiniApp/ActivatePackage",
        payload
      );
      return;
    }
     //till here
    //<===============

    if (state.status !== "F") {
      PaymentInquiry("Mobile", state.tid).then((response) => {
        console.log("PaymentInquiry:::", response);
        if (response.paymentStatus === "SUCCESS") {
          const handleSubscription = (url, payload) => {
            fetch(url, {
              method: "POST",
              headers: {
                Accept: "text/plain",
                "Content-Type": "application/json",
                Authorization:
                  "Basic ZlJqZGZVbWFzOmFmMzNhZmNhODAwNzZkOWMxMTViOGU0NjAyZjc4YTIx",
              },
              body: JSON.stringify(payload),
            })
              .then((response) => response.json())
              .then((data) => {
                //alert(data);
                if (data.responseCode === "0000") {
                  sessionStorage.setItem("redirectFromPaymentSuccess", "true");
                  setIsLoading(true);
                  navigate(-1);
                } else {
                  console.error("Transaction failed:", data.message);
                  sessionStorage.setItem("redirectFromPaymentFailed", "true");
                  setIsLoading(true);
                  navigate(-1);
                }
              })
              .catch((error) => {
                console.error("Error:", error);
                sessionStorage.setItem("redirectFromPaymentFailed", "true");
                setIsLoading(true);
                navigate(-1);
              });
          };
          const payload = {
            openId: userData.openId,
            orderId: state.tid,
            Price: String(state.price),
            coverage: String(state.coverage),
            transactionStatus: "Success",
            mobileNumber: userData.userMsisdn,
            name: userData.userName,
            transactionId: state.tid,
            packageId: parseInt(state.pid, 10),
          };
          if (state.type === "calladoc") {
            const formData = new FormData();
            formData.append("pid", state.pid);
            formData.append("patientid", userData.applicationUserId);
            formData.append("openId", userData.openId);
            //This api function is hosted on plesk server
            fetch(
              "https://miniappeasypaisa.webddocsystems.com/public/api/v1/subscription",
              {
                method: "POST",
                body: formData,
              }
            )
              .then((response) => response.json())
              .then(() => {
                sessionStorage.setItem("redirectFromPaymentSuccess", "true");
                setIsLoading(true);
                navigate(-1);
              })
              .catch((error) => {
                console.error("Error:", error);
                sessionStorage.setItem("redirectFromPaymentFailed", "true");
                setIsLoading(true);
                navigate(-1);
              });
          } else {
            let url = "";
            switch (state.type) {
              case "pocket":
                url =
                  "https://insuranceservice.webddocsystems.com/api/MiniApp/PocketSizeActivatePackage";
                break;
              case "insurance":
                url =
                  "https://insuranceservice.webddocsystems.com/api/MiniApp/ActivatePackage";
                break;
              case "rsa":
                //Agr isko call krwna hai tu Mr. Fahad se contact krna hai.
                // url = "https://insuranceservice.webddocsystems.com/api/MiniApp/MiniAppPackageActivationRSA";
                url =
                  "https://insuranceservice.webddocsystems.com/api/MiniApp/ActivatePackage";
                break;
              case "agri":
                url =
                  "https://insuranceservice.webddocsystems.com/api/MiniApp/MiniAppPackageActivationAgri";
                break;
              case "vets":
                url =
                  "https://insuranceservice.webddocsystems.com/api/MiniApp/MiniAppPackageActivationVetnary";
                break;
              default:
                navigate(-1);
                return;
            }
            handleSubscription(url, payload);
          }
        } else {
          console.log("paymentStatus");
          setIsLoading(true);
          navigate(-1);
          sessionStorage.setItem("redirectFromPaymentFailed", "true");
        }
      });
    } else {
      console.log("F");
      // sessionStorage.setItem("redirectFromPaymentFailed", "true");
      // setIsLoading(true);
      navigate(-1);
    }
  }, [location]);

  return <div></div>;
};

export default PaymentConfirm;
