import comprehensive from "./comprehensive";
import start from "./start";
import thirdpartytheft from "./thirdpartytheft";
import thirdparty from "./thirdparty";
import vehiclemake from "./vehiclemake";
import vehiclemodel from "./vehiclemodel";
import vehicleyear from "./vehicleyear";
import vehicleprice from "./vehicleprice";
import confirmprice from "./confirmprice";
import additionalinfo from "./additionalinfo";
import depreciationmessage from "./depreciationmessage";
import gapinsurance from "./gapinsurance";
import promotions from "./promotions";
import savequotation from "./savequotation";
import promotionsstep2 from "./promotionsstep2";
import paymentoptions from "./paymentoptions";
import customvehiclemake from "./customvehiclemake";
import vehicalregistration from "./vehicalregistration";
import additionalcover from "./additionalcover";
import sendtosurveyor from "./sendtosurveyor";
import allowcamera from "./allowcamera";
import preparinginsurancepolicy from "./preparinginsurancepolicy";
import vehicalchassis from "./vehicalchassis";
import providecnic from "./providecnic";
import comprehensiveinfo from "./comprehensiveinfo";
import isexcellent from "./isexcellent";
import isgood from "./isgood";
import isbad from "./isbad";
import unstaisfiedinfo from "./unstaisfiedinfo";
import payment from "./payment";
import insurancepayment from "./insurancepayment";
import vehicalcolor from "./vehicalcolor";
import getmysavequote from "./getmysavequote";
import getmysavedpolicy from "./getmysavedpolicy";
import policypaper from "./policypaper";
import vehicalregistrationConfirm from "./vehicalregistrationConfirm";
import vehicalcolorConfirm from "./vehicalcolorConfirm";
import providecnicConfirm from "./providecnicConfirm";
import PolicyEmail from "./policyemail";
import getmypendingpolicy from "./getmypendingpolicy";
const getDataCarInsurance = async (type, msg) => {
  console.log("type select ........", type);
  let newMessage;
  if (type === "start") {
    newMessage = new start(msg);
  } else if (type === "comprehensive") {
    newMessage = new comprehensive(msg);
  } else if (type === "comprehensive") {
    newMessage = new comprehensive(msg);
  } else if (type === "comprehensive") {
    newMessage = new comprehensive(msg);
  } else if (type === "thirdpartytheft") {
    newMessage = new thirdpartytheft(msg);
  } else if (type === "thirdparty") {
    newMessage = new thirdparty(msg);
  } else if (type === "vehiclemake") {
    newMessage = new vehiclemake(msg);
  } else if (type === "vehiclemodel") {
    newMessage = new vehiclemodel(msg);
  } else if (type === "vehicleyear") {
    newMessage = new vehicleyear(msg);
  } else if (type === "vehicleprice") {
    newMessage = new vehicleprice(msg);
  } else if (type === "confirmprice") {
    newMessage = new confirmprice(msg);
  } else if (type === "depreciationmessage") {
    newMessage = new depreciationmessage(msg);
  } else if (type === "gapinsurance") {
    newMessage = new gapinsurance(msg);
  } else if (type === "promotions") {
    newMessage = new promotions(msg);
  } else if (type === "savequotation") {
    newMessage = new savequotation(msg);
  } else if (type === "promotionsstep2") {
    newMessage = new promotionsstep2(msg);
  } else if (type === "paymentoptions") {
    newMessage = new paymentoptions(msg);
  } else if (type === "additionalinfo") {
    newMessage = new additionalinfo(msg);
  } else if (type === "vehicalregistration") {
    newMessage = new vehicalregistration(msg);
  } else if (type === "customvehiclemake") {
    newMessage = new customvehiclemake(msg);
  } else if (type === "additionalcover") {
    newMessage = new additionalcover(msg);
  } else if (type === "sendtosurveyor") {
    newMessage = new sendtosurveyor(msg);
  } else if (type === "allowcamera") {
    newMessage = new allowcamera(msg);
  } else if (type === "preparinginsurancepolicy") {
    newMessage = new preparinginsurancepolicy(msg);
  } else if (type === "vehicalchassis") {
    newMessage = new vehicalchassis(msg);
  } else if (type === "providecnic") {
    newMessage = new providecnic(msg);
  } else if (type === "comprehensiveinfo") {
    newMessage = new comprehensiveinfo(msg);
  } else if (type === "isexcellent") {
    newMessage = new isexcellent(msg);
  } else if (type === "isgood") {
    newMessage = new isgood(msg);
  } else if (type === "isbad") {
    newMessage = new isbad(msg);
  } else if (type === "unstaisfiedinfo") {
    newMessage = new unstaisfiedinfo(msg);
  } else if (type === "payment") {
    newMessage = new payment(msg);
  } else if (type === "vehicalcolor") {
    newMessage = new vehicalcolor(msg);
  } else if (type === "getmysavequote") {
    newMessage = new getmysavequote(msg);
  } else if (type === "getmysavedpolicy") {
    newMessage = new getmysavedpolicy(msg);
  } else if (type === "getmypendingpolicy") {
    newMessage = new getmypendingpolicy(msg);
  } else if (type === "policypaper") {
    newMessage = new policypaper(msg);
  } else if (type === "insurancepayment") {
    newMessage = new insurancepayment(msg);
  } else if (type === "vehicalregistrationConfirm") {
    newMessage = new vehicalregistrationConfirm(msg);
  } else if (type === "vehicalcolorConfirm") {
    newMessage = new vehicalcolorConfirm(msg);
  } else if (type === "providecnicConfirm") {
    newMessage = new providecnicConfirm(msg);
  } else if (type === "policy") {
    newMessage = new PolicyEmail(msg);
  }

  return {
    modal: await newMessage.getAllItems(),
  };
};

export default getDataCarInsurance;
