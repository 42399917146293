class promotions {
    constructor(data) {
        const price = data.price;
        this.items = {
            id: 30001,
            name: "Promotions",
            type: "text",
            slideimg: "/doctorassistant/dia.jpg",
            options: [
                { id: 40012, text: "Proceed", name: "Proceed", price: price, type: "paymentoptions", desc: "Proceed", inputtype: "button" },
                // { id: 40012, text: "Go Back", name: "Go Back", price: price, type: "gapinsurance", desc: "Go Back", inputtype: "button" },
            ],
            backtostart: false,
            text: [
                `<p>Let me search if there are any promotions running on Car insurance.</p>`,
                // `<p>Your lucky day. Easypaisa is offering 2% discount and a chance to win Rs. 10,000 cash in a lucky draw.</p>`,
                `<p>Your lucky day. Your are now a part of lucky draw which can win you Rs. 10,000 cash. Good Luck.</p>`,
            ]
        };
    }

    getAllItems() {
        return this.items;
    }
}

export default promotions;
