class ComplaintEnd {
  constructor() {
    this.items = {
      id: 50001,
      name: "About",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [],
      backtostart: true,
      text: `The complaint has now been generated. Our manager will revert in 48 hours. `,
    };
  }

  getAllItems() {
    return this.items;
  }
}

export default ComplaintEnd;
