// src/components/ActivatedPackageComponent.js
import React, { useState } from "react";
import Swal from "sweetalert2";
import "../../src/style.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoading } from "../contexts/LoadingContext";
import { BsDatabaseCheck } from "react-icons/bs";

const ActivatedPackageComponent = ({ items }) => {
  console.log(items);
  const { isLoading, setIsLoading } = useLoading();
  // console.log(items)
  const [transactions, setTransactions] = useState([]);

  const userData = JSON.parse(sessionStorage.getItem("user"));
  const number = userData?.userMsisdn || null;
  const navigate = useNavigate();
  const location = useLocation();
  const noUnsubButton = [
    "/health",
    "/income",
    "/accidental",
    "/car",
    "/bike",
    "/crop",
    "/womenHealth",
    "/liveStock",
    "/mobile",
    "/monthly-mobile",
    "/monthly-bike-insurance",
  ];

  const redirectFromPaymentSuccess = sessionStorage.getItem(
    "redirectFromPaymentSuccess"
  );
  if (redirectFromPaymentSuccess === "true") {
    Swal.fire({
      icon: "info",
      title: "Confirmation",
      text: "Your package has been activated successfully.",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then(() => {
      // Clear the flag from sessionStorage
      sessionStorage.removeItem("redirectFromPaymentSuccess");
    });
  }

  const handleUnsubscribe = (pidsub) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to unsubscribe from this service?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, unsubscribe",
      cancelButtonText: "Cancel",
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const user = JSON.parse(sessionStorage.getItem("user"));
        const OpenId = user.openId;
        const pid = pidsub;
        const service = "Insurance"; // Use the appropriate service name
        const number = user.userMsisdn || null;
        setIsLoading(true);
        try {
          const response = await fetch(
            `https://insuranceservice.webddocsystems.com/api/MiniApp/UnsubServices`,
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization:
                  "Basic ZlJqZGZVbWFzOmFmMzNhZmNhODAwNzZkOWMxMTViOGU0NjAyZjc4YTIx",
              },
              body: JSON.stringify({
                OpenId,
                service,
                pid,
                number,
              }),
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const data = await response.json();
          console.log("api ====", data);
          if (data.responseCode === "0000") {
            // Remove the package from the active subscriptions
            const updatedTransactions = transactions.filter(
              (t) => t.activePackage.productId !== pid
            );
            setIsLoading(false);
            // Update the state to reflect the changes
            setTransactions(updatedTransactions);

            Swal.fire({
              title: "Unsubscribed!",
              text: "You have successfully unsubscribed from the service.",
              icon: "success",
              confirmButtonText: "OK",
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(true);
              window.location.reload();
              //navigate("/income")
            });
          } else {
            Swal.fire({
              title: "Error!",
              text: data.message,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
            });
          }
        } catch (error) {
          console.error("Error unsubscribing:", error);
          Swal.fire({
            title: "Error!",
            text: "There was an error unsubscribing from the service. Please try again later.",
            icon: "error",
            confirmButtonText: "OK",
            allowOutsideClick: false,
          });
        }
      }
    });
  };
  // console.log(items);

  return (
    <div className="container-fluid">
      <div className="container">
        <div className="row mt-2  position-relative">
          <h6 className="fw-bold my-2 ps-0">Active Package</h6>
          <div className="card selected_package">
            <div className="card-header p-3 bg-white">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-end">
                  <div className="flex-shrink-0">
                    <BsDatabaseCheck fontSize={35} />
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <h5 className="fw-bold mb-0">
                      {items.pkgPrice}/
                      <span
                        className="fw-light text-secondary"
                        style={{ fontSize: "12px" }}
                      >
                        {items.pName}
                      </span>
                    </h5>
                  </div>
                </div>
                <p class="mb-0">
                  <span
                    class="badge mb-1 mt-1 fw-light fs-6 rounded-pill"
                    style={{ backgroundColor: "#1bb367" }}
                  >
                    Subscribed
                  </span>
                </p>
              </div>
            </div>
            <div className="card-body">
              <h6 className="fw-bold">
                Package: <span>{items.pName}</span>
              </h6>
              <ol className="package_detail_list mb-0">
                <li>
                  <p className=" mb-1">
                    Active Date :{" "}
                    <span
                      className="fw-light text-secondary"
                      style={{ fontSize: "12px" }}
                    >
                      {items.activateDate}
                    </span>
                  </p>
                </li>
                <li>
                  <p className=" mb-1">
                    Expire Date :{" "}
                    <span
                      className="fw-light text-secondary"
                      style={{ fontSize: "12px" }}
                    >
                      {items.expiryDate}
                    </span>
                  </p>
                </li>
                {/* <li>
                  <p className="mb-0">For claim and more information dial</p>
                  <p className="mb-0">0518493737</p>
                </li> */}
              </ol>
            </div>
          </div>
          <div className="col-12 pt-2 pb-3 mb-3 text-center border-bottom"></div>
          <div className="col-12 px-0">
            {items.type === "agri" || items.type === "vets" ? (
              <p className="dark-border rounded-border bg-secondary p-2  fs-15 text-center">
                <strong style={{ fontSize: "20px", color: "white" }}>
                  Call Now 0518493737
                </strong>
              </p>
            ) : (
              <div
                className="card p-2 text-white text-center mb-3"
                style={{ backgroundColor: "#1bb367" }}
              >
                <div className="">
                  <p className="mb-0">For Claim and more information dial</p>
                </div>
                <a
                  href="tel:0518493737"
                  className="d-block text-decoration-none fw-bold text-white"
                  style={{ fontSize: "30px" }}
                >
                  {" "}
                  051 8493737
                </a>
              </div>
            )}
          </div>
          <div className="col-12">
            <p
              className="custome-textfont"
              dangerouslySetInnerHTML={{ __html: items.features }}
            ></p>
          </div>
          {/* <p className="card-text mt-3">{items.features}</p> */}
        </div>
        {noUnsubButton.includes(location.pathname) && (
          <div className="row justify-content-center mb-2">
            <div className="col-6 col-md-3 d-flex justify-content-start align-items-center">
              <button
                type="button"
                className="mt-3 btn btn-danger w-100 rounded-pill"
                onClick={() => handleUnsubscribe(items.pid)}
              >
                Unsubscribe
              </button>
            </div>
          </div>
        )}
        <div className="d-flex justify-content-end align-items-end mt-1 mb-1">
          <p className="custome-textfont mb-0 text-end me-1 text-secondary">
            Powered By
          </p>
          <img
            src="/assets/NewMiniapp/powerd-by-log.png"
            alt="Logo"
            className="img-fluid"
            width={40}
          />
        </div>
      </div>
    </div>
  );
};

export default ActivatedPackageComponent;
