class vehicleprice {
    constructor() {
        this.items = {
            id: 30001,
            name: "Vehicle Price",
            type: "text",
            slideimg: "/doctorassistant/dia.jpg",
            options: [
                { id: 40012, text: "Enter Price", name: "Enter Price", price: "", type: "confirmprice", desc: "Enter Price", inputtype: "textinput" },
            ],
            backtostart: false,
            text: [
                `<p>Enter the market price of your vehicle.</p> `
            ]
        };
    }

    getAllItems() {
        console.log(sessionStorage.getItem("car_information"))
        return this.items;
    }
}

export default vehicleprice;
