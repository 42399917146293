class start {
  constructor(data) {
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    this.items = {
      id: 30001,
      name: "Start Text",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40012,
          text: "Need RSA mechanic services",
          name: "Need RSA mechanic services",
          price: "",
          type: "car",
          desc: "I’d like to get the RSA mechanic services",
          key: "insurance_type",
        },
        // {
        //   id: 40012,
        //   text: "Need RSA services for Bike",
        //   name: "Need RSA services for Bike",
        //   price: "",
        //   type: "car",
        //   desc: "I’d like to get the RSA services for Bike",
        //   key: "rsatype",
        //   value: "Need RSA services for bike",
        // },
        {
          id: 40011,
          text: "About RSA Pakistan",
          name: "About RSA Pakistan",
          price: "",
          type: "about",
          desc: "I’d like to know about RSA Pakistan",
        },
      ],
      backtostart: false,
      text: [
        `<p>Hello, ${sessionUser.userName}! 👋 Welcome to <strong><span style="color: #ef7e0e;">RSA</span></strong>. How can I assist you today?</p> `,
      ],
    };
  }

  async getAllItems() {
    ["RSA-Chat", "activation", "payment-info", "customer-detail"].forEach(item => sessionStorage.removeItem(item));

    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    const { userName, userMsisdn } = sessionUser;
    const registerPayload = {
      mobileNumber: userMsisdn,
      customerName: userName,
      gender: "Male",
      pin: "1234",
      os: "MINIAPP EP",
      devicetoken: "string",
    }
    try {
      const response = await fetch("https://webservice.rsapakistan.com/api/Portal/RegisterBackend", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(registerPayload),
      });
      const data = await response.json();
      sessionStorage.setItem("customer-detail",JSON.stringify(data.customerDetail.id))
      console.log("Response:", data.customerDetail);
    return this.items;
      
    } catch (error) {
      console.log("RegisterBackend error", error);
    return this.items;

    }
  }
}

export default start;
