import apiPayload from "../../components/rsachat/apiPayload";
import { sendEmail } from "../../services/api";
class haveinsurance {
  constructor(data) {
    this.items = {
      id: 30001,
      name: "Start Text",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40012,
          text: "Excellent",
          name: "Excellent 😊",
          price: "",
          type: "excellent",
          desc: "Excellent",
          key: "feedback",
        },
        {
          id: 40012,
          text: "Satisfactory",
          name: "Satisfactory 🙂",
          price: "",
          type: "good",
          desc: "Satistactory",
          key: "feedback",
        },
        {
          id: 40012,
          text: "Un-Satistactory",
          name: "Un-Satistactory 😞",
          price: "",
          type: "bad",
          desc: "Un-Satistactory",
          key: "feedback",
        },
      ],
      backtostart: false,
      text: [
        `<p>Great! We will call you shortly </p> `,
        `<p>Please provide feedback to improve our services</p>`,
      ],
    };
  }
  async getAllItems() {
    try {
      const session = JSON.parse(sessionStorage.getItem("user"));
      if (!session) {
        return this.items;
      }

      const message = `
          So please call the 
          mentioned number he need recovery service.
          User Number: ${session.userMsisdn}
          User Name: ${session.userName}
        `.trim();

      const response = await sendEmail(
        "support@webdoc.com.pk",
        "RSA Recovery Service",
        message
      );
      console.log("Complaint generated message:", message);
      console.log("Complaint generated successfully:", response);
      return this.items;
    } catch (error) {
      console.error("Failed to generate complaint:", error);
      // You might want to modify this.items to show an error message
      this.items.text = "Failed to generate complaint. Please try again later.";
      return this.items;
    }
  }
}

export default haveinsurance;
