import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const CarInsurancePolicyComprehansive = () => {
  const { id } = useParams();
  const [policy, setPolicy] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const data = {
      id: id,
    };
    fetch(
      "https://Digital.webdoc.com.pk/Promotion/public/api/v1/chat/car-insurance/get-policy",
      {
        method: "POST",
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.statusCode === 1) {
          setPolicy(data.payLoad);
        } else {
          throw new Error("Failed to fetch policy details");
        }
      })
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  }, [id]);

  if (loading)
    return <p className="text-center text-lg font-semibold">Loading...</p>;
  if (error)
    return (
      <p className="text-center text-red-500 font-semibold">Error: {error}</p>
    );

  return (
    <div className="max-w-4xl mx-auto p-8 bg-white shadow-md rounded-lg border">
      <div className="col-3 text-center absolute bg-white rounded-pill fw-normal py-1">
            <img
              className="mb-0 custom-heading w-50 h-50"
              src="/assets/MiniAppAssets/logo.png"
            ></img>
            {/* <h6 className="mb-0 custom-heading bg-white rounded-pill fw-normal py-1" dangerouslySetInnerHTML={{ __html: title }}></h6> */}
          </div>
      <h5 className="text-2xl font-bold text-center uppercase mt-4">
        East West Insurance Co. Ltd.
      </h5>
      <p className="text-lg text-center font-semibold">
        MOTOR COVER NOTE - COMPREHENSIVE INSURANCE
      </p>
      {/* <hr className="my-4 border-gray-300" /> */}
      <div className="container">
        <div className="row justify-content-end mt-4">
          <div className="col-lg-4 col-md-6">
            <p className="mb-0">
              <strong>COVER NOTE DATE:</strong> {policy.created_at}
            </p>
            <p className="mb-0">
              <strong>CNIC NO:</strong> {policy.cnic_number}
            </p>
          </div>
        </div>
        <div className="">
          <p className="mb-0">
            <strong>Cover Note No:</strong> CMVP/04/000004/02/2025
          </p>
          <p className="mb-0">
            <strong>Insured Name:</strong> XXXXXXXXX
          </p>
          <p className="mb-0">
            <strong>Address:</strong> XXXXxXXXXX
          </p>
          <p className="mb-0">
            <strong>Period of Insurance:</strong> 04-02-2025 to 03-02-2026
          </p>
        </div>
        <div className="card border border-dark rounded-0 p-2">
          <div className="row">
            <div className="col-md-4">
              <p className="mb-0">
                <strong>Reg No:</strong> {policy.car_registration_no}
              </p>
            </div>
            <div className="col-md-4">
              <p className="mb-0">
                <strong>Make:</strong> KIA
              </p>
            </div>
            <div className="col-md-4">
              <p className="mb-0">
                <strong>Body:</strong> SALOON
              </p>
            </div>
            <div className="col-md-4">
              <p className="mb-0">
                <strong>Model:</strong> 2024
              </p>
            </div>
            <div className="col-md-4">
              <p className="mb-0">
                <strong>CC Power:</strong> 3500
              </p>
            </div>
            <div className="col-md-4">
              <p className="mb-0">
                <strong>Seats:</strong> 5
              </p>
            </div>
            <div className="col-md-4">
              <p className="mb-0">
                <strong>Colour:</strong> {policy.car_color}
              </p>
            </div>
          </div>
        </div>
        <p className="font-bold text-left uppercase fw-bold mt-1">
          Attached Clauses & Endorsements:
        </p>
        <p className="mb-0">
          (1) Transfer of Interest (2) Market Value Clause (3) Jurisdiction
          Clause (4) CNG Cylinder Clause (5) Premium Payment Clause (6) Subject
          to awareness Clause (7) Subject to No Loss till date
        </p>

        <p className="mb-0">
          (1) Transfer of Interest (2) Market Value Clause (3) Jurisdiction
          Clause (4) CNG Cylinder Clause (5) Premium Payment Clause (6) Subject
          to awareness Clause (7) Subject to No Loss till date
        </p>
        <p className="font-bold text-left uppercase fw-bold">
          Limitation as to use:
        </p>
        <p className="mb-0">
          Use only for social domestic and pleasure purposes and for the insured
          business. The Policy does not cover use for higher or reward or for
          organized racing, pace making, reliability trial, speed testing, the
          carriage of purpose in connection with the Motor Trade.
        </p>
        <p className="font-bold text-left uppercase fw-bold">Driver:</p>
        <p className="mb-0">
          Any person provided that the person driving holds a valid license to
          drive the motor car or has held and is not disqualified for holding or
          obtaining such license. The company shall not be liable to make any
          payment in respect of loss arising from theft or criminal
          misappropriation or criminal breach of trust by insured's driver or
          known person. 
          <br>
          </br>
          The cover is restricted to fire, theft, and third-party
          liability only. Other types of damage, including accidental damage or
          losses due to vandalism or natural disasters (excluding fire), are not
          covered.
        </p>
        <p className="font-bold text-center uppercase fw-bold mt-2">
          IMPORTANT NOTICE
        </p>
        <p className="card border border-dark rounded-0 p-2">
          THE INSURED WILL NOT BE INDEMNIFIED IF THE VEHICLE IS USED OR DRIVEN
          OTHERWISE THAN IN ACCORDANCE WITH THIS SCHEDULE. ANY PAYMENT MADE BY
          THE COMPANY BY REASON OF WIDER TERMS APPEARING IN THE CERTIFICATE IN
          ORDER TO COMPLY WITH THE MOTOR VEHICLES ACT 1939, IS RECOVERABLE FROM
          THE INSURED SEE THE CLAUSE HEADED "AVOIDANCE OF CERTAIN TERMS AND
          RIGHTS OF RECOVERY".
        </p>
        <div className="row">
          <div className="col-lg-6 col-md-8">
            <p className="fw-bold mt-4 text-center">PREMIUM CALCULATION</p>
            <div className="grid grid-cols-2 gap-4 text-sm">
              <p className="d-flex justify-content-between mb-0">
                <strong>Insured Estimated Value:</strong> {policy.totalamount}{" "}
                PKR
              </p>
              <hr />
              <p className="d-flex justify-content-between mb-0">
                <strong>Amount Paid:</strong> {policy.amountpaid} PKR
              </p>
              <p className="d-flex justify-content-between mb-0">
                <strong>Gross Premium:</strong> 000,000
              </p>
              <p className="d-flex justify-content-between mb-0">
                <strong>ASC:</strong> 000,000
              </p>
              <p className="d-flex justify-content-between mb-0">
                <strong>CED:</strong> 000,000
              </p>
              <p className="d-flex justify-content-between mb-0">
                <strong>FIF:</strong> 000,000
              </p>
              <p className="d-flex justify-content-between mb-0">
                <strong>Stamp Duty:</strong> 000,000
              </p>
              <p className="d-flex justify-content-between mb-0">
                <strong>Advance Tax:</strong> 000,000
              </p>
            </div>

            <hr/>
            <p className="d-flex justify-content-between mb-0">
                <strong>Advance Tax:</strong> 000,000
              </p>
            <hr />

          </div>
        </div>
        <p className="mt-6">
          This Cover Note is valid up to{" "}
          <strong>
            {" "}
            auto fetch date 15 days after issuance of cover note.
          </strong>
        </p>
        <p className="mb-0">
          This contract is deemed to have been made on this{" "}
          <strong> Cover Note issuance date </strong> at Islamabad, and claims
          arising thereunder, if any, shall be at Islamabad.
        </p>
        <p className="fw-bold text-end mt-5">
          <span className="border-top border-dark pt-2">
            AUTHORIZED SIGNATORY
          </span>
        </p>
      </div>

      {/* <hr className="my-4 border-gray-300" /> */}
    </div>
  );
};

export default CarInsurancePolicyComprehansive;
