class payment {
  constructor() {
    this.items = {
      id: 30003,
      name: "calladoc",
      type: "text",
      options: [],
      backtostart: false,
      text: ``,
      slideimg: "/doctorassistant/dia.jpg",
      issuccess: false,
    };
  }
  // getAllItems() {
  //   return this.items;
  // }
  async getAllItems(price, pid) {
    const item = sessionStorage.getItem("user");
    const sess = JSON.parse(item);
    const form = new FormData();
    form.append("value", price);
    form.append("apikey", "Vet");

    const settings = {
      method: "POST",
      body: form,
    };
    try {
      const username = "fRjdfUmas";
      const password = "af33afca80076d9c115b8e4602f78a21";
      const credentials = btoa(`${username}:${password}`);

      let transactionId = null;

      if (pid !== 1) {
        const response = await fetch(
          "https://miniappeasypaisa.webddocsystems.com/public/api/v1/create-payment",
          settings
        );
        const result = await response.json();

        if (!result.paymentTransactionID) {
          this.items.text =
            "Payment failed. Please recharge easypaisa account and try again";
          this.items.issuccess = false;
          this.items.options = this.getPackageOptions();
          return this.items;
        }

        transactionId = result.paymentTransactionID;

        // Verify payment
        const paymentStatus = await this.handlePaymentResult(transactionId);
        if (paymentStatus !== "success") {
          this.items.text =
            "Payment failed. Please recharge easypaisa account and try again";
          this.items.issuccess = false;
          this.items.options = this.getPackageOptions();
          return this.items;
        }
      }

      // Prepare API payload
      const apiPayload = {
        openId: sess.openId,
        mobileNumber: sess.userMsisdn,
        name: sess.userName,
        transactionId: transactionId || "DirectActivation",
        coverage: "Testing",
        price: price,
        transactionStatus: "success",
        orderId: transactionId || "DirectActivation",
        packageId: pid,
      };

      // Call subscription API directly if pid === 1
      const subscriptionResponse = await fetch(
        "https://insuranceservice.webddocsystems.com/api/MiniApp/MiniAppPackageActivationVetnary",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${credentials}`,
          },
          body: JSON.stringify(apiPayload),
        }
      );

      if (!subscriptionResponse.ok) {
        throw new Error("Subscription API call failed");
      }

      this.items.text =
        "<p>Payment successfully completed! Now, let me connect you with the Vet Doctor&rsquo;s Assistant. Please hold on while we set everything up for you. We appreciate your trust in <strong>WEBDOC Veterinary Advisory</strong> — your pet’s/vet’s health is our priority! 🐾💙</p>";
      this.items.issuccess = true;
      return this.items;
    } catch (error) {
      console.error("Error:", error);
      this.items.text =
        "Payment failed. Please recharge easypaisa account and try again";
      this.items.issuccess = false;
      this.items.options = this.getPackageOptions();
      return this.items;
    }
  }

  handlePaymentResult = async (paymentTransactionID) => {
    return new Promise((resolve, reject) => {
      try {
        window.mp.createPayment(paymentTransactionID);

        window.createPaymentResult = async (jsonResult) => {
          const result = JSON.parse(jsonResult);
          const status = result.paymentStatus;

          if (status === "S") {
            const item = sessionStorage.getItem("user");
            const sess = JSON.parse(item);
            sess["package"] = "true";
            const updatedItem = JSON.stringify(sess);
            sessionStorage.setItem("user", updatedItem);
            resolve("success");
          } else {
            resolve("fail");
          }
        };
      } catch (error) {
        reject("fail");
      }
    });
  };

  getPackageOptions() {
    return [
      {
        id: 1,
        text: "1 month package",
        name: "1 Month package",
        price: "0",
        isfree: true,
        type: "payment",
        desc: "<p>Unlock the ultimate convenience with unlimited audio and video consultations, giving you direct access to vet expert care &ndash; tailored to fit your busy lifestyle!</p>",
      },
      {
        id: 72,
        text: "12 month package",
        name: "12 Month package",
        price: "700",
        type: "payment",
        desc: "<p>Unlock the ultimate convenience with unlimited audio and video consultations, giving you direct access to vet expert care &ndash; tailored to fit your busy lifestyle!</p>",
      },
    ];
  }
}

export default payment;
