class additionalinfo {
  constructor(data) {
    const insType = sessionStorage.getItem("insuranceType");
    const details = JSON.parse(sessionStorage.getItem("car_information"));
    const is_gapinsurance = details.find(
      (item) => item.key === "is_gapinsurance"
    )?.value;
    const is_installment = details.find(
      (item) => item.key === "is_installment"
    )?.value;
    this.insType = insType;
    const price = data.price;
    this.paymetPrice = data.price;
    this.name = data.name;
    const marketValue = parseFloat(price);
    let gapInsuranceCost;
    let installmentCost;
    if (insType == "comprehensive") {
      if (is_gapinsurance === 1) {
        gapInsuranceCost = marketValue * 0.015;
      } else {
        gapInsuranceCost = marketValue * 0.013;
      }
      installmentCost = (gapInsuranceCost * 1.22) / 12;
    } else if (insType == "thirdpartytheft") {
      // if (is_gapinsurance === 1) {
      //   gapInsuranceCost = marketValue * 0.012 + 2000;
      // } else {
      //   gapInsuranceCost = marketValue * 0.01;
      // }
      gapInsuranceCost = marketValue * 0.01;
      installmentCost = (gapInsuranceCost * 1.22) / 12;
    } else if (insType == "thirdparty") {
      gapInsuranceCost = 5000;
      installmentCost = gapInsuranceCost;
    }
    this.gapInsuranceCost = gapInsuranceCost;
    this.installment = installmentCost;
    if (is_installment == 1) {
      details.push(
        {
          key: "installment_amount",
          value: Math.round(installmentCost),
        },
        { key: "qouteprice", value: Math.round(installmentCost) }
      );
    } else
      details.push({ key: "qouteprice", value: Math.round(gapInsuranceCost) });
    sessionStorage.setItem("car_information", JSON.stringify(details));

    this.items = {
      id: 30001,
      name: "Personal Info",
      type: "text",
      options: [],
      backtostart: true,

      slideimg: "/doctorassistant/dia.jpg",
      issuccess: false,
    };

    const user = JSON.parse(sessionStorage.getItem("user"));

    if (details && user) {
      this.carInformation = {
        userMsisdn: user.userMsisdn,
        applicationUserId: user.applicationUserId,
        ...details.reduce((acc, item) => {
          acc[item.key] = item.value;
          return acc;
        }, {}),
        status: "Pending",
      };
    }
  }

  async getAllItems() {
    const savedItems = await this.saveToAPI();
    return savedItems;
  }
  async saveToAPI() {
    const details = JSON.parse(sessionStorage.getItem("car_information"));

    try {
      const response = await fetch(
        "https://Digital.webdoc.com.pk/Promotion/public/api/v1/chat/car-insurance/save-policy",
        {
          method: "POST",
          body: JSON.stringify(this.carInformation),
        }
      );
      console.log("resposne -->", response);
      if (response.ok) {
        // console.log("Data saved successfully", this.getSuccessOptions(result?.payLoad.id));
        const result = await response.json();
        console.log("result -->", result);

        details.push({
          key: "id",
          value: result?.payLoad?.id,
        });
        sessionStorage.setItem("car_information", JSON.stringify(details));
        const baseUrl = window.location.origin;
        this.items.text = ["Payment successful proceed further"];
        this.items.issuccess = true;

        this.items.options = this.getSuccessOptions(result?.payLoad.id);

        return this.items;
      } else {
        console.error("Failed to save data");
        this.items.text = ["Failed to save data."];
        this.items.issuccess = false;

        this.items.options = this.getFailOptions(this.paymetPrice);

        return this.items;
      }
    } catch (error) {
      console.error("Error saving data:", error);
      this.items.text = ["Failed to save policy."];
      this.items.issuccess = false;

      this.items.options = this.getFailOptions();

      return this.items;
    }
  }
  getSuccessOptions(id) {
    return [
      {
        id: 40012,
        text: "Car Registration Number",
        name: "Car Registration Number",
        type: "vehicalregistration",
        desc: "Car Registration Number",
        inputtype: "button",
      },
      // {
      //   id: 40012,
      //   text: "Car chassis/Engine Number",
      //   name: "Car chassis/Engine Number",
      //   type: "vehicalchassis",
      //   desc: "Car chassis/Engine Number",
      //   inputtype: "button",
      // },
    ];
  }
  getFailOptions(price) {
    return [
      {
        id: 40012,
        text: "Retry",
        name: "Retry",
        price: price,
        type: "insurancepayment",
        desc: "retry",
        inputtype: "button",
      },
    ];
  }
}

export default additionalinfo;
