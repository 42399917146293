class PolicyPaper {
  constructor(data) {
    const id = data.id;
    const baseUrl = window.location.origin;
    this.items = {
      id: id,
      name: "Payment Options",
      type: "text",

      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: id,
          text: "Submit",
          name: "Submit",
          type: "policy",
          desc: data.desc,
          inputtype: "textinput",
        },
      ],
      backtostart: true,
      text: [
        `<p>Please provide your email address so we can share the details of the insurance policy you’ve purchased.</p>`,
        // `<p>Click to the link to view policy paper:</p>`,
        // `<p><a href="${baseUrl}/car-insurance-policy-paper/${id}" target="_blank">Click here</a>.</p>`,
      ],
    };
  }

  async getAllItems() {
    return this.items;
  }
}

export default PolicyPaper;
