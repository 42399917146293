class rsa_packages {
  constructor() {
    this.items = [
      {
        apiKey: "Bike",
        id: 175,
        name: "Bike Insurance",
        badge: "Monthly",
        coverage: "50000",
        price: "150",
        bgColorClass: "bg-info",
        description: `<p><strong>Hassle-Free Bike Insurance:</strong> Get covered quickly with our simple online registration process.</p>
<ul>
<li><strong>Total Insurance Cover:</strong> PKR 50,000</li>
<li><strong>Accidental Bike Repair:</strong> Up to PKR 15,000</li>
<li><strong>Accidental Hospitalization:</strong> Up to PKR 35,000</li>
<li><strong>RSA Coverage:</strong> Available for Rawalpindi/Islamabad</li>
</ul>
<p><strong>Swift Claims, Superior Support:</strong> Our team ensures a smooth and speedy claims experience.</p>`,
        type: "insurance",
      },
      {
        apiKey: "Bike",
        id: 176,
        name: "Bike Insurance",
        badge: "Annual",
        coverage: "100000",
        price: "1500",
        bgColorClass: "bg-warning",
        description: `<p><strong>Hassle-Free Bike Insurance:</strong> Get covered quickly with our simple online registration process.</p>
<ul>
<li><strong>Total Insurance Cover:</strong> PKR 75,000</li>
<li><strong>Accidental Bike Repair:</strong> Up to PKR 25,000</li>
<li><strong>Accidental Hospitalization:</strong> Up to PKR 50,000</li>
<li><strong>RSA Coverage:</strong> Available for Rawalpindi / Islamabad</li>
</ul>
<p><strong>Swift Claims, Superior Support:</strong> Our team ensures a smooth and speedy claims experience.</p>`,
        type: "insurance",
      },
      // {
      //   id: 25,
      //   name: "Road Side Assistance",
      //   badge: "Monthly",
      //   coverage: "",
      //   price:"100",
      //   bgColorClass: "bg-info",
      //   description: `This includes towing of the vehicle, and 24/7 roadside assistance in Islamabad/Rawalpindi.
      //     `,
      //     type: "rsa",
      // },
      // {
      //   id: 26,
      //   name: "Road Side Assistance",
      //   badge: "Annual",
      //   coverage: "",
      //   price:"1000",
      //   bgColorClass: "bg-warning",
      //   description: `This includes towing of the vehicle, and 24/7 roadside assistance in Islamabad/Rawalpindi.
      //     `,
      //     type: "rsa",
      // },
    ];

    //     this.terms = `
    //     <h3>Personal Data we collect:</h3>
    // <p>In order to completely enjoy our services, you need to sign up with the website. At the time of signup, the site will ask for some personal data and information about you. This data is not utilized anywhere and is stored to improve the user experience and security of the website.<br />The personal data we collect is only to ensure the accessibility of our services to you. No data or personal information whatsoever will be collected without your knowledge. The personal data we collect might be your name, CNIC, email address, and contact number. However, this data will not include the passport number, drivers&rsquo; license number, bank card numbers, or any other registration numbers that might be provided to you by a third party.<br />You will require to enter a password to create a private account. Make sure to protect the password at all costs. That password shouldn&rsquo;t be provided or exposed to any other entity or used with any third-party services.</p>
    // <h3>Encryption:</h3>
    // <p>To ensure maximum security and avoid any possible security breach, we may encrypt your data and password so that you can use the services without any interruption.<br />The encryption of the data is only to ensure maximum security and avoid any security breach. Your data and personal information are used to provide you with our best premium services. In order to use our services, you are advised to accept our privacy policy.<br />The website can change the encryption policy without prior notice to every user. As our user, you grant us the privilege to change the encryption policy anytime to ensure maximum security.</p>
    // <h3>Personal information we may use:</h3>
    // <p>We are destined to provide the best user experience for our users. We utilize some personal information of the users like name and contact number for notifications and consultation. You might receive our updates, notifications, and information on your contact number as well as email address that you provide at the time of signup.<br />Every information is provided by the user at the time of signup. We don&rsquo;t use any information and personal data of use other than for notifications and providing the best user experience. No data is revealed/ exposed/ used to any third-party service provided without the permission of the user. The data provided by the users is always safeguarded and not used in any other service and platform other than this website. However, in case of any security breach or data loss, the website will not be held liable to data loss.</p>
    // <h3>Queries and Complaints:</h3>
    // <p>We seek to meet the highest standards of providing services and collecting/ using user&rsquo;s personal data. If you have any questions regarding the services and content of this website, please contact our customer services department. Leave us a message regarding your issues and our team will get back to you with the most feasible solution.</p>
    // <h3>Content Creation / Copyrights:</h3>
    // <p>All of the content that is uploaded on the website is copyrighted and is a property of this website. We are responsible for every content that is uploaded on this website and we ensure that all of the content is to provide better services to our users.<br />We allow our users to create, manage and upload some content on the website. However, the user will be held responsible for the content that will be uploaded by the user. In case any user&rsquo;s content gets reported/ banned for being unethical/ unnatural, the website management will not be held responsible and will be accused in any case. Users must take responsibility of their personal information and the content they are allowed to create on this website. In case of any security breach or loss of data, no data shall be provided to users in any case if except necessary.</p>
    // <ul>
    // <li>All the users of this website must agree with the privacy policy. We look forward to providing the best services to our users and increase the user experience.</li>
    // <li>This website can change/update this privacy policy at any time without prior notice to the users of our services.</li>
    // <li>If you have questions regarding the privacy policy of&nbsp;<a href="http://www.rsapakistan.com/"><strong>RSA Pakistan</strong></a>, please contact<a href="mailto:support@rsapakistan.com"><strong>&nbsp;support@rsapakistan.com</strong></a></li>
    // </ul>
    // `;
    this.terms = `
<strong>BASIC FACT SHEET</strong>
<div style="max-width: 425px; max-height: 584px; overflow: auto; border: 1px solid; padding: 10px;">
  <table style="width: 100%; border-collapse: collapse; text-align: left;">
    <tbody>
      <tr>
        <td colspan="4" style="padding: 10px; font-weight: bold;">
          RSA BIKE - ROADSIDE ASSISTANCE
        </td>
      </tr>
      <tr>
        <td style="width: 100px; padding: 6px; border: 1px solid #ddd; font-weight: bold; font-size: 14px">Description</td>
        <td style="width: 100px; padding: 6px; border: 1px solid #ddd; font-weight: bold; font-size: 14px">Service Fee Yearly</td>
        <td style="width: 100px; padding: 6px; border: 1px solid #ddd; font-weight: bold; font-size: 14px">Service Fee Monthly</td>
        <td style="width: 150px; padding: 6px; border: 1px solid #ddd; font-weight: bold; font-size: 14px">Coverage</td>
      </tr>
      <tr>
        <td rowspan="2" style="padding: 6px;font-size: 14px; border: 1px solid #ddd;">Roadside Assistance</td>
        <td rowspan="2" style="padding: 6px;font-size: 14px; border: 1px solid #ddd;">Rs. 1000</td>
        <td rowspan="2" style="padding: 6px;font-size: 14px; border: 1px solid #ddd;">Rs. 100</td>
        <td style="padding: 8px; border: 1px solid #ddd; font-size: 14px">Roadside Assistance, Towing </br>
        ISB / RWP only 24/7</td>
      </tr>
    
    </tbody>
  </table>
</div>
</br>
<p><strong><u>RSA Car &amp; BIKE One Time Service</u></strong></p>
<div style="max-width: 600px; overflow: auto; border: 1px solid #ddd; padding: 10px; border-radius: 8px;">
  <table style="width: 100%; border-collapse: collapse; text-align: left; font-family: Arial, sans-serif;">
    <tbody>
      <tr>
        <td colspan="3" style="padding: 12px; font-weight: bold; font-size: 16px; text-align: center; background-color: #f8f8f8; border-bottom: 2px solid #ddd;">
          RSA BIKE - ROADSIDE ASSISTANCE – ONE TIME SERVICE
        </td>
      </tr>
      <tr style="background-color: #f1f1f1;">
        <td style="padding: 10px; font-weight: bold; border: 1px solid #ddd;">Description</td>
        <td style="padding: 10px; font-weight: bold; border: 1px solid #ddd;">Premium</td>
        <td style="padding: 10px; font-weight: bold; border: 1px solid #ddd;">Coverage</td>
      </tr>
      <tr>
        <td style="padding: 10px; border: 1px solid #ddd;">RSA Bike – One Time Service</td>
        <td style="padding: 10px; border: 1px solid #ddd;">Rs. 300</td>
        <td style="padding: 10px; border: 1px solid #ddd;">Roadside assistance – one time<br> ISL/RWP only 24/7 Service</td>
      </tr>
      <tr>
        <td style="padding: 10px; border: 1px solid #ddd;">RSA Car – One Time Service</td>
        <td style="padding: 10px; border: 1px solid #ddd;">Rs. 1000</td>
        <td style="padding: 10px; border: 1px solid #ddd;">Roadside assistance – one time<br> ISL/RWP only 24/7 Service</td>
      </tr>
    </tbody>
  </table>
</div>

<p><strong>Terms and Conditions:</strong></p>
<p><strong>شرائط و ضوابط:</strong></p>
<ol>
<li>Roadside Assistance (RSA) services are provided on demand. Customers can request assistance for their broken-down bike either through the application or helpline 051-8499772.</li>
</ol>
<p>رودسائیڈ اسسٹنس سروسز ڈیمانڈ پر فراہم کی جاتی ہیں۔ کسٹمرز اپنی خراب بائیک کے لیے ایپلیکیشن یا ہیلپ لائن 2 7 7 9 9 48- 1 5 0 کے ذریعے مدد کی درخواست کر سکتے ہیں۔</p>
<ol start="2">
<li>Upon receiving a request for RSA services, our technicians will make every effort to reach the customer's location as promptly as possible. The primary objective is to attempt to repair the bike on-site. If the bike cannot be fixed at the location, it will be towed to the nearest garage for further inspection and repair.</li>
</ol>
<p>سروسز کی درخواست موصول ہونے پر، ہمارے ٹیکنیشنز کسٹمر کے مقام تک جلد از جلد پہنچنے کی ہر ممکن کوشش کریں گے۔ بنیادی مقصد بائیک کو موقع پر ہی ٹھیک کرنے کی کوشش کرنا ہے۔ اگر بائیک کو موقع پر ٹھیک نہیں کیا جا سکتا، تو اسے قریبی گیراج میں ٹو کر کے بھیجا جائے گا۔</p>
<ol start="3">
<li>There is no additional charge for the towing service if the bike needs to be transported to the garage.</li>
</ol>
<p>اگر بائیک کو گیراج تک لے جانے کی ضرورت ہو، تو ٹو سروس کے لیے کوئی اضافی چارج نہیں لیا جائے گا۔</p>
<ol start="4">
<li>In cases where the repair requires the replacement of parts, refueling, or any other necessary accessory, the cost of such items will be borne by the customer. We will only procure and provide these items at the explicit request of the customer to facilitate the repair process.</li>
</ol>
<p>اگر مرمت کے لیے پارٹس کی تبدیلی، فیول بھرنے، یا کسی دیگر ضروری ایکسسری کی ضرورت ہو، تو ایسے آئٹمز کی لاگت کسٹمر کے ذمہ ہوگی۔ ہم صرف کسٹمر کی واضح درخواست پر ہی ایسے آئٹمز فراہم کریں گے۔</p>
<ol start="5">
<li>Prior to issuing any parts, fuel, or accessories, the supervisor overseeing the service will inform the customer of the associated costs over the phone. The customer's recorded consent will be obtained before proceeding with the procurement and provision of these items.</li>
</ol>
<p>کسی بھی پارٹس، فیول، یا ایکسسری جاری کرنے سے پہلے، سپروائزر کسٹمر کو فون پر لاگت سے آگاہ کرے گا۔ ان آئٹمز کی فراہمی سے پہلے کسٹمر کی ریکارڈڈ رضامندی حاصل کی جائے گی۔</p>
<ol start="6">
<li>Our RSA services are available within specified zones as mentioned in the application for selected areas of Islamabad and Rawalpindi. If the customer is located outside the designated service zone, we may, at our discretion, provide assistance within a maximum radius of 5 kilometers from the zone boundary.</li>
</ol>
<p>ہماری سروسز اسلام آباد اور راولپنڈی کے منتخب علاقوں کے لیے ایپلیکیشن میں درج زونز تک محدود ہیں۔ اگر کسٹمر سروس زون سے باہر ہو، تو ہم اپنے اختیار پر صرف زون سے زیادہ سے زیادہ 5 کلومیٹر کے اندر ہی مدد فراہم کر سکتے ہیں۔</p>
<ol start="7">
<li>The availability of RSA services is subject to the operational capacity and resources of the service provider. While every effort will be made to assist customers in need, circumstances such as extreme weather conditions, natural disasters, or unforeseen emergencies may affect service availability.</li>
</ol>
<p>سروسز کی دستیابی سروس فراہم کنندہ کے آپریشنل وسائل پر منحصر ہے۔ اگرچہ ہر ممکن کوشش کی جائے گی کہ کسٹمرز کی مدد کی جائے، لیکن شدید موسمی حالات، قدرتی آفات، یا غیر متوقع ہنگامی حالات سروس کی دستیابی کو متاثر کر سکتے ہیں۔</p>
<ol start="8">
<li>3 days waiting period will be applied after activation of any of the RSA product.</li>
</ol>
<p>کسی بھی RSA پروڈکٹ کی ایکٹیویشن کے بعد 3 دن کی ویٹنگ پیریڈ لاگو ہوگی۔</p>
<ol start="9">
<li>The terms and conditions outlined herein are subject to change without prior notice. Any updates or revisions to the terms and conditions will be communicated to customers through the appropriate channels.</li>
</ol>
<p>یہاں درج شرائط و ضوابط میں بغیر پیشگی اطلاع کے تبدیلی کی جا سکتی ہے۔ کسی بھی اپ ڈیٹ یا ترمیم کی اطلاع کسٹمرز کو مناسب چینلز کے ذریعے دی جائے گی۔</p>
<ol start="10">
<li>By availing of our RSA services, customers agree to abide by these terms and conditions and acknowledge that failure to comply may affect the provision of assistance.<br /> ہماری RSA سروسز استعمال کرنے سے، کسٹمرز ان شرائط و ضوابط پر عمل کرنے پر رضامند ہوتے ہیں اور تسلیم کرتے ہیں کہ ان کی خلاف ورزی سے مدد کی فراہمی متاثر ہو سکتی ہے۔</li>
<li>For any queries, complaints, or feedback regarding our RSA services, customers can contact our helpline or customer support channels for assistance.<br /> ہماری RSA سروسز سے متعلق کسی بھی سوال، شکایت، یا فیڈ بیک کے لیے، کسٹمرز ہماری ہیلپ لائن یا کسٹمر سپورٹ چینلز سے رابطہ کر سکتے ہیں۔</li>
<li>These terms and conditions constitute the entire agreement between the customer and the service provider regarding RSA services and supersede any prior agreements or arrangements, whether oral or written.</li>
</ol>
<p>یہ شرائط و ضوابط کسٹمر اور سروس فراہم کنندہ کے درمیان RSA سروسز سے متعلق مکمل معاہدہ تشکیل دیتے ہیں اور کسی بھی پہلے سے موجود زبانی یا تحریری معاہدے یا انتظامات کو منسوخ کرتے ہیں۔</p>
`;
  }

  getAllItems() {
    return this.items;
  }

  getTerms() {
    return this.terms;
  }
}

export default rsa_packages;
