class car_packages {
  constructor() {
    this.items = [
      // {
      //   id: 34,
      //   image: "assets/MiniAppAssets/car.png",
      //   name: "Road Side Assistance",
      //   badge: "Silver Card Monthly",
      //   coverage: "",
      //   price: "1500",
      //   bgColorClass: "bg-info",
      //   description: `This includes towing of the vehicle, and 24/7 roadside assistance in Islamabad/Rawalpindi.
      //   `,
      //   type: "rsa",
      // },
      // {
      //   id: 35,
      //   image: "assets/MiniAppAssets/car.png",
      //   name: "Road Side Assistance",
      //   badge: "Gold Card Monthly",
      //   coverage: "",
      //   price: "2500",
      //   bgColorClass: "bg-warning",
      //   description: `This includes towing of the vehicle, and 24/7 roadside assistance in Islamabad/Rawalpindi.
      //   `,
      //   type: "rsa",
      // },
      // {
      //     id: 36,
      //     image: "assets/MiniAppAssets/car.png",
      //     name: "Road Side Assistance",
      //     badge: "Platinum Card Monthly",
      //     coverage: "200,000",
      //     price: "3500",
      //     bgColorClass: "bg-info",
      //     description: `In Islamabad and Rawalpindi, we offer 24/7 towing services and provide up to Rs. 100,000 for motor accident maintenance and accidental ICU & IPD hospitalization.
      //     `,
      //     type: "rsa",
      //   },
      //   {
      //     id: 31,
      //     image: "assets/MiniAppAssets/car.png",
      //     name: "Road Side Assistance",
      //     badge: "Silver Card Annual",
      //     coverage: "",
      //     price: "9000",
      //     bgColorClass: "bg-warning",
      //     description: `This includes towing of the vehicle, and 24/7 roadside assistance in Islamabad/Rawalpindi.
      //     `,
      //     type: "rsa",
      //   },
      //   {
      //     id: 32,
      //     image: "assets/MiniAppAssets/car.png",
      //     name: "Road Side Assistance",
      //     badge: "Gold Card Annual",
      //     coverage: "",
      //     price: "18000",
      //     bgColorClass: "bg-info",
      //     description: `This includes towing of the vehicle, and 24/7 roadside assistance in Islamabad/Rawalpindi.
      //     `,
      //     type: "rsa",
      //   },
      //   {
      //     id: 33,
      //     image: "assets/MiniAppAssets/car.png",
      //     name: "Road Side Assistance",
      //     badge: "Platinum Card Annual",
      //     coverage: "350,000",
      //     price: "30000",
      //     bgColorClass: "bg-warning",
      //     description: `In Islamabad and Rawalpindi, we offer 24/7 towing services and provide up to Rs. 100,000 for motor accident maintenance and Rs. 200,000 for accidental ICU & IPD hospitalization.
      //     `,
      //     type: "rsa",
      //   },
      //       {
      //         id: 30,
      //         image: "assets/MiniAppAssets/car.png",
      //         name: "Car Insurance",
      //         badge: "Monthly",
      //         coverage: "200,000",
      //         price: "1500",
      //         bgColorClass: "bg-info",
      //         description: `<p><strong>Rapid Insurance Setup:</strong> Secure your car with quick and simple online registration.</p>
      // <ul>
      // <li><strong>Total Insurance Cover: </strong>PKR 200,000</li>
      // <li><strong>Accidental Car Repair: </strong>Up to PKR 100,000</li>
      // <li><strong>Accidental Hospitalization: </strong>Up to PKR 100,000</li>
      // <li><strong>RSA Coverage:</strong> Available for Rawalpindi/Islamabad</li>
      // </ul>
      // <p><strong>Simple Claims, Superior Support:</strong> Our dedicated team makes claims processing quick and straightforward.</p>
      // <p><strong>Protect your vehicle and health with our all-inclusive insurance plan!</strong></p>`,
      //       },
      {
        id: 178,
        image: "assets/MiniAppAssets/car.png",
        name: "Car Insurance",
        badge: "Annual",
        coverage: "350000",
        price: "15000",
        bgColorClass: "bg-warning",
        description: `<p><strong>Rapid Insurance Setup:</strong> Secure your car with quick and simple online registration.</p>
<ul>
<li><strong>Total Insurance Cover: </strong>PKR 350,000</li>
<li><strong>Accidental Car Repair: </strong>Up to PKR 150,000</li>
<li><strong>Accidental Hospitalization: </strong>Up to PKR 200,000</li>
<li><strong>RSA Coverage: </strong>Available for Rawalpindi/Islamabad</li>
</ul>
<p><strong>Simple Claims, Superior Support:</strong> Our dedicated team makes claims processing quick and straightforward.</p>
<p><strong>Protect your vehicle and health with our all-inclusive insurance plan!</strong></p>`,
        type: "insurance",
      },
    ];
    //     this.terms = ` <div className="container mt-4">

    //       <h3 className="text-center text-primary font-weight-bold">
    //         <u>Car Insurance - Basic Fact Sheet</u>
    //       </h3>

    //       <div className="table-responsive mt-3">
    //         <table className="table table-bordered text-center">
    //           <thead className="thead-dark">
    //             <tr>
    //               <th>Plan</th>
    //               <th>Rate</th>
    //             </tr>
    //           </thead>
    //           <tbody>
    //             <tr>
    //               <td>Comprehensive Insurance</td>
    //               <td>1.3%</td>
    //             </tr>
    //             <tr>
    //               <td>Comprehensive with Gap Insurance</td>
    //               <td>1.5%</td>
    //             </tr>
    //             <tr>
    //               <td>Third Party / Fire & Theft Rate</td>
    //               <td>1%</td>
    //             </tr>
    //             <tr>
    //               <td>Third Party Only</td>
    //               <td>PKR 5000 Fixed</td>
    //             </tr>
    //           </tbody>
    //         </table>
    //       </div>

    //       <div className="card shadow-sm mt-4">
    //         <div className="card-header bg-info text-white">
    //           <h5 className="mb-0">Coverage & Benefits / <span className="urdu">کوریج اور فوائد</span></h5>
    //         </div>
    //         <div className="card-body">
    //           <h6 className="font-weight-bold">✅ Comprehensive Insurance / <span className="urdu">جامع انشورنس</span></h6>
    //           <ul>
    //             <li>Covers accidental damage to the insured vehicle / <span className="urdu">بیمہ شدہ گاڑی کو حادثاتی نقصان کا احاطہ کرتا ہے</span></li>
    //             <li>Covers third-party property damage and bodily injury / <span className="urdu">تیسرے فریق کی جائیداد کے نقصان اور جسمانی چوٹ کی ذمہ داری کا احاطہ کرتا ہے</span></li>
    //             <li>Covers theft and fire-related losses / <span className="urdu">چوری اور آگ سے متعلق نقصانات کا احاطہ کرتا ہے</span></li>
    //             <li>Optional **Gap Coverage** for full claim settlement in case of total loss / <span className="urdu">مجموعی نقصان کی صورت میں مکمل دعوے کے تصفیے کے لئے اختیاری گیپ کوریج</span></li>
    //           </ul>
    //         </div>
    //       </div>
    //       <div className="card shadow-sm mt-4">
    //         <div className="card-header bg-warning text-white">
    //           <h5 className="mb-0">Policyholder Obligations / <span className="urdu">پالیسی ہولڈر کی ذمہ داریاں</span></h5>
    //         </div>
    //         <div className="card-body">
    //           <ul>
    //             <li>Pay the insurance premium on time / <span className="urdu">کوریج کو برقرار رکھنے کے لئے انشورنس پریمیم کو وقت پر ادا کریں</span></li>
    //             <li>Provide accurate vehicle information / <span className="urdu">بیمہ شدہ گاڑی کے بارے میں درست اور مکمل معلومات فراہم کریں</span></li>
    //             <li>Report accidents, theft, or damage **within 48 hours** / <span className="urdu">48 گھنٹوں کے اندر حادثات، چوری، یا نقصان کی اطلاع دیں</span></li>
    //             <li>Maintain the vehicle in good condition / <span className="urdu">گاڑی کو اچھی حالت میں برقرار رکھیں</span></li>
    //           </ul>
    //         </div>
    //       </div>
    //       <div className="card shadow-sm mt-4">
    //         <div className="card-header bg-danger text-white">
    //           <h5 className="mb-0">Exclusions (What is NOT Covered) / <span className="urdu">اس پالیسی میں شامل نہیں</span></h5>
    //         </div>
    //         <div className="card-body">
    //           <ul>
    //             <li> War, riots, or terrorist acts / <span className="urdu">جنگ، فسادات، یا دہشت گردانہ کارروائیوں کی وجہ سے نقصانات</span></li>
    //             <li> Reckless driving, drunk driving, or racing / <span className="urdu">لاپرواہی، نشے میں ڈرائیونگ، یا ریسنگ</span></li>
    //             <li> Mechanical breakdowns or electrical failures / <span className="urdu">مکینیکل بریک ڈاؤن، یا برقی ناکامی</span></li>
    //             <li> Using vehicle for commercial purposes / <span className="urdu">گاڑی کا تجارتی مقاصد کے لئے استعمال</span></li>
    //           </ul>
    //         </div>
    //       </div>
    //       <div className="alert alert-info text-center mt-4">
    //         <h5>📞 Customer Support / <span className="urdu">کسٹمر سپورٹ</span></h5>
    //         <p>Call: <strong>3737 / 042-111-003-737 / 051-849-3737</strong></p>
    //         <p>Email: <a href="mailto:whssupport@webdocoffice.com.pk">whssupport@webdocoffice.com.pk</a></p>
    //       </div>
    //     </div>
    // `;
    this.terms = `
<div className="container mt-4">
  <h3 className="text-center text-primary font-weight-bold">
    <u>Car Insurance - Basic Fact Sheet</u>
  </h3>

  <div className="table-responsive mt-3">
    <table className="table table-bordered text-center"  style="max-width: 425px; max-height: 584px; overflow: auto; border: 1px solid; padding: 10px;>
      <thead className="thead-dark">
        <tr>
          <th>Plan</th>
          <th>Rate</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Comprehensive Insurance</td>
          <td>1.3%</td>
        </tr>
        <tr>
          <td>Comprehensive with Gap Insurance</td>
          <td>1.5%</td>
        </tr>
        <tr>
          <td>Third Party / Fire & Theft Rate</td>
          <td>1%</td>
        </tr>
        <tr>
          <td>Third Party Only</td>
          <td>PKR 5000 Fixed</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div className="card shadow-sm mt-4">
    <div className="card-header bg-info text-white">
      <h5 className="mb-0">Coverage & Benefits / <span className="urdu">کوریج اور فوائد</span></h5>
    </div>
    <div className="card-body">
      <h6 className="font-weight-bold">✅ Comprehensive Insurance / <span className="urdu">جامع انشورنس</span></h6>
      <ul>
        <li>Covers accidental damage to the insured vehicle / <span className="urdu">بیمہ شدہ گاڑی کو حادثاتی نقصان کا احاطہ کرتا ہے</span></li>
        <li>Covers third-party property damage and bodily injury / <span className="urdu">تیسرے فریق کی جائیداد کے نقصان اور جسمانی چوٹ کی ذمہ داری کا احاطہ کرتا ہے</span></li>
        <li>Covers theft and fire-related losses / <span className="urdu">چوری اور آگ سے متعلق نقصانات کا احاطہ کرتا ہے</span></li>
        <li>Optional **Gap Coverage** for full claim settlement in case of total loss / <span className="urdu">مجموعی نقصان کی صورت میں مکمل دعوے کے تصفیے کے لئے اختیاری گیپ کوریج</span></li>
      </ul>
    </div>
  </div>
  
  <div className="card shadow-sm mt-4">
    <div className="card-header bg-warning text-white">
      <h5 className="mb-0">Policyholder Obligations / <span className="urdu">پالیسی ہولڈر کی ذمہ داریاں</span></h5>
    </div>
    <div className="card-body">
      <ul>
        <li>Pay the insurance premium on time / <span className="urdu">کوریج کو برقرار رکھنے کے لئے انشورنس پریمیم کو وقت پر ادا کریں</span></li>
        <li>Provide accurate vehicle information / <span className="urdu">بیمہ شدہ گاڑی کے بارے میں درست اور مکمل معلومات فراہم کریں</span></li>
        <li>Report accidents, theft, or damage **within 48 hours** / <span className="urdu">48 گھنٹوں کے اندر حادثات، چوری، یا نقصان کی اطلاع دیں</span></li>
        <li>Maintain the vehicle in good condition / <span className="urdu">گاڑی کو اچھی حالت میں برقرار رکھیں</span></li>
      </ul>
    </div>
  </div>
  
  <div className="card shadow-sm mt-4">
    <div className="card-header bg-danger text-white">
      <h5 className="mb-0">Exclusions (What is NOT Covered) / <span className="urdu">اس پالیسی میں شامل نہیں</span></h5>
    </div>
    <div className="card-body">
      <ul>
        <li>War, riots, or terrorist acts / <span className="urdu">جنگ، فسادات، یا دہشت گردانہ کارروائیوں کی وجہ سے نقصانات</span></li>
        <li>Reckless driving, drunk driving, or racing / <span className="urdu">لاپرواہی، نشے میں ڈرائیونگ، یا ریسنگ</span></li>
        <li>Mechanical breakdowns or electrical failures / <span className="urdu">مکینیکل بریک ڈاؤن، یا برقی ناکامی</span></li>
        <li>Using vehicle for commercial purposes / <span className="urdu">گاڑی کا تجارتی مقاصد کے لئے استعمال</span></li>
      </ul>
    </div>
  </div>
  
  <div className="alert alert-info text-center mt-4">
    <h5>📞 Customer Support / <span className="urdu">کسٹمر سپورٹ</span></h5>
    <p>Call: <strong> 042-111-003-737 / 051-849-3737</strong></p>
    <p>Email: <a href="mailto:whssupport@webdocoffice.com.pk">whssupport@webdocoffice.com.pk</a></p>
  </div>
</div>

`;
  }

  getAllItems() {
    return this.items;
  }

  getTerms() {
    return this.terms;
  }
}

export default car_packages;
