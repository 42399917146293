class doc_assistant_start {
  constructor() {
    this.items = {
      img: "/doctorassistant/natasha.jpg",
      id: 50001,
      slideimg: "/doctorassistant/natasha.jpg",
      name: "Natasha",
      type: "text",
      options: [
        {
          id: 90001,
          name: "Flu",
          type: "diseases",
          desc: "Flu",
          text: "I have Flue",
        },
        {
          id: 90002,
          name: "Fever",
          type: "diseases",
          desc: "Fever",
          text: "I have Fever",
        },
        {
          id: 90003,
          name: "Gastric",
          type: "diseases",
          desc: "Gastric",
          text: "I have Gastric",
        },
        {
          id: 90004,
          name: "Skin",
          type: "diseases",
          desc: "Skin",
          text: "I have Skin",
        },
        {
          id: 90005,
          name: "UTI",
          type: "diseases",
          desc: "UTI",
          text: "I have UTI",
        },
        {
          id: 90006,
          name: "ENT",
          type: "diseases",
          desc: "ENT",
          text: "I have ENT",
        },
        { id: 90007, name: "other", type: "diseases", desc: "other", text: "other" },
      ],
      backtostart: false,
      text: `Hi! I’m Diya, your doctor’s virtual assistant. How can I assist you today? Please select your reason for consulting a doctor.`,
    };
  }

  getAllItems() {
    return this.items;
  }
}

export default doc_assistant_start;
