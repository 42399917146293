class agri_experts {
  constructor() {
    this.items = [
      {
        id: 2,
        name: "Agri Experts",
        badge: "Monthly",
        coverage: "-",
        price: "100",
        bgColorClass: "bg-info",
        description: `<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Calibri",sans-serif;text-align:justify;'>At WEBDOC, we offer agri-expert consultations in agronomy, plant breeding and genetics, entomology, plant pathology, soil science, agriculture extensions and food technology. Our advanced services ensure your plants, crops, and soil thrive. Enhance your agricultural quality with our specialized care and advanced services.</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Calibri",sans-serif;text-align:justify;'><strong>Product Features:</strong></p>
<ul style="list-style-type: disc;">
    <li><strong>Expert Guidance:</strong> Access to experienced agricultural specialists.</li>
    <li><strong>Customized Solutions:</strong> Tailored advice for crop, livestock, and farm management.</li>
    <li><strong>Cost-Effective:</strong> Affordable consultations to maximize farm productivity.</li>
    <li><strong>Real-Time Problem Solving:</strong> Immediate assistance for on-the-spot issues.</li>
    <li><strong>Resource Recommendations:</strong> Guidance on best practices, tools, and resources.</li>
</ul>`,
        type: "agri",
      },
      {
        id: 3,
        name: "Agri Experts",
        badge: "Annual",
        coverage: "-",
        price: "700",
        bgColorClass: "bg-warning",
        description: `<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Calibri",sans-serif;text-align:justify;'>At WEBDOC, we offer agri-expert consultations in agronomy, plant breeding and genetics, entomology, plant pathology, soil science, agriculture extensions and food technology. Our advanced services ensure your plants, crops, and soil thrive. Enhance your agricultural quality with our specialized care and advanced services.</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Calibri",sans-serif;text-align:justify;'><strong>Product Features:</strong></p>
<ul style="list-style-type: disc;">
    <li><strong>Expert Guidance:</strong> Access to experienced agricultural specialists.</li>
    <li><strong>Customized Solutions:</strong> Tailored advice for crop, livestock, and farm management.</li>
    <li><strong>Cost-Effective:</strong> Affordable consultations to maximize farm productivity.</li>
    <li><strong>Real-Time Problem Solving:</strong> Immediate assistance for on-the-spot issues.</li>
    <li><strong>Resource Recommendations:</strong> Guidance on best practices, tools, and resources.</li>
</ul>`,
        type: "agri",
      },
    ];
    // this.terms = `<p>Digital doctors are available either through application or by dialing the helpline number. Fair usage policy strictly applies.:&nbsp;</p>`
    // ;
    this.terms = `
    <p><strong>Basi Fact Sheet</strong></p>

<div style="max-width: 425px; max-height: 584px; overflow: auto; border: 1px solid; padding: 10px;">
  <table style="width: 100%; border-collapse: collapse; text-align: left;">
    <tbody>
      <tr>
        <td colspan="3" style="padding: 10px; font-weight: bold;">
          AGRI EXPERT – Tele Agri expert service.
        </td>
      </tr>
      <tr>
        <td style="width: 100px; padding: 8px; border: 1px solid #ddd;">MONTHLY</td>
        <td style="width: 50px; padding: 8px; border: 1px solid #ddd;">RS. 100</td>
        <td style="width: 150px; padding: 8px; border: 1px solid #ddd;">UNLIMITED CALLS TO AGRI EXPERTS</td>
      </tr>
      <tr>
        <td style="width: 100px; padding: 8px; border: 1px solid #ddd;">YEARLY</td>
        <td style="width: 50px; padding: 8px; border: 1px solid #ddd;">RS. 700</td>
        <td style="width: 150px; padding: 8px; border: 1px solid #ddd;">UNLIMITED CALLS TO AGRI EXPERTS</td>
      </tr>
    </tbody>
  </table>
</div>
<p>&nbsp;</p>
<p><strong>Product Details</strong> / <strong></strong><strong>پروڈکٹ</strong> <strong>کی</strong> <strong>تفصیلات</strong></p>
<p>Agri Expert, a platform to access Agriculture Experts from 9 am to 5 pm.</p>
<p>&nbsp;جہاں آپ صبح 9 بجے سے شام 5 بجے تک زرعی ماہرین تک رسائی حاصل کر سکتے ہیں۔</p>
<p>All our Agriculture experts have plenty of experience and are registered with the Higher Education Commission (HEC) and are trained to serve over the phone for all agriculture issues.</p>
<p>ہمارے تمام زرعی ماہرین کے پاس کافی تجربہ ہے اور وہ ہائر ایجوکیشن کمیشن (HEC) کے ساتھ رجسٹرڈ ہیں۔ انہیں تمام زرعی مسائل پر فون کے ذریعے خدمات فراہم کرنے کی تربیت دی گئی ہے۔</p>
<p>Customers can use the Easypaisa app for video calls or dial&nbsp;051-849-3737&nbsp;to avail the facility.</p>
<p>کسٹمرز ویڈیو کال کے لیے Easypaisa ایپ استعمال کر سکتے ہیں یا&nbsp;051-849-3737&nbsp;پر کال کر کے اس سہولت سے فائدہ اٹھا سکتے ہیں۔</p>
<p>We provide information, knowledge, expert advice, suggestions, and counseling on plant pathogens (e.g., bacteria, fungi, viruses, and nematodes) and insect pests of different crops and their management.</p>
<p>ہم پودوں کے pathogens (جیسے بیکٹیریا، فنگس، وائرس، اور نیماٹوڈز) اور مختلف فصلوں کے کیڑوں اور ان کے انتظام کے بارے میں معلومات، علم، ماہرانہ مشورے، تجاویز، اور کاؤنسلنگ فراہم کرتے ہیں۔</p>
<p>We also provide information about different varieties of field crops, vegetables, fruits, and information about all types of soils that are suitable for the growth and development of plants.</p>
<p>ہم مختلف قسم کی فیلڈ فصلوں، سبزیوں، پھلوں کے اقسام کے بارے میں معلومات بھی فراہم کرتے ہیں، نیز ان تمام قسم کی مٹیوں کے بارے میں معلومات جو پودوں کی نشوونما اور ترقی کے لیے موزوں ہیں۔</p>
<p>Furthermore, we also provide all sorts of information regarding weather and crops and their development.</p>
<p>مزید برآں، ہم موسم اور فصلوں اور ان کی ترقی سے متعلق ہر قسم کی معلومات بھی فراہم کرتے ہیں۔</p>
<p>Please NOTE that the calls access to Agriculture experts are unlimited; however, in events where we believe that the customer(s) are misusing the lines, we may refuse to further facilitate the customers.</p>
<p>براہ کرم نوٹ کریں کہ زرعی ماہرین تک کالز کی رسائی لامحدود ہے؛ تاہم، اگر ہمیں لگے کہ کسٹمر لائن کا غلط استعمال کر رہا ہے، تو ہم مزید سہولت فراہم کرنے سے انکار کر سکتے ہیں۔</p>
<p>Any bad language to our Agri Experts is taken very strictly, and we reserve the right to block such customers despite their subscription.</p>
<p>ہمارے زرعی ماہرین کے ساتھ کسی بھی قسم کی بدتمیزی کو سخت طور پر لیا جائے گا، اور ہمارے پاس ایسے کسٹمرز کو بلاک کرنے کا حق محفوظ ہے، چاہے ان کی سبسکرپشن ہی کیوں نہ ہو۔</p>
<p><strong>DISCLAIMER:</strong></p>
<p><strong>انتباہ</strong><strong>:</strong></p>
<p>Our agriculture advisors are experts in the field and qualified professionals. The advice, suggestions, and counseling are provided based on the information from the client.<br /> ہمارے زرعی مشیر اس شعبے کے ماہر اور قابلیت رکھنے والے پیشہ ور افراد ہیں۔ مشورے، تجاویز، اور کاؤنسلنگ کلائنٹ کی فراہم کردہ معلومات کی بنیاد پر دی جاتی ہیں۔</p>
<p>Wrong information from the client may result in a mismatch of knowledge shared, for which&nbsp;WEBDOC&nbsp;and associate partners do not stand responsible.</p>
<p>کلائنٹ کی طرف سے غلط معلومات فراہم کرنے کی صورت میں، دی گئی معلومات میں عدم مطابقت ہو سکتی ہے، جس کے لیے&nbsp;WEBDOC&nbsp;اور اس کے ساتھی ادارے ذمہ دار نہیں ہوں گے۔</p> 
    `;
  }

  getAllItems() {
    return this.items;
  }
  getTerms() {
    return this.terms;
  }
}

export default agri_experts;
