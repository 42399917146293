class startingchat {
    constructor() {
        const sessionUser = JSON.parse(sessionStorage.getItem("user"));
        this.items =
        {
            id: 30001,
            name: "Start Text",
            type: "text",
            slideimg: "/doctorassistant/dia.jpg",
            options: [
                { id: 40012,text:"Connect to the Agri Expert", name: "Connect to the Agri Expert", price: "", type: "doctor", desc: "I’d like to connect with an Agri expert." },
                { id: 40012,text:"About us", name: "About us", price: "", type: "about", desc: "About us" },
                // { id: 40011,text:"FAQs", name: "FAQ", price: "", type: "faqs", desc: "FAQ" },
                ],
            backtostart: false,
            text: `<p>Hello, ${sessionUser.userName}! 👋 Welcome to <strong>WEB<span style="color: #ff0000;">DOC</span></strong>.
             I’m here to guide you on your farming journey. How can I assist you today?
            `,

        }
    }

    getAllItems() {
        return this.items;
    }
}

export default startingchat;
