import start from "./start";
import about from "./about";
import arrived from "./arrived";
import buyinsurance from "./buyinsurance";
import car from "./car";
import confirmpayment from "./confirmpayment";
import End from "./End";
import feedback from "./feedback";
import haveinsurance from "./haveinsurance";
import insurance from "./insurance";
import issue from "./issue";
import noNeedInsurance from "./noNeedInsurance";
import notfixed from "./notfixed";
import payment from "./payment";
import recover from "./recover";
import safety from "./safety";
import stillworking from "./stillworking";
import waiting from "./waiting";
import location from "./location";
import membership from "./membership";
import updatePlan from "./updatePlan";
import membershipPayment from "./membershipPayment";
import excellent from "./excellent";
import good from "./good";
import bad from "./bad";
import registration from "./registration";
const getDataRSA = async (type, msg) => {
  let newMessage;
  console.log("type .......", type);
  if (type === "start") {
    newMessage = new start();
  } else if (type === "about") {
    newMessage = new about(msg);
  } else if (type === "arrived") {
    newMessage = new arrived(msg);
  } else if (type === "buyinsurance") {
    newMessage = new buyinsurance(msg);
  } else if (type === "car") {
    newMessage = new car(msg);
  } else if (type === "confirmpayment") {
    newMessage = new confirmpayment(msg);
  } else if (type === "End") {
    newMessage = new End(msg);
  } else if (type === "feedback") {
    newMessage = new feedback(msg);
  } else if (type === "excellent") {
    newMessage = new excellent(msg);
  } else if (type === "good") {
    newMessage = new good(msg);
  } else if (type === "bad") {
    newMessage = new bad(msg);
  } else if (type === "haveinsurance") {
    newMessage = new haveinsurance(msg);
  } else if (type === "insurance") {
    newMessage = new insurance(msg);
  } else if (type === "issue") {
    newMessage = new issue(msg);
  } else if (type === "membership") {
    newMessage = new membership(msg);
  } else if (type === "membershipPayment") {
    newMessage = new membershipPayment(msg);
    // let res = await newMessage.getAllItems(msg.price, msg.id);

    // if (res.issuccess) {
    //     let response = new confirmMembership(msg); // Create only when success
    //     return {
    //         modal: res,
    //         memberAssistance: response.getAllItems()
    //     };
    // }

    // return { modal: res }; // Avoids unnecessary execution
  } else if (type === "updatePlan") {
    newMessage = new updatePlan(msg);
  } else if (type === "noNeedInsurance") {
    newMessage = new noNeedInsurance(msg);
  } else if (type === "notfixed") {
    newMessage = new notfixed(msg);
  } else if (type === "payment") {
    newMessage = new payment(msg);
  } else if (type === "recover") {
    newMessage = new recover(msg);
  } else if (type === "safety") {
    newMessage = new safety(msg);
  } else if (type === "stillworking") {
    newMessage = new stillworking(msg);
  } else if (type === "waiting") {
    newMessage = new waiting(msg);
  } 
  else if (type === "location") {
    newMessage = new location(msg);
  }
  else if (type === "registration") {
    newMessage = new registration(msg);
  }
  return {
    modal: await newMessage.getAllItems(),
  };
};

export default getDataRSA;
