class disease_info {
  constructor() {
    this.items = {
      id: 3000311,
      name: "Information",
      type: "text",
      options: [
        { id: 100, name: "Video", type: "calltype", text: "Video" },
        { id: 101, name: "Voice", type: "calltype", text: "Voice" },
      ],
      backtostart: false,
      slideimg: "/doctorassistant/natasha.jpg",
      text: ``,
      issuccess: false,
    };

    this.diseasesinfo = {
      Flu: {
        information: `<p>The flu, or influenza, is&nbsp;a contagious viral infection that affects the respiratory system.&nbsp;It can cause mild to severe illness, and sometimes death.&nbsp;</p>
<p>Symptoms&nbsp;</p>
<ul>
<li>Fever or feeling feverish</li>
<li>Chills</li>
<li>Runny or stuffy nose</li>
<li>Sore throat</li>
<li>Cough</li>
<li>Muscle or body aches</li>
<li>Headache</li>
<li>Fatigue</li>
<li>Vomiting and diarrhea, especially in children</li>
</ul>
<p>Treatment&nbsp;</p>
<ul>
<li>Rest</li>
<li>Drink plenty of fluids, like water, juice, and warm soups</li>
<li>Take over-the-counter pain relievers like acetaminophen (Tylenol) or ibuprofen (Advil, Motrin IB)</li>
<li>Take antiviral medications</li>
<li>Take cough medicine</li>
</ul>
<p>Prevention</p>
<ul>
<li>Get the yearly flu vaccine&nbsp;</li>
<li>Wash your hands often with soap and warm water&nbsp;</li>
<li>Cover your mouth and nose with a tissue when you cough or sneeze&nbsp;</li>
<li>Throw away used tissues as soon as possible&nbsp;</li>
<li>Stay home and avoid contact with others if you have a high temperature or don't feel well&nbsp;</li>
</ul>
<p>When to see a doctor&nbsp;</p>
<ul>
<li>If you are younger than 5 years old</li>
<li>If you are 65 years or older and live in an aged care home</li>
<li>If you have a medical condition like heart or kidney disease, type 2 diabetes, or severe asthma</li>
<li>If you are pregnant or very obese</li>
</ul>
<p>Disclaimer:</p>
<p>This is for informational purposes only. For medical advice or diagnosis, consult a professional. Generative AI is experimental.</p>`,
      },
      Fever: {
        information: `<p><strong>Definition</strong></p>
<p>Fever is the temporary increase in the body's temperature in response to a disease or illness.</p>
<p>A child has a fever when the temperature is at or above one of these levels:</p>
<ul>
<li>100.4&deg;F (38&deg;C) measured in the bottom (rectally)</li>
<li>99.5&deg;F (37.5&deg;C) measured in the mouth (orally)</li>
<li>99&deg;F (37.2&deg;C) measured under the arm (axillary)</li>
</ul>
<p>An adult probably has a fever when the temperature is above 99&deg;F to 99.5&deg;F (37.2&deg;C to 37.5&deg;C), depending on the time of day.</p>
<p><strong>Alternative Names</strong></p>
<p>Elevated temperature; Hyperthermia; Pyrexia; Febrile</p>
<p><strong>Considerations</strong></p>
<p>Normal body temperature may change during any given day. It is usually highest in the evening.</p>
<p>Fever is an important part of the body's defense against infection. Most bacteria and viruses that cause infections in people thrive best at 98.6&deg;F (37&deg;C). Many infants and children develop high fevers with mild viral illnesses. Although a fever signals that a battle might be going on in the body, the fever is fighting for, not against the person.</p>
<p>Brain damage from a fever generally will not occur unless the fever is over 107.6&deg;F (42&deg;C). Untreated fevers caused by infection will seldom go over 105&deg;F (40.6&deg;C) unless the child is overdressed or in a hot place.</p>
<p>Unexplained fevers that continue for days or weeks are called fevers of undetermined origin (FUO).</p>
<p><strong>Causes</strong></p>
<p>Almost any infection can cause a fever, including:</p>
<ul>
<li>Bone infections (osteomyelitis), appendicitis, skin infections or cellulitis, and meningitis</li>
<li>Respiratory infections such as colds or flu-like illnesses, sore throats, ear infections, sinus infections, mononucleosis, bronchitis, pneumonia, and tuberculosis</li>
<li>Urinary tract infections</li>
<li>Viral gastroenteritis and bacterial gastroenteritis</li>
</ul>
<p>Children and adults may have a low-grade fever for 1 or 2 days after some immunizations.</p>
<p>Teething may cause a slight increase in a child's temperature, but not higher than 100&deg;F (37.8&deg;C).</p>
<p>Autoimmune or inflammatory disorders may also cause fevers.</p>
<p><strong>Home Care</strong></p>
<p>A simple cold or other viral infection can sometimes cause a high fever (102&deg;F to 104&deg;F or 38.9&deg;C to 40&deg;C). This does not mean you or your child has a serious problem. Some serious infections don't cause a fever or can cause a very low body temperature, most often in infants.</p>
<p>If the fever is mild and you have no other problems, you do not need treatment. Drink fluids and rest.</p>
<p>The illness is probably not serious if your child:</p>
<ul>
<li>Is still interested in playing</li>
<li>Is eating and drinking well</li>
<li>Is alert and smiling at you</li>
<li>Has a normal skin color</li>
<li>Looks well when their temperature comes down</li>
</ul>
<p>Take steps to lower a fever if you or your child is uncomfortable, vomiting, dried out (dehydrated), or not sleeping well. Remember, the goal is to lower, not eliminate, the fever.</p>
<p>When trying to lower a fever:</p>
<ul>
<li>Do not bundle up someone who has chills.</li>
<li>Remove excess clothing or blankets. The room should be comfortable, not too hot or cool. Try one layer of lightweight clothing, and one lightweight blanket for sleep. If the room is hot or stuffy, a fan may help.</li>
<li>A lukewarm bath or sponge bath may help cool someone with a fever. This is effective after medicine is given -- otherwise the temperature might bounce right back up.</li>
<li>Do not use cold baths, ice, or alcohol rubs. These cool the skin, but often make the situation worse by causing shivering, which raises the core body temperature.</li>
</ul>
<p><strong>When to Contact a Medical Professional</strong></p>
<p>Contact a provider right away if your child:</p>
<ul>
<li>Is 3 months or younger and has a rectal temperature of 100.4&deg;F (38&deg;C) or higher</li>
<li>Is 3 to 12 months old and has a fever of 102.2&deg;F (39&deg;C) or higher</li>
<li>Is 2 years or younger and has a fever that lasts longer than 24 to 48 hours</li>
<li>Is older and has a fever for longer than 48 to 72 hours</li>
<li>Has a fever of 105&deg;F (40.5&deg;C) or higher, unless it comes down readily with treatment and the person is comfortable</li>
<li>Has other symptoms that suggest an illness may need to be treated, such as a sore throat, earache, or cough</li>
<li>Has had fevers come and go for up to a week or more, even if these fevers are not very high</li>
<li>Has a serious medical illness, such as a heart problem, sickle cell anemia, diabetes, or cystic fibrosis</li>
<li>Recently had an immunization</li>
<li>Has a new rash or bruises</li>
<li>Has pain with urination</li>
<li>Has a weakened immune system (because of long-term [chronic] steroid therapy, a bone marrow or organ transplant, spleen removal, HIV/AIDS, or cancer treatment)</li>
<li>Has recently traveled to another country</li>
</ul>
<p>Contact your provider right away if you are an adult and you:</p>
<ul>
<li>Have a fever of 105&deg;F (40.5&deg;C) or higher, unless it comes down readily with treatment and you are comfortable</li>
<li>Have a fever that stays at or keeps rising above 103&deg;F (39.4&deg;C)</li>
<li>Have a fever for longer than 48 to 72 hours</li>
<li>Have had fevers come and go for up to a week or more, even if they are not very high</li>
<li>Have a serious medical illness, such as a heart problem, sickle cell anemia, diabetes, cystic fibrosis, COPD, or other long-term (chronic) lung problems</li>
<li>Have a new rash or bruises</li>
<li>Have pain with urination</li>
<li>Have a weakened immune system (from chronic steroid therapy, a bone marrow or organ transplant, spleen removal, HIV/AIDS, or cancer treatment)</li>
<li>Have recently traveled to another country</li>
</ul>
<p>Disclaimer:</p>
<p>This is for informational purposes only. For medical advice or diagnosis, consult a professional. Generative AI is experimental.</p>`,
      },
      Gastric: {
        information: `<p>Gastric issues are&nbsp;conditions that affect the stomach or gastrointestinal (GI) tract.&nbsp;They can be caused by a number of factors, including infections, inflammation, medications, and lifestyle choices.&nbsp;</p>
<p>Symptoms&nbsp;</p>
<ul>
<li>Abdominal pain or discomfort</li>
<li>Bloating</li>
<li>Constipation</li>
<li>Diarrhea</li>
<li>Heartburn and acid reflux</li>
<li>Nausea and vomiting</li>
<li>Unintentional weight loss</li>
<li>Blood in the stool</li>
<li>Difficulty swallowing</li>
</ul>
<p>Causes&nbsp;</p>
<ul>
<li>Eating too much or too fast</li>
<li>Food intolerance</li>
<li>Menstrual cycle</li>
<li>Stress</li>
<li>Pregnancy</li>
<li>Certain medications, such as antidepressants, iron pills, and strong pain medicines</li>
<li>Infections, such as H. pylori</li>
<li>Inflammation, such as gastritis</li>
</ul>
<p>Treatment&nbsp;</p>
<ul>
<li>Most gastric issues can be treated with proper care.</li>
<li>Treatment may include medicine and lifestyle changes.</li>
</ul>
<p>Complications</p>
<ul>
<li>Some gastric issues can lead to serious complications, such as inflammatory arthritis.&nbsp;</li>
<li>Stomach cancer is a dangerous gastric issue that can be cured if detected early.&nbsp;</li>
</ul>
<p>Disclaimer:</p>
<p>This is for informational purposes only. For medical advice or diagnosis, consult a professional. Generative AI is experimental.</p>`,
      },
      Skin: {
        information: `<p>Skin Issues can include a variety of conditions that can cause discomfort, affect your appearance, or cause other health problems.&nbsp;Some common skin issues include:&nbsp;</p>
<ul>
<li><strong>Acne</strong>:&nbsp;</li>
</ul>
<p>Caused by clogged pores that lead to pimples&nbsp;</p>
<ul>
<li><strong>Atopic dermatitis (eczema)</strong>:</li>
</ul>
<p>&nbsp;A skin condition that causes dry, itchy skin that can lead to swelling, cracking, or scaliness&nbsp;</p>
<ul>
<li><strong>Psoriasis</strong>:&nbsp;</li>
</ul>
<p>A condition that causes itchy, scaly red patches&nbsp;</p>
<ul>
<li><strong>Hives</strong>:&nbsp;</li>
</ul>
<p>Red and sometimes itchy bumps on your skin that can appear and then disappear&nbsp;</p>
<ul>
<li><strong>Skin cancer</strong>:&nbsp;</li>
</ul>
<p>An uncontrolled growth of abnormal skin cells&nbsp;</p>
<ul>
<li><strong>Vitiligo</strong>:&nbsp;</li>
</ul>
<p>Patches of skin that lose pigment&nbsp;</p>
<ul>
<li><strong>Alopecia areata</strong>:&nbsp;</li>
</ul>
<p>A condition that causes hair loss in small patches&nbsp;</p>
<ul>
<li><strong>Rosacea</strong>:&nbsp;</li>
</ul>
<p>A condition that causes flushed, thick skin and pimples, usually on the face&nbsp;</p>
<ul>
<li><strong>Raynaud's phenomenon</strong>:&nbsp;</li>
</ul>
<p>A condition that causes periodic reduced blood flow to your fingers, toes, or other body parts&nbsp;</p>
<p>Skin issues can be caused by allergies, irritants, genetic makeup, certain diseases, and immune system problems.&nbsp;</p>
<p>Treatments for skin issues can include antibiotics, medicated ointments and creams, steroids, vitamin injection, and laser therapy.&nbsp;</p>
<p>Disclaimer:</p>
<p>This is for informational purposes only. For medical advice or diagnosis, consult a professional. Generative AI is experimental.</p>`,
      },
      UTI: {
        information: `<p>A urinary tract infection (UTI) is&nbsp;a bacterial infection that affects the urinary system.&nbsp;It can occur in the kidneys, ureters, bladder, or urethra.&nbsp;</p>
<p>Symptoms&nbsp;</p>
<ul>
<li>Pain or burning when urinating</li>
<li>Cloudy or smelly urine</li>
<li>Blood in the urine</li>
<li>Frequent or urgent need to urinate</li>
<li>Pain in the lower abdomen or back</li>
<li>Fever, chills, and fatigue</li>
</ul>
<p>Causes&nbsp;</p>
<ul>
<li>Bacteria from the skin or rectum enter the urethra</li>
<li>E. coli bacteria, which normally live in the colon</li>
</ul>
<p>Treatment&nbsp;Antibiotics, Pain relievers, and Drinking lots of water to help flush out bacteria.&nbsp;</p>
<p>Risk factors&nbsp;</p>
<ul>
<li>Women are more likely to get UTIs than men</li>
<li>Children can also get UTIs, but they are less common</li>
</ul>
<p>Types of UTIs&nbsp;</p>
<ul>
<li><strong>Bladder infection</strong>:&nbsp;Also known as cystitis, this is the most common type of UTI</li>
<li><strong>Kidney infection</strong>:&nbsp;Also known as pyelonephritis, this is less common but more serious</li>
</ul>
<p>Prognosis&nbsp;</p>
<ul>
<li>Symptoms usually improve within 36 hours with treatment</li>
<li>Up to 42% of uncomplicated UTIs may resolve on their own within a few days or weeks</li>
</ul>
<p>Disclaimer:</p>
<p>This is for informational purposes only. For medical advice or diagnosis, consult a professional. Generative AI is experimental.</p>`,
      },
      ENT: {
        information: `<p>Ear, nose, and throat (ENT) issues are common conditions that affect the ears, nose, and throat.&nbsp;Some common ENT problems include:&nbsp;</p>
<ul>
<li><strong>Ear infections</strong>:&nbsp;Can cause hearing loss&nbsp;</li>
<li><strong>Sinus issues</strong>:&nbsp;Can cause facial pain and pressure, nasal congestion, and discharge&nbsp;</li>
<li><strong>Throat problems</strong>:&nbsp;Can include difficulty swallowing, hoarseness, and tonsillitis&nbsp;</li>
<li><strong>Nasal problems</strong>:&nbsp;Can include nosebleeds, nasal polyps, and allergic rhinitis&nbsp;</li>
<li><strong>Sleep apnea</strong>:&nbsp;Can cause snoring, choking sounds, and morning headaches&nbsp;</li>
<li><strong>Hearing loss</strong>:&nbsp;Can be caused by ear infections, anatomical issues, or damage to the cochlea or auditory nerve&nbsp;</li>
</ul>
<p><strong>Other common ENT issues include:&nbsp;</strong></p>
<p>Dizziness, Tinnitus, Swimmer's ear, Hoarseness, Loss of taste, Balance issues, Ruptured eardrum, Voice disorders, and Thyroid nodules.</p>
<p>General practitioners can often treat ENT problems.&nbsp;</p>
<p><strong>Disclaimer: </strong></p>
<p>This is for informational purposes only. For medical advice or diagnosis, consult a professional. Generative AI is experimental.</p>`,
      },
    };
  }

  // Method to make the API call, now accepting customerNumber as a parameter
  async getAllItems(diseases) {
    console.log('info =======>', diseases)
    this.items.text = this.diseasesinfo[diseases].information;
    return this.items;

    // };
  }
}
export default disease_info;
