class insurancepayment {
  constructor(data) {
    const insType = sessionStorage.getItem("insuranceType");
    const user = JSON.parse(sessionStorage.getItem("user"));

    const details = JSON.parse(sessionStorage.getItem("car_information"));
    const is_gapinsurance = details.find(
      (item) => item.key === "is_gapinsurance"
    )?.value;
    const is_installment = details.find(
      (item) => item.key === "is_installment"
    )?.value;
    this.insType = insType;
    const price = data.price;
    this.paymetPrice = data.price;
    this.name = data.name;
    const marketValue = parseFloat(price);
    let gapInsuranceCost;
    let installmentCost;

    console.log("data Type =>>", is_gapinsurance);
    if (insType == "comprehensive") {
      if (is_gapinsurance === 1) {
        gapInsuranceCost = marketValue * 0.015;
      } else {
        gapInsuranceCost = marketValue * 0.013;
      }
      installmentCost = (gapInsuranceCost * 1.22) / 12;
    } else if (insType == "thirdpartytheft") {
      // if (is_gapinsurance === 1) {
      //   gapInsuranceCost = marketValue * 0.012 + 2000;
      // } else {
      //   gapInsuranceCost = marketValue * 0.01;
      // }
      gapInsuranceCost = marketValue * 0.01;
      installmentCost = (gapInsuranceCost * 1.22) / 12;
    } else if (insType == "thirdparty") {
      gapInsuranceCost = 5000;
      installmentCost = gapInsuranceCost;
    }
    if (is_installment === 1) {
      details.push(
        {
          key: "installment_amount",
          value: Math.round(installmentCost),
        },
        { key: "qouteprice", value: Math.round(installmentCost) }
      );
    } else
      details.push({ key: "qouteprice", value: Math.round(gapInsuranceCost) });
    sessionStorage.setItem("car_information", JSON.stringify(details));
    this.gapInsuranceCost = gapInsuranceCost;
    this.installment = installmentCost;
    if (details && user) {
      this.carInformation = {
        userMsisdn: user.userMsisdn,
        applicationUserId: user.applicationUserId,
        ...details.reduce((acc, item) => {
          acc[item.key] = item.value;
          return acc;
        }, {}),
        status: "Pending",
      };
    }
    this.items = {
      id: 30001,
      name: "Personal Info",
      type: "text",
      options: [],
      backtostart: true,
      text: ``,
      slideimg: "/doctorassistant/dia.jpg",
      issuccess: false,
    };
  }

  async getAllItems() {
    console.log("Name =>>", this.name);
    const response = await this.paymentAPICall(
      this.name === "Pay Now in Full"
        ? this.gapInsuranceCost
        : this.installment,
      this.paymetPrice
    );
    return response;
  }

  handlePaymentResult = async (paymentTransactionID) => {
    return new Promise((resolve, reject) => {
      try {
        let information =
          JSON.parse(sessionStorage.getItem("car_information")) || [];
        window.mp.createPayment(paymentTransactionID);
        window.createPaymentResult = async (jsonResult) => {
          const result = JSON.parse(jsonResult);
          const status = result.paymentStatus;
          // alert(`Create Payment Result:\n${JSON.stringify(result, null, 2)}`);
          if (status === "S") {
            information.push({ key: "status", value: "Pending" });
            sessionStorage.setItem(
              "car_information",
              JSON.stringify(information)
            );
            resolve("success");
          } else {
            resolve("fail");
          }
        };
      } catch (error) {
        reject("fail");
      }
    });
  };

  async paymentAPICall(price, actualPrice) {
    console.log(price, "<------>", actualPrice);
    const item = sessionStorage.getItem("user");
    JSON.parse(item);
    const form = new FormData();
    form.append("value", Math.round(price));
    form.append("apikey", "Car");

    const settings = {
      method: "POST",
      body: form,
    };

    try {
      const response = await fetch(
        "https://miniappeasypaisa.webddocsystems.com/public/api/v1/create-payment",
        settings
      );
      const result = await response.json();
      console.log(result);
      // alert(JSON.stringify(result, null, 2));
      if (result.paymentTransactionID) {
        const paymentStatus = await this.handlePaymentResult(
          result.paymentTransactionID
        );
        //alert(paymentStatus);

        if (paymentStatus === "success") {
          const details = JSON.parse(sessionStorage.getItem("car_information"));

          try {
            const response = await fetch(
              "https://Digital.webdoc.com.pk/Promotion/public/api/v1/chat/car-insurance/save-policy",
              {
                method: "POST",
                body: JSON.stringify(this.carInformation),
              }
            );
            if (response.ok) {
              // console.log("Data saved successfully", this.getSuccessOptions(result?.payLoad.id));
              const result = await response.json();
              details.push({
                key: "id",
                value: result?.payLoad?.id,
              });
              sessionStorage.setItem(
                "car_information",
                JSON.stringify(details)
              );
              this.items.text = [
                "Payment successful. Please provide the following details to proceed",
              ];
              this.items.issuccess = true;
              this.items.options = this.getSuccessOptions(this.paymetPrice);
              return this.items;
            } else {
              console.error("Failed to save data");
              this.items.text = ["Failed to save data."];
              this.items.issuccess = false;
              this.items.options = this.getFailOptions(this.paymetPrice);
              return this.items;
            }
          } catch (error) {
            console.error("Error saving data:", error);
            this.items.text = ["Failed to save policy.retry!"];
            this.items.issuccess = false;
            this.items.options = this.getFailOptions();
            return this.items;
          }
          this.items = {
            id: 30001,
            name: "Personal Info",
            type: "text",
            slideimg: "/doctorassistant/dia.jpg",
            options: this.getSuccessOptions(actualPrice),
          };

          // alert(JSON.stringify({ "payment Status": paymentStatus }));
        } else {
          this.items.text = [
            "Payment failed. Please recharge easypaisa account and try again",
          ];
          this.items.issuccess = false;
          this.items.options = this.getFailOptions(actualPrice);
        }
      }
    } catch (error) {
      console.log("Payment failed.", this.insType);
      console.error("Error:", error);

      this.items.text = [
        "Payment failed. Please try again later or contact support",
      ];
      this.items.issuccess = false;
      if (this.insType == "thirdparty") {
        this.items.options = [
          {
            id: 40012,
            text: "Retry",
            name: "Retry",
            price: price,
            type: "insurancepayment",
            desc: "Retry",
            inputtype: "button",
          },
        ];
      } else {
        this.items.options = this.getFailOptions(actualPrice);
      }
    }

    return this.items;
  }

  getSuccessOptions(price) {
    return [
      {
        id: 40012,
        text: "Car Registration Number",
        name: "Car Registration Number",
        price: price,
        type: "vehicalregistration",
        desc: "Car Registration Number",
        inputtype: "button",
      },
      // {
      //   id: 40012,
      //   text: "Car chassis/Engine Number",
      //   name: "Car chassis/Engine Number",
      //   price: price,
      //   type: "vehicalchassis",
      //   desc: "Car chassis/Engine Number",
      //   inputtype: "button",
      // },
    ];
  }
  getFailOptions(price) {
    return [
      {
        id: 40012,
        text: "Retry",
        name: "Retry",
        price: price,
        type: "insurancepayment",
        desc: "retry",
        inputtype: "button",
      },
    ];
  }
}

export default insurancepayment;
