class vehicalregistrationConfirm {
  constructor(msg) {
    const text = msg.inputtext;
    this.items = {
      id: 30001,
      name: "Custom Vehicle Make",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40012,
          text: "Confirm",
          name: "Confirm",
          price: "",
          type: "vehicalcolor",
          desc: "Confirm",
          inputtype: "button",
          key: "car_registration_no",
          value: text,
        },
        {
          id: 40013,
          text: "Change",
          name: "Change",
          price: "",
          type: "vehicalregistration",
          desc: "Change Value",
          inputtype: "button",
        },
      ],
      backtostart: false,
      text: [`<p>Provided vehicle registration number is ${text}. Is this correct?</p> `],
    };
  }

  getAllItems() {
    console.log(sessionStorage.getItem("car_information"));
    return this.items;
  }
}

export default vehicalregistrationConfirm;
