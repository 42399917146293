class feedbackGood {
  constructor() {
    this.items = {
      id: 50001,
      name: "About",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        // {
        //   id: 170,
        //   text: "Webdoc Health Services Rs. 1000",
        //   price: "1000",
        //   name: "Webdoc Health Services Rs. 1000",
        //   type: "paymentProduct",
        //   desc: "<ul><li>Unlimited Doctor Consultancy</li><li>Video and Voice Calls</li><li>Insurance coverage<li>In-Patient Cover upto Rs 30,000/Y</li><li>Accidental OPD Rs 5000</li></ul>",
        // },
        // {
        //   id: 169,
        //   text: "Webdoc Health Services + Rs. 2000",
        //   price: "2000",
        //   name: "Webdoc Health Services + Rs. 2000",
        //   type: "paymentProduct",
        //   desc: "<ul><li>Tele Doctor Consultancy for the whole family.</li><li>Insurance coverage<li>In-Patient Cover upto Rs 100,000/Y</li><li>Daily Limit Rs 10,000/Day</li></ul>",
        // },
      ],
      backtostart: true,
      text: `Thank you for your feedback. This will help us improve our services. We hope to serve you again.`,
    };
  }

  getAllItems() {
    return this.items;
  }
}

export default feedbackGood;
