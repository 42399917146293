import React, { useEffect, useRef, useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faMicrophone,
  faMicrophoneSlash,
  faVideoSlash,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { ref, update } from "firebase/database";
import { database } from "../firebase/firebaseConfig";
import AgoraRTC from "agora-rtc-sdk-ng";
import "./video-call.css";
import getchatmodals from "../modals/chatmodalss/callend";

const ChatVideoCall = ({
  email: cname,
  setShowVideoModal,
  setMessages,
  setShowList,
}) => {
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const localAudioRef = useRef(null);
  const ringingRef = useRef(new Audio("/ringtone.mp3"));
  const ringTimeoutRef = useRef(null);
  const localTracksRef = useRef([]); // Add this new ref
  const [client, setClient] = useState(null);
  const [joined, setJoined] = useState(false);
  const [ringing, setRinging] = useState(true);
  const [audioMuted, setAudioMuted] = useState(false);
  const [videoMuted, setVideoMuted] = useState(false);

  const session = JSON.parse(sessionStorage.getItem("user"));
  const senderEmail = session.userEmail;

  const email = cname ? cname.replace(/\./g, "") : null;

  useEffect(() => {
    document.body.style.backgroundColor = "#393b3c";

    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  const token = "f0924810ffd04733b7a726cb961157cd";

  useEffect(() => {
    if (!token) return;

    const agoraClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
    setClient(agoraClient);

    console.log("Agora client: ", client);
    // Subscribe to remote user's audio track
    agoraClient.on("user-published", async (user, mediaType) => {
      await agoraClient.subscribe(user, mediaType);
      console.log("User subscribed:", user);
      setRinging(false);
      if (mediaType === "video") {
        const remoteVideoTrack = user.videoTrack;
        remoteVideoTrack.play(remoteVideoRef.current);
      } else if (mediaType === "audio") {
        const remoteAudioTrack = user.audioTrack;
        remoteAudioTrack.play();
      }
      ringingRef.current.pause();
      clearTimeout(ringTimeoutRef.current);
    });

    const joinChannel = async () => {
      try {
        await agoraClient.join(token, cname, null, null);
        setJoined(true);
        console.log("Successfully joined the channel");
        const localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        const localVideoTrack = await AgoraRTC.createCameraVideoTrack();
        
        // Store tracks reference
        localTracksRef.current = [localAudioTrack, localVideoTrack];
        
        localVideoTrack.play(localVideoRef.current);
        await agoraClient.publish([localAudioTrack, localVideoTrack]);

        // Play the ringing tone
        ringingRef.current.loop = true;
        ringingRef.current.play();

        ringTimeoutRef.current = setTimeout(async () => {
          // Cleanup tracks when call times out
          localTracksRef.current.forEach((track) => {
            track.stop();
            track.close();
          });
          localTracksRef.current = [];
          endVideoCall();
        }, 50000);
      } catch (error) {
        console.error("Failed to join the channel:", error);
      }
    };

    const makeCallEntryForFirebase = async (email, calling, status) => {
      try {
        const callEntryData = {
          AppointmentID: "0",
          CallType: "Incoming Video Call",
          IsCalling: calling,
          CallingPlatform: "Mini Chat App",
          SenderEmail: senderEmail,
        };
        await update(ref(database, `DoctorCall/${email}`), callEntryData);

        const doctorStatusUpdate = {
          status: status,
        };
        await update(ref(database, `Doctors/${email}`), doctorStatusUpdate);
      } catch (error) {
        console.error("Error making call:", error);
        // Handle error
      }
    };
    makeCallEntryForFirebase(email, "true", "busy");
    joinChannel();
  }, [token, cname]);

  const toggleAudioMute = () => {
    if (client) {
      const localAudioTrack = client.localTracks.find(
        (track) => track.trackMediaType === "audio"
      );
      if (localAudioTrack) {
        if (audioMuted) {
          //Track is enabled
          localAudioTrack.setEnabled(true);
        } else {
          localAudioTrack.setEnabled(false);
        }
        setAudioMuted(!audioMuted);
      }
    }
  };

  const toggleVideoMute = () => {
    if (client) {
      const localVideoTrack = client.localTracks.find(
        (track) => track.trackMediaType === "video"
      );
      if (localVideoTrack) {
        if (videoMuted) {
          localVideoTrack.setEnabled(true);
        } else {
          localVideoTrack.setEnabled(false);
        }
        setVideoMuted(!videoMuted);
      }
    }
  };

  const handleEndCall = () => {
    endVideoCall();
  };

  const endVideoCall = useCallback(async () => {
    ringingRef.current.pause();
    setJoined(false);

    // Cleanup tracks
    localTracksRef.current.forEach((track) => {
      track.stop();
      track.close();
    });
    localTracksRef.current = [];

    if (client) {
      // Stop and close all local tracks before leaving
      client.localTracks.forEach((track) => {
        track.stop();
        track.close();
      });
      console.log("Before leaving call: ", client);
      await client.leave();
      client.removeAllListeners();
    }

    try {
      setShowVideoModal(false);
      setShowList(false);
      let obj = new getchatmodals();
      let msg = await obj.getAllItems(senderEmail, cname);
      console.log(obj);
      setTimeout(() => {
        setMessages((prevMessages) => [...prevMessages, msg.modal]);

        if (msg.modal.options && msg.modal.options.length > 0) {
          msg.modal.options.forEach((option) => {
            option.isOption = true;
            option.sender = "Receiver";
            setMessages((prevMessages) => [...prevMessages, option]);
          });
        }
      }, 2000);

      const callEntryData = {
        AppointmentID: "0",
        CallType: "Incoming Video Call",
        IsCalling: "",
        SenderEmail: senderEmail,
      };
      await update(ref(database, `DoctorCall/${email}`), callEntryData);

      const doctorStatusUpdate = {
        status: "online",
      };
      await update(ref(database, `Doctors/${email}`), doctorStatusUpdate);
    } catch (error) {
      console.error("Error making call:", error);
    }
  }, [client, senderEmail]);

  useEffect(() => {
    if (client) {
      const handleUserLeft = () => {
        endVideoCall();
      };

      client.on("user-left", handleUserLeft);

      // Clean up the event listener on component unmount
      return () => {
        client.off("user-left", handleUserLeft);
      };
    }
  }, [client]);

  return (
    <>
      <div className="video-call-container position-fixed">
        <video
          ref={remoteVideoRef}
          className="video-container"
          autoPlay
          playsInline
          muted
        />
        <video
          ref={localVideoRef}
          className="position-absolute top-0 end-0 m-3"
          style={{ width: "200px", height: "150px" }}
          autoPlay
          playsInline
        />

        {ringing && (
          <div className="position-absolute top-50 start-50 bottom-0 translate-middle-x d-flex justify-content-center">
            <p style={{ color: "rgb(64 64 64)" }}>Ringing...</p>
          </div>
        )}

        <div
          className="position-absolute bottom-0 start-50 translate-middle-x d-flex justify-content-center"
          style={{ marginBottom: "30%" }}
        >
          <button
            className="btn btn-primary me-2 btn-round"
            onClick={toggleAudioMute}
          >
            <FontAwesomeIcon
              icon={audioMuted ? faMicrophoneSlash : faMicrophone}
            />
          </button>
          <button
            className="btn btn-danger me-2 btn-round"
            onClick={handleEndCall}
          >
            <FontAwesomeIcon icon={faPhone} />
          </button>
          <button
            className="btn btn-success btn-round"
            onClick={toggleVideoMute}
          >
            <FontAwesomeIcon icon={videoMuted ? faVideoSlash : faVideo} />
          </button>
        </div>
      </div>
    </>
  );
};

export default ChatVideoCall;
