class vehiclemake {
  constructor(msg) {
    console.log("res", msg);
    this.items = {
      id: 30001,
      name: "Vehicle Make",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [],
      backtostart: true,
      text: [
        `<p>Great! Now, please select the model of your ${
          msg.inputtext ? msg.inputtext : msg.desc
        } vehicle.</p> `,
      ],
    };

    this.paramMake = msg.desc;
    this.make = {
      Toyota: [
        {
          id: 40012,
          text: "Corolla",
          name: "Corolla",
          type: "vehiclemodel",
          desc: "Corolla",
          inputtype: "button",
          key: "model",
          value: "Corolla",
        },
        {
          id: 40012,
          text: "Prius",
          name: "Prius",
          type: "vehiclemodel",
          desc: "Prius",
          inputtype: "button",
          key: "model",
          value: "Prius",
        },
        {
          id: 40011,
          text: "Vitz",
          name: "Vitz",
          type: "vehiclemodel",
          desc: "Vitz",
          inputtype: "button",
          key: "model",
          value: "Vitz",
        },
        {
          id: 40012,
          text: "Prado",
          name: "Prado",
          type: "vehiclemodel",
          desc: "Prado",
          inputtype: "button",
          key: "model",
          value: "Prado",
        },
        {
          id: 40012,
          text: "Landcruiser",
          name: "Landcruiser",
          type: "vehiclemodel",
          desc: "Landcruiser",
          inputtype: "button",
          key: "model",
          value: "Landcruiser",
        },
        {
          id: 40011,
          text: "Revo",
          name: "Revo",
          type: "vehiclemodel",
          desc: "Revo",
          inputtype: "button",
          key: "model",
          value: "Revo",
        },
        {
          id: 40012,
          text: "Rocco",
          name: "Rocco",
          type: "vehiclemodel",
          desc: "Rocco",
          inputtype: "button",
          key: "model",
          value: "Rocco",
        },
        {
          id: 40012,
          text: "Cross",
          name: "Cross",
          type: "vehiclemodel",
          desc: "Cross",
          inputtype: "button",
          key: "model",
          value: "Cross",
        },
        {
          id: 40011,
          text: "Camry",
          name: "Camry",
          type: "vehiclemodel",
          desc: "Camry",
          inputtype: "button",
          key: "model",
          value: "Camry",
        },
        {
          id: 40011,
          text: "Fortuner",
          name: "Fortuner",
          type: "vehiclemodel",
          desc: "Fortuner",
          inputtype: "button",
          key: "model",
          value: "Fortuner",
        },
        {
          id: 40013,
          text: "Other",
          name: "Other",
          type: "vehiclemodel",
          desc: "Other",
          inputtype: "textinput",
          key: "model",
          value: "Other",
        },
      ],
      Audi: [
        {
          id: 40012,
          text: "A4",
          name: "A4",
          type: "vehiclemodel",
          desc: "A4",
          inputtype: "button",
          key: "model",
          value: "A4",
        },
        {
          id: 40012,
          text: "A5",
          name: "A5",
          type: "vehiclemodel",
          desc: "A5",
          inputtype: "button",
          key: "model",
          value: "A5",
        },
        {
          id: 40012,
          text: "A7",
          name: "A7",
          type: "vehiclemodel",
          desc: "A7",
          inputtype: "button",
          key: "model",
          value: "A7",
        },
        {
          id: 40012,
          text: "A3",
          name: "A3",
          type: "vehiclemodel",
          desc: "A3",
          inputtype: "button",
          key: "model",
          value: "A3",
        },
        {
          id: 40012,
          text: "e-tron",
          name: "e-tron",
          type: "vehiclemodel",
          desc: "e-tron",
          inputtype: "button",
          key: "model",
          value: "e-tron",
        },
        {
          id: 40012,
          text: "e-tron GT",
          name: "e-tron GT",
          type: "vehiclemodel",
          desc: "e-tron GT",
          inputtype: "button",
          key: "model",
          value: "e-tron GT",
        },
        {
          id: 40012,
          text: "Q7",
          name: "Q7",
          type: "vehiclemodel",
          desc: "Q7",
          inputtype: "button",
          key: "model",
          value: "Q7",
        },
        {
          id: 40013,
          text: "Other",
          name: "Other",
          type: "vehiclemodel",
          desc: "Other",
          inputtype: "textinput",
          key: "model",
          value: "Other",
        },
      ],
      Mercedez: [
        {
          id: 40012,
          text: "C class",
          name: "C class",
          type: "vehiclemodel",
          desc: "C class",
          inputtype: "button",
          key: "model",
          value: "C class",
        },
        {
          id: 40012,
          text: "E class",
          name: "E class",
          type: "vehiclemodel",
          desc: "E class",
          inputtype: "button",
          key: "model",
          value: "E class",
        },
        {
          id: 40012,
          text: "S class",
          name: "S class",
          type: "vehiclemodel",
          desc: "S class",
          inputtype: "button",
          key: "model",
          value: "S class",
        },
        {
          id: 40013,
          text: "Other",
          name: "Other",
          type: "vehiclemodel",
          desc: "Other",
          inputtype: "textinput",
          key: "model",
          value: "Other",
        },
      ],
      Honda: [
        {
          id: 40012,
          text: "Civic",
          name: "Civic",
          type: "vehiclemodel",
          desc: "Civic",
          inputtype: "button",
          key: "model",
          value: "Civic",
        },
        {
          id: 40012,
          text: "City",
          name: "City",
          type: "vehiclemodel",
          desc: "City",
          inputtype: "button",
          key: "model",
          value: "City",
        },
        {
          id: 40012,
          text: "Accord",
          name: "Accord",
          type: "vehiclemodel",
          desc: "Accord",
          inputtype: "button",
          key: "model",
          value: "Accord",
        },
        {
          id: 40012,
          text: "BRV",
          name: "BRV",
          type: "vehiclemodel",
          desc: "BRV",
          inputtype: "button",
          key: "model",
          value: "BRV",
        },
        {
          id: 40013,
          text: "Other",
          name: "Other",
          type: "vehiclemodel",
          desc: "Other",
          inputtype: "textinput",
          key: "model",
          value: "Other",
        },
      ],
      Kia: [
        {
          id: 40012,
          text: "Sportage",
          name: "Sportage",
          type: "vehiclemodel",
          desc: "Sportage",
          inputtype: "button",
          key: "model",
          value: "Sportage",
        },
        {
          id: 40012,
          text: "Picanto",
          name: "Picanto",
          type: "vehiclemodel",
          desc: "Picanto",
          inputtype: "button",
          key: "model",
          value: "Picanto",
        },
        {
          id: 40012,
          text: "Sorento",
          name: "Sorento",
          type: "vehiclemodel",
          desc: "Sorento",
          inputtype: "button",
          key: "model",
          value: "Sorento",
        },
        {
          id: 40012,
          text: "Carnival",
          name: "Carnival",
          type: "vehiclemodel",
          desc: "Carnival",
          inputtype: "button",
          key: "model",
          value: "Carnival",
        },
        {
          id: 40013,
          text: "Other",
          name: "Other",
          type: "vehiclemodel",
          desc: "Other",
          inputtype: "textinput",
          key: "model",
          value: "Other",
        },
      ],
      Hyundai: [
        {
          id: 40012,
          text: "Elantra",
          name: "Elantra",
          type: "vehiclemodel",
          desc: "Elantra",
          inputtype: "button",
          key: "model",
          value: "Elantra",
        },
        {
          id: 40012,
          text: "Sonata",
          name: "Sonata",
          type: "vehiclemodel",
          desc: "Sonata",
          inputtype: "button",
          key: "model",
          value: "Sonata",
        },
        {
          id: 40012,
          text: "Tuscon",
          name: "Tuscon",
          type: "vehiclemodel",
          desc: "Tuscon",
          inputtype: "button",
          key: "model",
          value: "Tuscon",
        },
        {
          id: 40012,
          text: "Santa fe",
          name: "Santa fe",
          type: "vehiclemodel",
          desc: "Santa fe",
          inputtype: "button",
          key: "model",
          value: "Santa fe",
        },
        {
          id: 40013,
          text: "Other",
          name: "Other",
          type: "vehiclemodel",
          desc: "Other",
          inputtype: "textinput",
          key: "model",
          value: "Other",
        },
      ],
      Suzuki: [
        {
          id: 40012,
          text: "Alto",
          name: "Alto",
          type: "vehiclemodel",
          desc: "Alto",
          inputtype: "button",
          key: "model",
          value: "Alto",
        },
        {
          id: 40012,
          text: "Mehran",
          name: "Mehran",
          type: "vehiclemodel",
          desc: "Mehran",
          inputtype: "button",
          key: "model",
          value: "Mehran",
        },
        {
          id: 40011,
          text: "Jimmy",
          name: "Jimmy",
          type: "vehiclemodel",
          desc: "Jimmy",
          inputtype: "button",
          key: "model",
          value: "Jimmy",
        },
        {
          id: 40011,
          text: "Cultus",
          name: "Cultus",
          type: "vehiclemodel",
          desc: "Cultus",
          inputtype: "button",
          key: "model",
          value: "Cultus",
        },
        {
          id: 40011,
          text: "WagonR",
          name: "WagonR",
          type: "vehiclemodel",
          desc: "WagonR",
          inputtype: "button",
          key: "model",
          value: "WagonR",
        },
        {
          id: 40011,
          text: "Swift",
          name: "Swift",
          type: "vehiclemodel",
          desc: "Swift",
          inputtype: "button",
          key: "model",
          value: "Swift",
        },
        {
          id: 40011,
          text: "Bolan",
          name: "Bolan",
          type: "vehiclemodel",
          desc: "Bolan",
          inputtype: "button",
          key: "model",
          value: "Bolan",
        },
        {
          id: 40011,
          text: "Ravi",
          name: "Ravi",
          type: "vehiclemodel",
          desc: "Ravi",
          inputtype: "button",
          key: "model",
          value: "Ravi",
        },
        {
          id: 40013,
          text: "Other",
          name: "Other",
          type: "vehiclemodel",
          desc: "Other",
          inputtype: "textinput",
          key: "model",
          value: "Other",
        },
      ],
      Other: [
        {
          id: 40013,
          text: "Other",
          name: "Other",
          type: "vehiclemodel",
          desc: "Other",
          inputtype: "textinput",
          key: "model",
          value: "Other",
        },
      ],
    };
  }

  getAllItems() {
    console.log("Insurance type print",sessionStorage.getItem("insuranceType"));
    console.log(sessionStorage.getItem("car_information"));
    this.items.options = this.make[this.paramMake];
    return this.items;
  }
}

export default vehiclemake;
