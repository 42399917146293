class isexcellent {
  constructor(data) {
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    this.items = {
      id: 30001,
      name: "Start Text",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        // {
        //   id: 70,
        //   text: "3 Month Package",
        //   name: "3 Month Package",
        //   price: "2000",
        //   type: "insurancepayment",
        //   desc: "<p>Home and road side assistance with in Rawalpindi and Islamabad.</p>",
        // },
        // {
        //   id: 72,
        //   text: "6 Month Package",
        //   name: "6 Month Package",
        //   price: "4000",
        //   type: "insurancepayment",
        //   desc: "<p>Home and Road side assistance with one time free break service within Rawalpindi and Islamabad.</p>",
        // },
      ],
      backtostart: true,
      text: [`<p>Thank you for your feedback. `],
    };
  }

  getAllItems() {
    return this.items;
  }
}

export default isexcellent;
