import React, { useEffect, useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLoading } from "../contexts/LoadingContext";
import axios from "axios";
import Icon from "../components/Icon";
import Header from "../components/Header";
import Header_packages from "../components/Header_packages";
import Doctors_db from "../components/Doctors_db";
import Insurance from "../components/Insurance";
import Historycard from "../components/Historycard";
import ProtectSection from "../components/protection";
import Swal from "sweetalert2";
import ".././style.css";

const Main = () => {
  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useLoading();
  const [isUserData, setUserData] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    if (sessionUser) {
      setIsLoading(false);
      setUserData(sessionUser);
    } else {
      authenticateUser();
    }
  }, []);

  const authenticateUser = () => {
    setIsLoading(true);
    const scopes = [
      "USER_MSISDN",
      "USER_NAME",
      "OPEN_ID",
      "UNION_ID",
      "USER_BIRTHDAY",
    ];

    const getUserAuthCodeResult = (jsonResult) => {
      const result = JSON.parse(jsonResult);
      try {
        if (result.status === "SUCCESS") {
          const form = new FormData();
          form.append("value", result.authCode);
          // form.append("value", 'eb6fede2361ed929cb0f53f5f643ec06ffc312b0');

          axios
            .post(
              "https://miniappeasypaisa.webddocsystems.com/public/api/v1/auth",
              form,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              if (response.data.openId) {
                sessionStorage.setItem("user", JSON.stringify(response.data));
                setUserData(response.data);
              } else {
                Swal.fire({
                  icon: "info",
                  allowOutsideClick: false,
                  title: "Something went wrong. Please try again later!",
                  confirmButtonText: "Retry",
                }).then(() => {
                  authenticateUser();
                });
              }
              // sessionStorage.setItem("user", JSON.stringify(response.data));
              // setUserData(response.data);
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          Swal.fire({
            allowOutsideClick: false,
            title: "Please Share the information for Services",
            confirmButtonText: "Retry",
          }).then((result) => {
            if (result.isConfirmed) {
              window.mp.getUserAuthCode(scopes);
            } else {
              setIsLoading(false);
            }
          });
        }
      } catch (error) {
        console.error("Error fetching doctor profile:", error);
        setIsLoading(false);
      }
    };

    window.mp.getUserAuthCode(scopes);

    window.getUserAuthCodeResult = getUserAuthCodeResult;
  };

  useEffect(() => {
    const redirectFromPaymentSuccess = sessionStorage.getItem(
      "redirectFromPaymentSuccess"
    );
    if (redirectFromPaymentSuccess === "true") {
      Swal.fire({
        icon: "info",
        title: "Confirmation",
        text: "Call a Doc package has been activated successfully.",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      }).then(() => {
        // Clear the flag from sessionStorage
        sessionStorage.removeItem("redirectFromPaymentSuccess");
      });
    }

    const redirectFromPaymentFailed = sessionStorage.getItem(
      "redirectFromPaymentFailed"
    );

    if (redirectFromPaymentFailed === "true") {
      Swal.fire({
        icon: "info",
        title: "Payment Failed",
        text: "Something went wrong please try again.",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      }).then(() => {
        sessionStorage.removeItem("redirectFromPaymentFailed");
      });
    }

    const redirectFromVideoCall = sessionStorage.getItem(
      "redirectFromVideoCall"
    );

    if (redirectFromVideoCall === "true") {
      Swal.fire({
        icon: "info",
        title: "Consultation",
        text: "The prescription/consultation will be available in the medical history section within 5 minutes.",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      }).then(() => {
        // Clear the flag from sessionStorage
        sessionStorage.removeItem("redirectFromVideoCall");
      });
    }
  }, []);

  const prescriptionList = useCallback(() => {
    navigate(`/prescriptions-list`);
  }, [navigate]);

  const myPackages = useCallback(() => {
    navigate(`/my-packages`);
  }, [navigate]);

  return (
    <>
      <Header_packages title={"Main"} />
      <Header userSession={isUserData} />
      <div className="container mt-4">
        <Doctors_db />

        <ProtectSection />
        <div className="row mb-3">
          <div className="col-12">
            <div className="custom-card p-3">
              <div className="row mb-3">
                <div className="col-3">
                  <Link
                    to="/insurancescreen/health/1020"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Icon
                      icon="insuranceIcons/health.png"
                      label="Health Insurance"
                    />
                  </Link>
                </div>

                <div className="col-3">
                  <Link
                    to="/insurancescreen/mobile/1024"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Icon
                      icon="insuranceIcons/mobile.png"
                      label="Mobile Insurance"
                    />
                  </Link>
                </div>
                <div className="col-3">
                  <Link
                    to="/insurancescreen/income/1019"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Icon
                      icon="insuranceIcons/income.png"
                      label="Income Protection"
                    />
                  </Link>
                </div>
                <div className="col-3">
                  <Link
                    to="/insurancescreen/accidental/1027"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Icon
                      icon="insuranceIcons/accident.png"
                      label="Accidental Insurance"
                    />
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <Link
                    to="/insurancescreen/women/1026"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Icon
                      icon="insuranceIcons/women.png"
                      label="Women Health Insurance"
                    />
                  </Link>
                </div>
                <div className="col-3">
                  <Link
                    to="/insurancescreen/crops/1023"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Icon
                      icon="insuranceIcons/crops.png"
                      label="Crop Insurance"
                    />
                  </Link>
                </div>
                <div className="col-3">
                  <Link
                    to="/insurancescreen/livestock/1025"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Icon
                      icon="insuranceIcons/livestock.png"
                      label="Livestock Insurance"
                    />
                  </Link>
                </div>
                {/* <div className="col-3">
                  <Link
                    to="/insurancescreen/pocket/1025"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Icon
                      icon="insuranceIcons/pocket-sized-wallet.png"
                      label="Pocket Size Insurance"
                    />
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <Insurance />
        <div className="row">
          <div className="col-6">
            <div onClick={() => prescriptionList()}>
              <Historycard
                image="assets/NewMiniapp/health-report.png"
                title="Medical History"
              />
            </div>
          </div>
          <div className="col-6" onClick={() => myPackages()}>
            <Historycard
              image="assets/NewMiniapp/subscription.png"
              title="My Subscription"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;

// import React, { useEffect, useCallback, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { useLoading } from "../contexts/LoadingContext";
// import axios from "axios";
// import Icon from "../components/Icon";
// import Header from "../components/Header";
// import Doctors_db from "../components/Doctors_db";
// import Insurance from "../components/Insurance";
// import Historycard from "../components/Historycard";
// import Swal from "sweetalert2";
// import ".././style.css";

// const Main = () => {
//   const navigate = useNavigate();
//   const { isLoading, setIsLoading } = useLoading();
//   const [isUserData, setUserData] = useState();

//   useEffect(() => {
//     setIsLoading(true);
//     const sessionUser = JSON.parse(sessionStorage.getItem("user"));
//     if (sessionUser) {
//       setIsLoading(false);
//       setUserData(sessionUser);
//     } else {
//       setIsLoading(false);
//       authenticateUser();
//     }
//   }, []);

//   const authenticateUser = () => {
//     setIsLoading(true);
//     const scopes = [
//       "USER_MSISDN",
//       "USER_NAME",
//       "OPEN_ID",
//       "UNION_ID",
//       "USER_BIRTHDAY",
//     ];
//     const getUserAuthCodeResult = (jsonResult) => {
//       const result = JSON.parse(jsonResult);
//       try {
//         if (result.status === "SUCCESS") {
//           const form = new FormData();
//           form.append("value", result.authCode);

//           axios
//             .post(
//               "https://miniappeasypaisa.webddocsystems.com/public/api/v1/auth",
//               form,
//               {
//                 headers: {
//                   "Content-Type": "multipart/form-data",
//                 },
//               }
//             )
//             .then((response) => {
//               sessionStorage.setItem("user", JSON.stringify(response.data));
//             })
//             .catch((error) => {
//               console.error(error);
//               setIsLoading(false);
//             });
//         } else {
//           Swal.fire({
//             allowOutsideClick: false,
//             title: "Please Share the information for Services",
//             confirmButtonText: "Retry",
//           }).then((result) => {
//             if (result.isConfirmed) {
//               window.mp.getUserAuthCode(scopes);
//             } else {
//               setIsLoading(false);
//             }
//           });
//         }
//       } catch (error) {
//         console.error("Error fetching doctor profile:", error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     window.mp.getUserAuthCode(scopes);
//     window.getUserAuthCodeResult = getUserAuthCodeResult;
//     // window.getUserAuthCodeResult = getUserAuthCodeResult;
//   };

//   useEffect(() => {
//     const redirectFromVideoCall = sessionStorage.getItem(
//       "redirectFromVideoCall"
//     );

//     if (redirectFromVideoCall === "true") {
//       Swal.fire({
//         icon: "info",
//         title: "Consultation",
//         text: "The prescription/consultation will be available in the medical history section within 5 minutes.",
//         confirmButtonText: "Ok",
//       }).then(() => {
//         // Clear the flag from sessionStorage
//         sessionStorage.removeItem("redirectFromVideoCall");
//       });
//     }
//   }, []);

//   const prescriptionList = useCallback(() => {
//     navigate(`/prescriptions-list`);
//   }, [navigate]);

//   const myPackages = useCallback(() => {
//     navigate(`/my-packages`);
//   }, [navigate]);

//   return (
//     <>
//       <Header userSession={isUserData} />
//       <div className="container mt-4">
//         <Doctors_db />
//         <div className="row mb-3">
//           <div className="col-12">
//             <div className="custom-card p-3">
//               <div className="row mb-3">
//                 <div className="col-3">
//                   <Link
//                     to="/insurancescreen/health/1020"
//                     style={{ textDecoration: "none", color: "black" }}
//                   >
//                     <Icon
//                       icon="insuranceIcons/health.png"
//                       label="Health Insurance"
//                     />
//                   </Link>
//                 </div>

//                 <div className="col-3">
//                   <Link
//                     to="/insurancescreen/mobile/1024"
//                     style={{ textDecoration: "none", color: "black" }}
//                   >
//                     <Icon
//                       icon="insuranceIcons/mobile.png"
//                       label="Mobile Insurance"
//                     />
//                   </Link>
//                 </div>
//                 <div className="col-3">
//                   <Link
//                     to="/insurancescreen/income/1019"
//                     style={{ textDecoration: "none", color: "black" }}
//                   >
//                     <Icon
//                       icon="insuranceIcons/income.png"
//                       label="Income Protection"
//                     />
//                   </Link>
//                 </div>
//                 <div className="col-3">
//                   <Link
//                     to="/insurancescreen/accidental/1027"
//                     style={{ textDecoration: "none", color: "black" }}
//                   >
//                     <Icon
//                       icon="insuranceIcons/accident.png"
//                       label="Accidental Insurance"
//                     />
//                   </Link>
//                 </div>
//               </div>
//               <div className="row">
//                 <div className="col-3">
//                   <Link
//                     to="/insurancescreen/women/1026"
//                     style={{ textDecoration: "none", color: "black" }}
//                   >
//                     <Icon
//                       icon="insuranceIcons/women.png"
//                       label="Women Health Insurance"
//                     />
//                   </Link>
//                 </div>
//                 <div className="col-3">
//                   <Link
//                     to="/insurancescreen/crops/1023"
//                     style={{ textDecoration: "none", color: "black" }}
//                   >
//                     <Icon
//                       icon="insuranceIcons/crops.png"
//                       label="Crop Insurance"
//                     />
//                   </Link>
//                 </div>
//                 <div className="col-3">
//                   <Link
//                     to="/insurancescreen/livestock/1025"
//                     style={{ textDecoration: "none", color: "black" }}
//                   >
//                     <Icon
//                       icon="insuranceIcons/livestock.png"
//                       label="Livestock Insurance"
//                     />
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <Insurance />
//         <div className="row">
//           <div className="col-6">
//             <div onClick={() => prescriptionList()}>
//               <Historycard
//                 image="assets/NewMiniapp/health-report.png"
//                 title="Medical History"
//               />
//             </div>
//           </div>
//           <div className="col-6" onClick={() => myPackages()}>
//             <Historycard
//               image="assets/NewMiniapp/subscription.png"
//               title="My Subscription"
//             />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Main;
