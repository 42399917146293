import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import axios from "axios";
import Swal from "sweetalert2";
import PackageScreen from "../components/pacakge-screen";
import getInsuranceData from "../modals/getInsuranceData";
import Header_packages from "../components/Header_packages";
import ActivatedPackageComponent from "../components/activatedPackage";
import Header from "../components/Header";
import { useLocation } from "react-router-dom";
import { checkInsurance } from "../services/api";

import { useLoading } from "../contexts/LoadingContext";

const MonthlyMobile = () => {
  const [userData, setUserData] = useState(null);
  const [details, setDetails] = useState(null);
  const { isLoading, setIsLoading } = useLoading();
  const [healthData, setHealthData] = useState(null);
  const [error, setError] = useState(null);
  const type = "monthmobile";
  const service = "insurance";
  const id = "1024";

  const [customerNumber, setCustomerNumber] = useState(null);

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const openId = params.get("openId");
    if (openId) {
      console.log("OpenId from URL:", openId);
      // You can use the openId here as needed
    }
  }, [location]);

  // // Use effect to start the authentication process
  // useEffect(() => {
  //   setIsLoading(true);
  //   const sessionUser = JSON.parse(sessionStorage.getItem("user"));
  //   //console.log(sessionUser);
  //   if (sessionUser) {
  //     setIsLoading(false);
  //     setUserData(sessionUser);
  //     setCustomerNumber(sessionUser.userMsisdn);
  //     //customerNumber = sessionUser.userMsisdn;
  //   } else {
  //     authenticateUser();
  //   }
  // }, []);

  const checkInsuranceStatus = async (openId) => {
    try {
      const response = await checkInsurance(openId, "Mobile");
      console.log("Insurance check response:", response);
      setHealthData(response);
      return response; // Return the response so we can check it in useEffect
    } catch (err) {
      console.error("Insurance check error:", err);
      setError(err.message);
      return null;
    }
  };

  useEffect(() => {
    const verifyInsuranceAuth = async () => {
      setIsLoading(true);
      try {
        const params = new URLSearchParams(location.search);
        const openId = params.get("openId");

        if (!openId) {
          console.log("No openId found, proceeding with authentication");
          authenticateUser();
          return;
        }

        const response = await checkInsuranceStatus(openId);
        console.log("Insurance Status:", response);

        // Check if we have a valid response with openId
        if (response && response.openId) {
          console.log("Valid user data found, setting session");
          sessionStorage.setItem("user", JSON.stringify(response));
          // setIsLoading(false);
          setCustomerNumber(response.userMsisdn);
          setUserData(response);
        } else {
          console.log(
            "No valid user data found, proceeding with authentication"
          );
          authenticateUser();
        }
      } catch (error) {
        console.error("Error during insurance verification:", error);
        authenticateUser();
      }
    };

    verifyInsuranceAuth();
  }, [location.search]);

  // Function to handle user authentication code result
  const getUserAuthCodeResult = (jsonResult) => {
    const result = JSON.parse(jsonResult);
    try {
      if (result.status === "SUCCESS") {
        const form = new FormData();
        form.append("value", result.authCode);
        form.append("apikey", "Mobile");

        axios
          .post(
            "https://miniappeasypaisa.webddocsystems.com/public/api/v1/insurance-auth",
            form,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.data.openId) {
              sessionStorage.setItem("user", JSON.stringify(response.data));
              setUserData(response.data); // Set the user data
              setCustomerNumber(response.data.userMsisdn);
              //customerNumber = response.data.userMsisdn;
            } else {
              Swal.fire({
                icon: "info",
                allowOutsideClick: false,
                title: "Something went wrong. Please try again later!",
                confirmButtonText: "Retry",
              }).then(() => {
                authenticateUser();
              });
            }
          })
          .catch((error) => {
            console.error(error);
            setIsLoading(false); // Stop loading on error
          });
      } else {
        Swal.fire({
          allowOutsideClick: false,
          title: "Please Share the information for Services",
          confirmButtonText: "Retry",
        }).then((result) => {
          if (result.isConfirmed) {
            authenticateUser();
          } else {
            setIsLoading(false);
          }
        });
      }
    } catch (error) {
      console.error("Error processing auth code:", error);
      setIsLoading(false);
    }
  };

  // Function to authenticate the user
  const authenticateUser = () => {
    setIsLoading(true);
    const scopes = [
      "USER_MSISDN",
      "USER_NAME",
      "OPEN_ID",
      "UNION_ID",
      "USER_BIRTHDAY",
    ];
    // Trigger authentication code (implementation not provided in your code)
    window.mp.getUserAuthCode(scopes);
    window.getUserAuthCodeResult = getUserAuthCodeResult;
  };

  // Fetch subscription status
  const fetchSubscriptionStatus = async (customerNumber, service, id) => {
    setIsLoading(true); // Set loading to true when fetching

    try {
      const response = await fetch(
        `https://insuranceservice.webddocsystems.com/api/MiniApp/CheckCategoryPackages?id=${id}&customerNumber=${customerNumber}&service=${service}`,
        {
          method: "GET",
          headers: {
            Authorization:
              "Basic ZlJqZGZVbWFzOmFmMzNhZmNhODAwNzZkOWMxMTViOGU0NjAyZjc4YTIx",
          },
        }
      );
      const data = await response.json();
      setDetails(data); // Save subscription details in state
    } catch (error) {
      console.error("Error fetching subscription status:", error);
    } finally {
      setIsLoading(false); // Set loading to false once the API call is complete
    }
  };

  // Use effect to fetch subscription status after user is authenticated
  useEffect(() => {
    if (userData && customerNumber && service && id) {
      fetchSubscriptionStatus(customerNumber, service, id); // Fetch subscription status after user is authenticated
    }

    //console.log(userData);
    console.log(customerNumber);
    //console.log(service);
    //console.log(id);
  }, [userData]); // Trigger fetching when userData is set

  const { items, terms } = getInsuranceData(type);

  // Render a loading state or the actual content
  // if (isLoading) {
  //   return (
  //     <div>Loading...</div>
  //   );
  // }

  if (isLoading) {
    return;
  }
  // If user is authenticated and responseCode is "0000"

  if (userData && details?.responseCode === "0000" && details) {
    details.type = type;
    return (
      <div>
        <Header_packages
          //title={`${type.charAt(0).toUpperCase() + type.slice(1)} Package`}
          title="Monthly Mobile Insurance"
        />
        <Header userSession={JSON.parse(sessionStorage.getItem("user"))} />
        <ActivatedPackageComponent items={details} />
      </div>
    );
  } else {
    return (
      <div>
        <Header_packages title="Monthly Mobile Insurance" />
        <Header
          userSession={JSON.parse(sessionStorage.getItem("user"))}
          packageData="notActive"
        />
        <PackageScreen
          items={Array.isArray(items) ? items : []}
          terms={terms}
        />
      </div>
    );
  }

  // If no valid userData or other conditions
};

export default MonthlyMobile;
