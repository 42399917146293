class callend {
  constructor() {
    this.items = {
      id: 32000,
      name: "Precription",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 71000,
          text: "Agri Advisory Notes",
          name: "Agri Advisory Notes",
          type: "consultation",
          desc: "",
        },
        {
          id: 71001,
          text: "Connect to Agri Expert Again",
          name: "Connect to Agri Expert Again",
          type: "againconnect",
          desc: "",
        },
      ],
      backtostart: true,
      text: `I hope you had a smooth and helpful consultation with the agri expert leaving you feeling confident and cared for. Would you like me to get agri expert note or need to connect the agri expert again.`,
    };
  }

  getAllItems(patientemail, doctoremail) {
    // let link =
    //   "https://webdoc.com.pk/webcalling/ptcl/consultation.php?patientemail=" +
    //   patientemail +
    //   "&DoctorEmail=" +
    //   doctoremail +
    //   "&location=Miniapp";
    // this.items.options[0].desc =
    //   'Get the prescription from the below link </br> <a target="_blank" href="' +
    //   link +
    //   '">Get Prescription</a>';
    // return this.items;

    return {
      modal: this.items,
    };
  }
}

export default callend;
