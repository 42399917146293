class paymentProduct {
  constructor() {
    this.items = {
      id: 30003,
      name: "PaymentProduct",
      type: "text",
      options: [],
      backtostart: true,
      text: ``,
      slideimg: "/doctorassistant/dia.jpg",
      issuccess: false,
    };
  }

  async getAllItems(price, pid) {
    const form = new FormData();
    form.append("value", price);
    form.append("apikey", "Doctor");

    const settings = {
      method: "POST",
      body: form,
    };
    try {
      const response = await fetch(
        "https://miniappeasypaisa.webddocsystems.com/public/api/v1/create-payment",
        settings
      );
      const result = await response.json();

      if (result.paymentTransactionID) {
        const paymentStatus = await this.handlePaymentResult(
          result.paymentTransactionID
        );

        if (paymentStatus === "success") {
          let coverage;
          if (pid === 170) {
            coverage = 30000;
          } else if (pid === 169) {
            coverage = 100000;
          }

          const userData = JSON.parse(sessionStorage.getItem("user"));
          const payload = {
            openId: userData.openId,
            orderId: result.paymentTransactionID,
            Price: String(price),
            coverage: String(coverage),
            transactionStatus: "Success",
            mobileNumber: userData.userMsisdn,
            name: userData.userName,
            transactionId: result.paymentTransactionID,
            packageId: parseInt(pid, 10),
          };
          const subscriptionResponse = await fetch(
            "https://insuranceservice.webddocsystems.com/api/MiniApp/ActivatePackage",
            {
              method: "POST",
              headers: {
                Accept: "text/plain",
                "Content-Type": "application/json",
                Authorization:
                  "Basic ZlJqZGZVbWFzOmFmMzNhZmNhODAwNzZkOWMxMTViOGU0NjAyZjc4YTIx",
              },
              body: JSON.stringify(payload),
            }
          );

          if (!subscriptionResponse.ok) {
            this.items.text =
              "Payment failed. Please recharge easypaisa account and try again";
            this.items.issuccess = false;
            this.items.options = this.getPackageOptions();
            return this.items;
          }

          const data = await subscriptionResponse.json();
          // Update the items text for success

          if (data.responseCode === "0000") {
            this.items.text = `Thanks for successfully subcribing to the WHS services. We have activated your service, for more infromation 0518493737.`;
            this.items.issuccess = true;
            return this.items;
          } else {
            this.items.text =
              "Service activation failed. Please for more infromation 0518493737";
            this.items.issuccess = false;
          }
        } else {
          this.items.text =
            "Payment failed. Please recharge easypaisa account and try again";
          this.items.issuccess = false;
          this.items.options = this.getPackageOptions();
          return this.items;
        }
      }
    } catch (error) {
      console.error("Error:", error);
      this.items.text =
        "Payment failed. Please recharge easypaisa account and try again";
      this.items.issuccess = false;
      this.items.options = this.getPackageOptions();
      return this.items;
    }
  }

  handlePaymentResult = async (paymentTransactionID) => {
    return new Promise((resolve, reject) => {
      try {
        resolve("success");
      } catch (error) {
        reject("fail");
      }
    });
  };

  getPackageOptions() {
    return [
      {
        id: 170,
        text: "Webdoc Health Services Rs. 1000",
        price: "1000",
        name: "Webdoc Health Services Rs. 1000",
        type: "paymentProduct",
        desc: "<ul><li>Unlimited Doctor Consultancy</li><li>Video and Voice Calls</li><li>Insurance coverage<li>In-Patient Cover upto Rs 30,000/Y</li><li>Accidental OPD Rs 5000</li></ul>",
      },
      {
        id: 169,
        text: "Webdoc Health Services + Rs. 2000",
        price: "2000",
        name: "Webdoc Health Services + Rs. 2000",
        type: "paymentProduct",
        desc: "<ul><li>Tele Doctor Consultancy for the whole family.</li><li>Insurance coverage<li>In-Patient Cover upto Rs 100,000/Y</li><li>Daily Limit Rs 10,000/Day</li></ul>",
      },
    ];
  }
}
export default paymentProduct;
