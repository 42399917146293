import React from "react";
import { Link } from "react-router-dom";
import Advisory1 from "./advisory1";

const advisoryData = [
  {
    to: "/insurancescreen/agri/1002",
    image: "/assets/AppIcons/Onlinedoctor.png",
    title: "Online Doctors",
  },
  {
    to: "/insurancescreen/vets/1001",
    image: "/assets/AppIcons/MobileInsurance.png",
    title: "Mobile Insurance",
  },
  {
    to: "/insurancescreen/agri/1002",
    image: "/assets/AppIcons/CarIns.png",
    title: "Car Insurance",
  },
  {
    to: "/insurancescreen/agri/1002",
    image: "/assets/AppIcons/Vet&Pet.png",
    title: "Pet & Vet Doctors",
  },
  {
    to: "/insurancescreen/agri/1002",
    image: "/assets/AppIcons/Vet&Pet.png",
    title: "Agri Experts",
  },
  {
    to: "/insurancescreen/vets/1001",
    image: "/assets/AppIcons/RSA.png",
    title: "RSA Roadside Assistance",
  },
];

const AdvisorySection = () => {
  return (
    <div className="mb-3">
      <div className="d-flex flex-wrap row-gap-3">
        {advisoryData.map((item, index) => (
          <div className="col-4 col-md-2" key={index}>
            <Link to={item.to} className="text-decoration-none">
              <Advisory1 image={item.image} title={item.title} />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdvisorySection;
