class stillworking {
  constructor(data) {
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    this.items = {
      id: 30001,
      name: "Start Text",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40012,
          text: "Fixed",
          name: "Fixed",
          price: "",
          type: "feedback",
          desc: "Fixed",
          key:'is_fixed',
          value:1
        },
        {
          id: 40012,
          text: "Not Fixed",
          name: "Not Fixed",
          price: "",
          type: "notfixed",
          desc: "Not Fixed",
          key:'is_fixed',
          value:0
        },
      ],
      backtostart: false,
      text: [`<p>He is trying to fix it. Please be patient</p> `],
    };
  }

  getAllItems() {
    return this.items;
  }
}

export default stillworking;
