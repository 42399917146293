import React, { useState, useEffect } from "react";
import axios from "axios";

function PrescriptionList() {
  const [prescriptions, setPrescriptions] = useState([]);
  const [loading, setLoading] = useState(true);  // Track loading state

  // Fetching data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const sessionUser = JSON.parse(sessionStorage.getItem("user"));
       
        const response = await axios.get(
          `https://testingnew.webddocsystems.com/public/api/v1/prescription/list?patientId=${sessionUser.applicationUserId}&page=1&perPage=5`,
          {
            headers: {
              Cookie: "ci_session=jvhpe8ecbjhfc90tm67qts9qju3pokas",
            },
          }
        );
        setPrescriptions(response.data.payLoad);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);  // Set loading to false after the data is fetched
      }
    };

    fetchData();
  }, []);

  // Handle toggling collapse
  const handleToggle = (index) => {
    const updatedPrescriptions = [...prescriptions];
    updatedPrescriptions[index].isOpen = !updatedPrescriptions[index].isOpen;
    setPrescriptions(updatedPrescriptions);
  };

  return (
    <div className="container my-2">
      <h2 className="text-center">Prescription List</h2>

      {loading ? (  // Show loading state if data is still being fetched
        <div className="text-center">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="list-group">
          {prescriptions.map((prescription, index) => (
            <div className="list-group-item mb-2" key={prescription.Id}>
              <div
                className=""
                style={{ cursor: "pointer" }}
                onClick={() => handleToggle(index)}
              >
                <div className="row">
                  <div className="col-3 float-end">
                    <img
                      src={prescription.DoctorImage}
                      alt={prescription.DoctorFullName}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div className="col-9">
                    <strong>{prescription.DoctorFullName}</strong> <br />
                    {prescription.ConsultationDate}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12">
                    <button className="btn btn-success btn-sm">
                      {prescription.isOpen ? "Hide Details" : "Show Details"}
                    </button>
                  </div>
                </div>
              </div>

              {/* Collapsible content */}
              {prescription.isOpen && (
                <div className="mt-3">
                  <ul className="list-group">
                    <li className="list-group-item">
                      <strong>Complaint:</strong>{" "}
                      {prescription.Complaint || "N/A"}
                    </li>
                    <li className="list-group-item">
                      <strong>Diagnosis:</strong>{" "}
                      {prescription.Diagnosis || "N/A"}
                    </li>
                    <li className="list-group-item">
                      <strong>Prescription:</strong>{" "}
                      {prescription.Prescription || "N/A"}
                    </li>
                    <li className="list-group-item">
                      <strong>Tests:</strong> {prescription.Tests || "N/A"}
                    </li>
                    <li className="list-group-item">
                      <strong>Remarks:</strong> {prescription.Remarks || "N/A"}
                    </li>
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default PrescriptionList;
