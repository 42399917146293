class vehicalregistration {
  constructor(msg) {
    console.log("vehicle --->", msg);
    if (msg.details) {
      const sessionData = Object.entries(msg.details).map(([key, value]) => ({
        key,
        value,
      }));
      sessionStorage.setItem("insuranceType", msg.details.type);

      // Store in session storage
      sessionStorage.setItem("car_information", JSON.stringify(sessionData));
    }

    this.items = {
      id: 30001,
      name: "Custom Vehicle Make",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40012,
          text: "Submit",
          name: "Submit",
          type: "vehicalregistrationConfirm",
          desc: msg.desc,
          inputtype: "textinput",
        },
      ],
      backtostart: false,
      text: [`<p>Please provide the registration number of your vehicle.</p> `],
    };
  }

  getAllItems() {
    console.log(sessionStorage.getItem("car_information"));
    return this.items;
  }
}

export default vehicalregistration;
