export default function getPakistanGreeting() {
  const options = { timeZone: "Asia/Karachi", hour12: false, hour: "2-digit" };
  const hour = new Intl.DateTimeFormat("en-US", options).format(new Date());

  if (hour >= 5 && hour < 11) {
    return "Good Morning";
  } else if (hour >= 11 && hour < 15) {
    return "Good Afternoon";
  } else {
    return "Good Evening";
  }
}
