import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Alert } from "react-bootstrap";
// import { Row, Col } from "react-bootstrap";
import { onValue, ref } from "firebase/database";
import { database } from "../firebase/firebaseConfig";
import Swal from "sweetalert2";
import { useLoading } from "../contexts/LoadingContext";
import "./doctor-list-component.css";

const VetDoctorListComponent = () => {
  const [doctors, setDoctors] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [page, setPage] = useState(1);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const { isLoading, setIsLoading } = useLoading();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `https://digital.webdoc.com.pk/claimportal/public/vet/doctor/list?page=${page}&perPage=30`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        if (data.statusCode === 3 && data.count === 0) {
          setAllDataLoaded(true);
          return;
        }
        if (data.payLoad === null) {
          console.log("No data found");
        } else if (Array.isArray(data.payLoad)) {
          setDoctors((prevDoctors) => [...prevDoctors, ...data.payLoad]);
        } else {
          const payloadArray = Object.values(data).find(Array.isArray);
          if (payloadArray) {
            setDoctors((prevDoctors) => [...prevDoctors, ...payloadArray]);
          } else {
            throw new Error("Payload data is not an array");
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    

    fetchData();
  }, [page, setIsLoading]);

  useEffect(() => {
    const realtimeRef = ref(database, "VetDoctors");
    const unsubscribe = onValue(realtimeRef, (snapshot) => {
      const realtimeData = snapshot.val();
      if (realtimeData) {
        const updatedDoctors = doctors.map((doctor) => {
          const email = doctor.Email.replace(/\./g, "");
          const status = realtimeData[email]?.status || doctor.status;
          return { ...doctor, status };
        });
        setDoctors(updatedDoctors);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [doctors]);
  // const onAudioVideoCallClick = useCallback(
  //   (id, email) => {
  //     sessionStorage.setItem("doctorEmail", email);
  //     sessionStorage.setItem("doctorUserId", id);
  //     navigate(`/packages`);
  //   },
  //   [navigate]
  // );

  const onAudioVideoCallClick = useCallback(
    (id, email) => {
      if (email) {
        navigate(`/video-call`, { state: { email } });
      } else {
        console.error("Doctor profile data is not available.");
      }
    },
    [navigate]
  );

  const onDoctorProfile = useCallback(
    (id, email, status) => {
      // sessionStorage.setItem("doctorEmail", email);
      // sessionStorage.setItem("doctorStatus", status);
      navigate(`/doctor-profile/${id}`);
    },
    [navigate]
  );

  const handleSearchInputChange = useCallback((event) => {
    setSearchKeyword(event.target.value);
  }, []);

  const filteredDoctors = doctors.filter(
    (doctor) =>
      doctor.Name.toLowerCase().includes(searchKeyword.toLowerCase())
  );

  const onlineDoctors = filteredDoctors.filter(
    (doctor) => doctor.status === "online"
  );
  const busyDoctors = filteredDoctors.filter(
    (doctor) => doctor.status === "busy"
  );
  const offlineDoctors = filteredDoctors.filter(
    (doctor) => doctor.status === "offline"
  );

  const doctorListToRender = onlineDoctors.concat(busyDoctors, offlineDoctors);

  return (
    <div>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search.."
          className="search-bar"
          value={searchKeyword}
          onChange={handleSearchInputChange}
        />
      </div>

      <Row xs={1} md={2} lg={2} className="gx-3 mt-2">
        {doctorListToRender.map((doctor) => (
          <Col key={doctor.id} lg={4} md={6} className="mb-2">
            <Card
              className="h-100"
              onClick={() => onAudioVideoCallClick(doctor.Id)}
              // style={{
              //   boxShadow:
              //     "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.15)",
              // }}
            >
              <Card.Body>
                <Card.Text>
                  <Row>
                    <Col xs={12}>
                      <div className="star-group">
                        <div
                          className="ellipse-pair"
                          onClick={() =>
                            onDoctorProfile(
                              doctor.ApplicationUserId,
                              doctor.Email,
                              doctor.status
                            )
                          }
                        >
                          <div className="ellipse2">
                            <div>
                              <img
                                className="d2-parent2"
                                alt=""
                                src={doctor.ImgLink}
                              />
                              <div
                                className={
                                  doctor.status === "online"
                                    ? "star-field"
                                    : "offline-star-field"
                                }
                              />
                            </div>
                          </div>
                          <div className="frame-parent30">
                            <div className="dr-karim-rehmat10">
                              {doctor.Name}
                              
                            </div>

                            <div className="family-medicine8">
                              {doctor.DoctorSpecialties}{" "}
                              <span className="text-info">
                                ({doctor.DoctorDutyTime})
                              </span>
                            </div>

                            {doctor.status === "online" && (
                              <div className="online-parent2">
                                <div className="online9">ONLINE</div>
                              </div>
                            )}
                            {doctor.status === "offline" && (
                              <div className="online-parent3">
                                <div className="online9">OFFLINE</div>
                              </div>
                            )}
                            {doctor.status === "busy" && (
                              <div className="online-parent4">
                                <div className="online9">BUSY</div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="twinkling-stars-frame">
                          {/* <div className="family-medicine-logo-group4">
                            {[...Array(Math.round(doctor.AverageRating))].map(
                              (_, index) => (
                                <img
                                  key={index}
                                  className={`video-call-icon${
                                    index === 0 ? "" : index
                                  }`}
                                  loading="eager"
                                  alt=""
                                  src="/star-component.svg"
                                />
                              )
                            )}
                          </div> */}
                          <div className="framewith-ellipses2">
                            <div className="call-frame" />
                            <div
                              className="audio-video-call7"
                              onClick={() => {
                                if (doctor.status !== "online") {
                                  Swal.fire({
                                    icon: "info",
                                    title: "Please wait",
                                    text: "Please wait for the doctor to come online.",
                                    confirmButtonText: "OK",
                                  });
                                } else {
                                  onAudioVideoCallClick(
                                    doctor.ApplicationUserId,
                                    doctor.Email
                                  );
                                }
                              }}
                              style={{ fontSize: "10px" }}
                            >
                              Video Call
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default VetDoctorListComponent;
