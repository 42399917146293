import faqs from "./faq";
import about from "./about";
import needto_see_doc from "./calladoc";
import startingchat from "./startingchat";
import doc_assistant_start from "./doc_assistant_start";
import prescription from "./precription";
import payment from "./payment";
import doc_assistant_calltype from "./doc_assistant_calltype";
import disease_info from "./disease_info";
import callend from "./callend";
import feedbackExe from "./feedbackExe";
import feedbackGood from "./feedbackGood";
import feedbackBad from "./feedbackBad";
import ComplaintEnd from "./complaintend";
import paymentProduct from "./paymentProduct";

const getVetChatData = async (type, price, pid, disease) => {
  let newMessage;
  console.log("Type passed:", type);
  if (type === "about") {
    newMessage = new about();
    // newMessage = response.getAllItems();
  } else if (type === "faqs") {
    newMessage = new faqs();
    // newMessage = response.getAllItems();
  } else if (type === "againconnect") {
    newMessage = new doc_assistant_calltype();
    //console.log(newMessage.getAllItems(disease));
    return {
      modal: await newMessage.getAllItems(disease),
    };
    // newMessage = response.getAllItems();
  } else if (type === "doctor") {
    newMessage = new needto_see_doc();
    let assistant = new doc_assistant_calltype();

    let res = await newMessage.getAllItems();
    if (res.options.length > 0) {
      return {
        modal: res,
      };
    } else {
      return {
        modal: res,
        doctorassistant: await assistant.getAllItems(),
      };
    }
    // newMessage = response.getAllItems();
  } else if (type === "start") {
    newMessage = new startingchat();
    // newMessage = response.getAllItems();
  } else if (type === "precription") {
    newMessage = new prescription();
    // newMessage = response.getAllItems();
  } else if (type === "payment") {
    newMessage = new payment();
    // let assistant = new doc_assistant_start();
    let assistant = new doc_assistant_calltype();

    let res = await newMessage.getAllItems(price, pid);
    //alert(JSON.stringify(res));
    if (res.issuccess) {
      return {
        modal: res,
        doctorassistant: await assistant.getAllItems(),
      };
    } else {
      return {
        modal: res,
      };
    }
    // newMessage = response.getAllItems();
  }
  //  else if (type === "info") {
  //   newMessage = new disease_info();
  //   return {
  //     modal: await newMessage.getAllItems(disease),
  //   };
  // }
  else if (type === "callend") {
    newMessage = new callend();
  } else if (type == "feedbackExe") {
    newMessage = new feedbackExe();
  } else if (type == "feedbackGood") {
    newMessage = new feedbackGood();
  } else if (type == "feedbackBad") {
    newMessage = new feedbackBad();
  } else if (type == "ComplaintEnd") {
    newMessage = new ComplaintEnd();
  } else if (type == "paymentProduct") {
    newMessage = new paymentProduct();

    let res = await newMessage.getAllItems(price, pid);
    //alert(JSON.stringify(res));
    return {
      modal: res,
    };
  }

  return {
    modal: await newMessage.getAllItems(),
  };
};

export default getVetChatData;
