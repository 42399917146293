class about {
  constructor() {
    this.items = {
      id: 30001,
      name: "About",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40012,
          text: "Connect to the Agri Expert",
          name: "Connect to the Agri Expert",
          price: "",
          type: "doctor",
          desc: "I’d like to connect with a Agri Expert.",
        },
        // {
        //   id: 40011,
        //   text: "FAQs",
        //   name: "FAQ",
        //   price: "",
        //   type: "faqs",
        //   desc: "FAQs",
        // },
      ],
      backtostart: true,
      text: `<p><strong>WEB<span style="color: #ff0000;">DOC</span></strong> is Pakistan&rsquo;s top Digital Tech platform, 
     offering a comprehensive solution for trusted online expert consultations with the highest level of security. No matter where you are in the country, WEBDOC connects you with professionals, providing seamless digital expert advice. Our platform allows you to schedule video or audio consultations at your convenience, ensuring you receive the support you need, anytime and anywhere.
      </p>`,
    };
  }

  getAllItems() {
    return this.items;
  }
}

export default about;
