class thirdpartytheft {
  constructor() {
    this.items = {
      id: 30001,
      name: "Third party / Fire & Theft",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40012,
          text: "Toyota",
          name: "Toyota",
          type: "vehiclemake",
          desc: "Toyota",
          inputtype: "button",
          key: "make",
          value: "Toyota",
        },
        {
          id: 40012,
          text: "Audi",
          name: "Audi",
          type: "vehiclemake",
          desc: "Audi",
          inputtype: "button",
          key: "make",
          value: "Audi",
        },
        {
          id: 40012,
          text: "Mercedez",
          name: "Mercedez",
          type: "vehiclemake",
          desc: "Mercedez",
          inputtype: "button",
          key: "make",
          value: "Mercedez",
        },
        {
          id: 40012,
          text: "Honda",
          name: "Honda",
          type: "vehiclemake",
          desc: "Honda",
          inputtype: "button",
          key: "make",
          value: "Honda",
        },
        {
          id: 40011,
          text: "Suzuki",
          name: "Suzuki",
          type: "vehiclemake",
          desc: "Suzuki",
          inputtype: "button",
          key: "make",
          value: "Suzuki",
        },
        {
          id: 40011,
          text: "Kia",
          name: "Kia",
          type: "vehiclemake",
          desc: "Kia",
          inputtype: "button",
          key: "make",
          value: "Kia",
        },
        {
          id: 40011,
          text: "Hyundai",
          name: "Hyundai",
          type: "vehiclemake",
          desc: "Hyundai",
          inputtype: "button",
          key: "make",
          value: "Hyundai",
        },
        {
          id: 40011,
          text: "Other",
          name: "Other",
          type: "vehiclemake",
          desc: "Other",
          inputtype: "textinput",
          key: "make",
          value: "Other",
        },
      ],
      backtostart: false,
      text: [
        `<p>It is for a budget-friendly option while still securing their car against fire and theft. This also covers third party (other party) in case of accidents on road but does not cover your vehicle damages. This is mid-level insurance policy.</p> `,
        // `<p>Perfect. You love your vehicle. It must be very costly. Let’s get some more information. Please select the make of your vehicle</p> `,
      ],
    };
  }

  getAllItems() {
    return this.items;
  }
}

export default thirdpartytheft;
