class buyinsurance {
    constructor(data) {
      const sessionUser = JSON.parse(sessionStorage.getItem("user"));
      this.items = {
        id: 30001,
        name: "Start Text",
        type: "text",
        slideimg: "/doctorassistant/dia.jpg",
        options: [
            { id: 40012,text: "Pay Now",name: "Pay Now",price: "",type: "haveinsurance",desc: "Pay Now"},
            { id: 40012,text: "No",name: "NO",price: "",type: "noNeedInsurance",desc: "NO"},
            ],
        backtostart: false,
        text: [ 
          `<p>You inruace price will be Rs 1000. Please confirm for payment.</p> `,
        ],
      };
    }
  
    getAllItems() {
      return this.items;
    }
  }
  
  export default buyinsurance;
  