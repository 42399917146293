class membership {
    constructor(data) {
      const sessionUser = JSON.parse(sessionStorage.getItem("user"));
      this.items = {
        id: 30001,
        name: "Start Text",
        type: "text",
        slideimg: "/doctorassistant/dia.jpg",
        options: [
          
          { id: 2, text: "6 month membership", name: "6 Month membership", price: "4000", type: "updatePlan",desc: "<ul><li>Home Assistance.</li> <li>RoadSide Assistance.</li><li>20% off on recovery service.</li> <span>(7 Days waiting period Apply)</span></ul>",key:"duration_days",value:180},
          { id: 1, text: "3 month membership", name: "3 Month membership", price: "2000", type: "updatePlan", desc: "<ul><li>Home Assistance.</li> <li>RoadSide Assistance.</li> <span>(7 Days waiting period Apply)</span></ul>",key:"duration_days",value:90 },
          { id: 1, text: "1 month membership", name: "1 Month membership", price: "1000", type: "updatePlan", desc: "<ul><li>Home Assistance.</li> <li>RoadSide Assistance.</li> <span>(7 Days waiting period Apply)</span></ul>",key:"duration_days",value:30 },
         ],
         backtostart: false,
         
        text: [
          `<p>
        Absolutely! To proceed, please select one of the following membership packages that best suits your needs:
          </p> `
        ],
      };
    }
  
    getAllItems() {
      return this.items;
    }
  }
  
  export default membership;
  