class feedbackExe {
  constructor() {
    this.items = {
      id: 50001,
      name: "About",
      type: "text",
      slideimg: "/doctorassistant/natasha.jpg",
      options: [],
      // options: [
      //   {
      //     id: 170,
      //     text: "Webdoc Health Services",
      //     price: "1000",
      //     name: "Webdoc Health Services",
      //     type: "paymentProduct",
      //     desc: "<ul><li>Unlimited Doctor Consultancy</li><li>Unlimited Tele Health Calls</li><li>Insurance coverage<li>In-Patient Cover upto Rs 30,000/Y</li><li>Accidental OPD Rs 5000</li></ul>",
      //   },
      //   {
      //     id: 169,
      //     text: "Webdoc Health Services +",
      //     price: "2000",
      //     name: "Webdoc Health Services +",
      //     type: "paymentProduct",
      //     desc: "<ul><li>Tele Doctor Consultancy for the whole family.</li><li>Insurance coverage<li>In-Patient Cover upto Rs 100,000/Y</li><li>Daily Limit Rs 10,000/Day</li></ul>",
      //   },
      // ],
      backtostart: true,
      // text: `Thank you for your feedback. We recommend considering our health insurance policies, which offer coverage for non-emergency hospitalizations and financial protection against unexpected healthcare costs.`,
      text: `Thank you for your feedback. `,
    };
  }

  getAllItems() {
    return this.items;
  }
}

export default feedbackExe;
