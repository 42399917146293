class car {
  constructor(data) {
    this.items = {
      id: 30001,
      name: "Start Text",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40012,
          text: "Instant Mechanic",
          name: "Instant Mechanic",
          price: "",
          type: "issue",
          desc: "Yes i need a mechanic",
          key: "instant_type",
        },
        // {
        //   id: 40012,
        //   text: "Membership",
        //   name: "Membership",
        //   price: "",
        //   type: "membership",
        //   desc: "I would like to take a membership",
        //   key: "instant_type",
        // },
      ],
      backtostart: false,
      text: [`<p>Need a mechanic now? Get instant assistance.</p> `],
    };
  }
  async getAllItems() {
    try {
      const sessionUser = JSON.parse(sessionStorage.getItem("user"));
      sessionStorage.setItem(
        "activation",
        JSON.stringify({ key: "payment_status", value: "InActive" })
      );
      return this.items;
      // const apiUrl =
      //   "https://Digital.webdoc.com.pk/Promotion/public/api/v1/chat/rsa-insurance/get";

      // const response = await fetch(apiUrl, {
      //   method: "POST",
      //   body: JSON.stringify({
      //     applicationUserId: sessionUser.applicationUserId,
      //   }),
      // });

      // const data = await response.json();
      // const membershipRecord = data?.payLoad?.find(
      //   (value) => value.instant_type === "Membership"
      // );

      // if (membershipRecord?.activate_date && membershipRecord?.duration_days) {
      //   const activationDate = new Date(membershipRecord.activate_date);
      //   const expiryDate = new Date(activationDate);
      //   expiryDate.setDate(
      //     expiryDate.getDate() + parseInt(membershipRecord.duration_days, 10)
      //   );

      //   if (new Date() < expiryDate) {
      //     // Remove Membership option and update text if membership is active
      //     sessionStorage.setItem(
      //       "activation",
      //       JSON.stringify({ key: "payment_status", value: "Active" })
      //     );

      //     this.items.options = this.items.options.filter(
      //       (option) => option.type !== "membership"
      //     );
      //     this.items.text = [
      //       `<p>Great! Your membership is active. Select an option to proceed.</p>`,
      //     ];
      // return this.items;

      //   } else
      //     sessionStorage.setItem(
      //       "activation",
      //       JSON.stringify({ key: "payment_status", value: "InActive" })
      //     );
      // return this.items;

      // }

      // else
      //     sessionStorage.setItem(
      //       "activation",
      //       JSON.stringify({ key: "payment_status", value: "InActive" })
      //     );
      // return this.items;
    } catch (error) {
      console.error("Error fetching data from API:", error);
      return this.items;
    }
  }
}

export default car;
