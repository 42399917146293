class PolicyEmail {
  constructor(data) {
    const id = data.id;
    const baseUrl = window.location.origin;
    this.items = {
      id: id,
      name: data.name,
      type: "text",
      email: data.inputtext,
      slideimg: "/doctorassistant/dia.jpg",
      options: [],
      backtostart: true,
      text: [
        `<p>The details of your insurance policy have been successfully sent to your email. Please review them at your convenience.</p>`,
      ],
    };
  }

  async getAllItems() {
    try {
      const userId = JSON.parse(
        sessionStorage.getItem("user")
      ).applicationUserId;
      const email = this.items.email;
      const policy_id = this.items.id;
      const formData = new FormData();
      formData.append("to", email);
      formData.append("id", policy_id);
      formData.append("applicationUserId", userId);

      const response = await fetch(
        "https://digital.webdoc.com.pk/Promotion/public/api/v1/chat/car-insurance/send-view-email",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();

      if (!response.ok) {
        this.items.text = ["Failed to send email! please try again."];
        console.log("Email sent failed:", this.items);

        this.items.options = [
          {
            id: this.items.id,
            text: "Submit",
            name: this.items.name,
            type: "policy",
            desc: data.desc,
            inputtype: "textinput",
          },
        ];
        return this.items;
      }
      if (this.items.name === "New Policy") {
        this.items.options = [
          {
            id: 40012,
            text: "Excellent",
            name: "Excellent 🙂",
            price: "",
            type: "isexcellent",
            desc: "Excellent 🙂",
          },
          {
            id: 40012,
            text: "Good",
            name: "Satisfactory 😐",
            price: "",
            type: "isgood",
            desc: "Satisfactory 😐",
          },
          {
            id: 40012,
            text: "Bad",
            name: "Unsatisfactory 🙁",
            price: "",
            type: "isbad",
            desc: "Unsatisfactory 🙁",
          },
        ];
      }
      console.log("items:>", this.items);
      console.log("Email sent successfully:", data);
      return this.items;
    } catch (error) {
      console.log("Error    ::::>", error);
      this.items.text = ["Failed to send email! please try again."];
      return this.items;
    }
  }
}

export default PolicyEmail;
