class doc_assistant_calltype {
  constructor() {
    this.items = {
      id: 50001,
      name: "About",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        { id: 100, name: "Video", type: "calltype", text: "Video" },
        { id: 101, name: "Voice", type: "calltype", text: "Voice" },
        // { id: 102, name: "Information about ",type:"info",text:"Disease Info", desc:"Information about "},
      ],
      backtostart: true,
      text: `Would you like to connect the Agri Expert via Video or Audio Call `,

      // text: `It’s sad to hear, but don’t worry we have more information regarding the issue mentioned. Would you like to connect the doctor via Video or Audio Call `,
    };
  }

  async getAllItems(diseaseName) {
    // this.items.options[2].name="Information About "+diseaseName;
    // this.items.options[2].desc="Information About "+diseaseName;
    return this.items;
  }
}

export default doc_assistant_calltype;
