class SaveQuotation {
  constructor(data) {
    this.price = data.price;
    const insType = sessionStorage.getItem("insuranceType");
    this.insType = insType;
    this.items = {
      id: 30001,
      name: "Promotions Step 1",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [],
      backtostart: true,
      text: "",
      issuccess: false,
    };

    // Retrieve car information from sessionStorage
    const carInformation = JSON.parse(
      sessionStorage.getItem("car_information")
    );
    const user = JSON.parse(sessionStorage.getItem("user"));

    // Ensure both user and car information exist in sessionStorage
    if (carInformation && user) {
      // Merge user information with car information
      this.carInformation = {
        ...user, // Spread user data
        userMsisdn: user.userMsisdn,
        applicationUserId: user.applicationUserId,
        ...carInformation.reduce((acc, item) => {
          acc[item.key] = item.value; // Map each key-value pair from carInformation to the object
          return acc;
        }, {}),
      };
    }
  }

  // Async method to get all items and save data
  async getAllItems() {
    const savedItems = await this.saveToAPI();
    return savedItems;
  }

  // Get package options for payment
  getPackageOptions() {
    return [
      {
        id: 40012,
        text: "Buy Now",
        name: "Buy Now",
        price: this.price,
        type: "paymentoptions",
        desc: "Buy Now",
        inputtype: "button",
      },
      // {
      //   id: 40012,
      //   text: "Find me a better price",
      //   name: "Find me a better price",
      //   price: this.price,
      //   type: "promotions",
      //   desc: "Find me a better price",
      //   inputtype: "button",
      //   key: "is_luckydraw",
      //   value: 1,
      // },
    ];
  }
  getTheftOnlyOptions() {
    return [
      {
        id: 40012,
        text: "Buy Now",
        name: "Buy Now",
        price: this.price,
        type: "paymentoptions",
        desc: "Buy Now",
        inputtype: "button",
      },
    ];
  }

  // Method to send the car information to the API
  async saveToAPI() {
    try {
      const response = await fetch(
        "https://Digital.webdoc.com.pk/Promotion/public/api/v1/chat/car-insurance/save-quotes",
        {
          method: "POST",
          body: JSON.stringify(this.carInformation),
        }
      );

      if (response.ok) {
        console.log("Data saved successfully", this.getPackageOptions());
        const result = await response.json();
        this.items.text = [
          "Your quotation saved successfully. Please choose your payment option:",
        ];
        this.items.issuccess = true;
        if (this.insType === "thirdparty") {
          this.items.options = this.getTheftOnlyOptions();
        } else {
          this.items.options = this.getPackageOptions();
        }
      } else {
        console.error("Failed to save data");
        this.items.text = ["Your quotation save failed."];
        this.items.issuccess = false;
        if (this.insType === "thirdparty") {
          this.items.options = this.getTheftOnlyOptions();
        } else {
          this.items.options = this.getPackageOptions();
        }
      }
    } catch (error) {
      console.error("Error saving data:", error);
      this.items.text = ["Your quotation save failed."];

      this.items.issuccess = false;
      if (this.insType === "thirdparty") {
        this.items.options = this.getTheftOnlyOptions();
      } else {
        this.items.options = this.getPackageOptions();
      }
    }

    return this.items;
  }
}

export default SaveQuotation;
