class health_insurance {
  constructor() {
    this.items = [
      {
        id: 169,
        name: "Annual Plan",
        badge: "Annual",
        coverage: "100000",
        price: "2000",
        bgColorClass: "bg-info",
        description: `<p><strong>Instant Enrolment:</strong> Secure your health with quick online sign-up for your insurance coverage.</p>
<ul>
<li>I<strong>n-Patient Department (IPD) Coverage:</strong> PKR. 100,000 (Rs. 10,000/day limit)  - For Whole Family including parents.</li>
<li><strong>Inclusive Protection:</strong> Coverage extends to the entire family, including parents.</li>
<li><strong>Unlimited Tele-Doctor Consultations:</strong> Unlimited Tele doctor consultations for whole family!</li>
</ul><p><strong>Seamless Claims:</strong> Experience hassle-free claims with our friendly customer support.</p>`,
        type: "insurance",
      },
      {
        id: 170,
        name: "Annual Plan",
        badge: "Annual",
        coverage: "30000",
        price: "1000",
        bgColorClass: "bg-warning",
        description: `<p><strong>Instant Enrolment:</strong> Secure your health with quick online sign-up for your insurance coverage.</p>
<ul>
<li><strong>In-Patient Department (IPD) Coverage:</strong> PKR, 30,000 (Consumable in one go), Accidental OPD: PKR 5,000</li>
<li><strong>Accidental Out-Patient Department (OBD) Coverage:</strong> Up to PKR 5,000</li>
<li><strong>Unlimited Tele-Doctor Consultations:</strong> Unlimited Tele doctor consultations for whole family!</li>
</ul>
<p><strong>Seamless Claims:</strong> Experience hassle-free claims with our friendly customer support.</p>`,
        type: "insurance",
      },
      {
        id: 171,
        name: "6 Months",
        badge: "Bi Annual",
        coverage: "30000",
        price: "500",
        bgColorClass: "bg-info",
        description: `<p><strong>Instant Enrolment:</strong> Secure your health with quick online sign-up for your insurance coverage.</p>
<ul>
<li><strong>In-Patient Department (IPD) Coverage:</strong> PKR, 30,000 (PKR. 7,000/day limit), Accidental OBD: PKR 5,000</li>
<li><strong>Accidental Out-Patient Department (OPD) Coverage:</strong> Up to PKR 5,000</li>
<li><strong>Unlimited Tele-Doctor Consultations:</strong> Unlimited Tele doctor consultations for whole family!</li>
</ul>
<p><strong>Seamless Claims:</strong> Experience hassle-free claims with our friendly customer support.</p>`,
        type: "insurance",
      },
      {
        id: 172,
        name: "Monthly Plan",
        badge: "Monthly",
        coverage: "10000",
        price: "60",
        bgColorClass: "bg-warning",
        description: `<p><strong>Instant Enrolment:</strong> Secure your health with quick online sign-up for your insurance coverage.</p>
<ul>
<li><strong>In-Patient Department (IPD) Coverage:</strong> PKR. 10,000 (Pro-rated) </li>
<li><strong>Consultation Calls:</strong> 5 consultation calls / month for whole family</li>
</ul>
<p><strong>Seamless Claims:</strong> Experience hassle-free claims with our friendly customer support.</p>`,
        type: "insurance",
      },
      //       {
      //         id: 173,
      //         name: "Daily Plan",
      //         badge: "Daily",
      //         coverage: "10,000",
      //         price: "2",
      //         bgColorClass: "bg-warning",
      //         description: `<p><strong>Instant Enrolment:</strong> Secure your health with quick online sign-up for your insurance coverage.</p>
      // <ul>
      // <li><strong>In-Patient Department (IPD) Coverage:</strong> PKR. 10,000 (Pro-rated) </li>
      // <li><strong>Consultation Calls:</strong> 5 consultation calls / month for whole family</li>
      // </ul>
      // <p><strong>Seamless Claims:</strong> Experience hassle-free claims with our friendly customer support.</p>`,
      //         type: "insurance",
      //       },
    ];

    this.terms = `
    <b style="font-size: 12px;">Disclaimer: </b><small style="font-size: 10px;"> Webdoc in partnership with our underwriter East West insurance company is providing the product. This is a recurring payment model.</small><br></br>
    <p><strong>POLICY TERMS &amp; EXCLUSIONS</strong></p>
<ul>
<li>All plans are auto renew for next <b>5 years</b> or till attainment of maximum covered age of the customer whichever comes earlier.</li>
<li>Pre-existing conditions are not covered.</li>
<li>Cosmetic or surgical procedures are not covered.</li>
<li>Regular medicine &amp; SI(Surgical Instruments) doesn&rsquo;t fall under IPD.</li>
<li>MMC(Major Medical Care) &amp; DD(Dread Disease) cover will apply under IPD admission cover Only.</li>
<li>Cover restricts to IPD, Patient must be admitted at-least <b>24 hours </b> on bed to avail cover.</li>
<li>Day care surgeries or any admission less than <b>24 hours </b> including emergencies are not covered.</li>
<li>No Maternity Cover apply.</li>
<li>Policy can be<strong>&nbsp;reversed within 15 days</strong>&nbsp;after subscription only for <b>6 month</b> and annual plan.</li>
</ul>
<p><strong>DOCTORS CONSULTANCY &amp; TIMING:</strong></p>
<p>Doctors services are available from Monday &ndash; Saturday 9:00am &ndash; 9:00pm.</p>
<p><strong>CLAIMS PAYMENT:</strong></p>
<ul>
<li>Claim payment TAT is 15 working days after complete documents submission.&nbsp;</li>
</ul>
<p><strong>REQUIRED DOCUMENTS:</strong></p>
<ol>
<li>CNIC Copy (Both sides)</li>
<li>Treatment details/Doctor&rsquo;s prescription</li>
<li>Admission &amp; discharge slip</li>
<li>Payment receipt/bills (payment paid by patient/customer in hospital)</li>
<li>Lab reports, if any</li>
<li>MSISDN &amp; Easypaisa account</li>
</ol>
<p><strong>Disclaimer:</strong> This insurance is underwritten by East West Insurance company.</p>
<p>Customer will call on 3737 / 042-111-003-737 / 0518493737 intimate about&nbsp;<strong>claim/complaint</strong>&nbsp;or can email all these documents on whsclaims@webdoc.com.pk</p>`;
  }

  getAllItems() {
    return this.items;
  }

  getTerms() {
    return this.terms;
  }
}

export default health_insurance;
