import React, { useState, useRef, useEffect } from "react";

const HandleMessage = ({ messages, startagain }) => {
  const [expandedMessages, setExpandedMessages] = useState({});
  const messagesEndRef = useRef(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleReadMoreClick = (msgId, paragraphIdx) => {
    setExpandedMessages((prevState) => ({
      ...prevState,
      [msgId]: {
        ...prevState[msgId], // Keep previous state of other paragraphs
        [paragraphIdx]: !prevState[msgId]?.[paragraphIdx], // Toggle the expanded state of the specific paragraph
      },
    }));
  };
  return (
    <>
      {messages.map((msg, index) => (
        <div key={index}>
          {/* this is main image */}
          {msg.img && msg.img !== "" && (
            <div className="row mt-2">
              <div className="col-12 text-center">
                <img
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                  }}
                  className="rounded-circle img-fluid"
                  src={msg.img}
                  alt=""
                />
                <br />
                <strong className="mt-2">{msg.display}</strong>
                <br />
                <small>{msg.position}</small>
              </div>
            </div>
          )}

          <div className="d-flex">
            {/* this is side image on all chats */}
            {!msg.isOption && msg.sender !== "You" && (
              <div className="flex-shrink-0 mt-2">
                <img
                  src={msg.slideimg}
                  alt="customer executive"
                  style={{
                    width: "25px",
                    height: "30px",
                    objectFit: "cover",
                  }}
                  className="rounded-circle img-fluid"
                />
              </div>
            )}

            {/* this is chat started */}
            <div ref={messagesEndRef} />

            <div
              style={{ minWidth: "30%" }}
              className={
                msg.sender !== "You"
                  ? "flex-grow-1 ms-2"
                  : "ms-auto justify-content-end"
              }
            >
              {msg.text &&
                Array.isArray(msg.text) &&
                msg.text.length > 0 &&
                !msg.isOption && (
                  <>
                    {msg.text.map((paragraph, idx) => {
                      // Calculate the length of the current paragraph (without HTML tags)
                      const paragraphLength = paragraph.replace(
                        /<[^>]*>/g,
                        ""
                      ).length;

                      return (
                        <div
                          key={idx}
                          className={`message shadow-sm ${
                            msg.sender === "You" ? "sender" : "receiver"
                          } ${idx !== 0 ? "mt-2" : ""}`}
                        >
                          <div className="message_text">
                            {/* Display sender info only if it's not the receiver */}
                            {msg.sender !== "Receiver" && (
                              <div className="message_sender"></div>
                            )}

                            {/* Render the paragraph with expanded or collapsed class */}
                            <div
                              className={`message-content ${
                                expandedMessages[msg.id || index]?.[idx]
                                  ? "expanded"
                                  : "collapsed"
                              }`}
                              dangerouslySetInnerHTML={{
                                __html:
                                  expandedMessages[msg.id || index]?.[idx] ||
                                  paragraphLength < 240
                                    ? paragraph // Show full text if expanded
                                    : paragraph.substring(0, 240) + "...",
                              }}
                            ></div>
                          </div>

                          {/* Show Read More button if paragraph length exceeds 300 characters and it is not expanded */}
                          {paragraphLength > 240 &&
                            !expandedMessages[msg.id || index]?.[idx] && (
                              <a
                                href="#"
                                className="text-decoration-none read-more-btn"
                                onClick={() =>
                                  handleReadMoreClick(msg.id || index, idx)
                                } // Use both msg.id and paragraph index
                              >
                                Read More
                              </a>
                            )}
                        </div>
                      );
                    })}

                    {/* Show Start Again link if the message has this option */}
                    {msg.backtostart && (
                      <a
                        href="#"
                        className="text-decoration-none"
                        style={{ cursor: "pointer" }}
                        onClick={() => startagain("start")}
                      >
                        Start Again
                      </a>
                    )}
                  </>
                )}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default HandleMessage;
