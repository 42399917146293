import { toWords } from "number-to-words";

class ConfirmPrice {
  constructor(data) {
    // Retrieve insurance type from sessionStorage
    const insType = sessionStorage.getItem("insuranceType");
    this.insType = insType;

    // Get price and calculate market value
    const price = data.price;
    const marketValue = parseFloat(price);
    let insuranceCost;

    // Calculate insurance cost based on the type
    if (insType === "comprehensive") {
      insuranceCost = marketValue * 0.013;
    } else if (insType === "thirdpartytheft") {
      insuranceCost = marketValue * 0.01;
    } else if (insType === "thirdparty") {
      insuranceCost = 5000;
    }

    // Format the insurance cost
    const formattedInsuranceCost =
      Math.round(insuranceCost).toLocaleString("en-US");
    const insuranceCostInWords = Math.round(insuranceCost);

    // Assign items based on the type
    this.items = {
      id: 30001,
      name: "Confirm Price",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: this.getOptions(insType, price),
      backtostart: false,
      text: this.getInsuranceText(
        insType,
        formattedInsuranceCost,
        insuranceCostInWords
      ),
    };
  }

  // Get options dynamically based on the insurance type
  getOptions(insType, price) {
    if (insType === "comprehensive") {
      return [
        {
          id: 40012,
          text: "Confirm",
          name: "Confirm",
          price: `${price}`,
          type: "depreciationmessage",
          desc: "Confirm",
          inputtype: "button",
          key: "carvalue",
          value: `${price}`,
        },
        {
          id: 40013,
          text: "Change Value",
          name: "Change Value",
          price: "",
          type: "vehicleprice",
          desc: "Change Value",
          inputtype: "button",
        },
      ];
    } else if (insType === "thirdparty" || insType === "thirdpartytheft") {
      return [
        {
          id: 40012,
          text: "Confirm",
          name: "Confirm",
          price: `${price}`,
          type: "gapinsurance",
          desc: "Confirm",
          inputtype: "button",
          key: "carvalue",
          value: `${price}`,
        },
        {
          id: 40013,
          text: "Change Value",
          name: "Change Value",
          price: "",
          type: "vehicleprice",
          desc: "Change Value",
          inputtype: "button",
        },
      ];
    }
    // Default case if insType is not recognized
    return [];
  }

  // Get appropriate text based on insurance type
  getInsuranceText(insType, formattedInsuranceCost, insuranceCostInWords) {
    if (insType === "comprehensive") {
      return [
        `<p>Based on the information provided, the premium is Rs. ${formattedInsuranceCost} (Rupees ${insuranceCostInWords} Only).</p>`,
      ];
    } else if (insType === "thirdpartytheft") {
      return [
        `<p>Based on the information provided, the premium is Rs. ${formattedInsuranceCost} (Rupees ${insuranceCostInWords} Only).</p>`,
      ];
    } else if (insType === "thirdparty") {
      return [
        `<p>Based on the information provided, the premium is Rs. ${formattedInsuranceCost} (Rupees ${insuranceCostInWords} Only), with 100K for Car accidental cover and 100K for accidental Health cover.</p>`,
      ];
    }
    // Default case if insType is not recognized
    return [];
  }

  getAllItems() {
    console.log(sessionStorage.getItem("car_information"));
    return this.items;
  }
}

export default ConfirmPrice;
