class getmysavequote {
  constructor() {
    this.items = {
      id: 30001,
      // name: "Start Text",
      // type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      // options: [],
      backtostart: false,
      // text: ["hello"],
    };
  }

  async getAllItems() {
    sessionStorage.removeItem("car_information");
    sessionStorage.removeItem("insuranceType");
    const savedItems = await this.saveToAPI();
    console.log("savedItems", savedItems);
    return savedItems;
  }

  getExistingUserOptions() {
    return [
      {
        id: 40012,
        text: "Searching for Car insurance",
        name: "Searching for Car insurance",
        price: this.price,
        type: "comprehensive",
        desc: "Searching for Car insurance",
        inputtype: "button",
      },
      {
        id: 40012,
        text: "Get my Quote",
        name: "Get my Quote",
        price: this.price,
        type: "getmysavequote",
        desc: "Get my Quote",
        inputtype: "button",
      },
      {
        id: 40012,
        text: "My Policy",
        name: "My Policy",
        price: this.price,
        type: "getmysavedpolicy",
        desc: "My Policy",
        inputtype: "button",
      },
    ];
  }

  // Method to send the car information to the API
  async saveToAPI() {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const data = {
      applicationUserId: user.applicationUserId,
    };

    try {
      const response = await fetch(
        "https://Digital.webdoc.com.pk/Promotion/public/api/v1/chat/car-insurance/get-quotation",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        const result = await response.json();

        if (result.statusCode === 1 && result.payLoad) {
          this.items.options = result.payLoad.map((quote) => ({
            id: quote.id,
            text: `Quote for ${quote.make} ${quote.model}`,
            name: `${quote.make} ${quote.model}`,
            price: quote.carvalue,
            type: "paymentoptions",
            desc: `
            <strong>Make:</strong> ${quote.make} <br> 
            <strong>Model:</strong> ${quote.model} <br> 
            <strong>Type:</strong> ${quote.type} <br> 
            <strong>Year:</strong> ${quote.year} <br> 
            <strong>Value:</strong> ${quote.carvalue} <br> 
            <strong>Quotation:</strong> ${quote.qouteprice} <br> 
            ${
              quote.is_gapinsurance !== null
                ? `<strong>GAP Insurance:</strong> ${
                    quote.is_gapinsurance === "0" ? "No" : "Yes"
                  } <br>`
                : ""
            }
             `,
            inputtype: "button",
            quotetype: quote.type,
            quote: quote,
          }));
          this.items.text = ["Here are your saved quotes"];
        } else {
          this.items.text = ["No quotes found"];
          this.items.options = this.getExistingUserOptions();
        }
      } else {
        this.items.text = ["No quotes found"];
        this.items.options = this.getExistingUserOptions();
      }
    } catch (error) {
      this.items.text = ["No quotes found"];
      this.items.options = this.getExistingUserOptions();
    }

    return this.items;
  }
}

export default getmysavequote;
