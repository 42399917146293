// src/components/Header.js
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa6";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { IoMdRefresh } from "react-icons/io";
import getInsuranceData from "../modals/getInsuranceData";
import InsuranceComponent from "./InsuranceComponent";
const Header_packages_chat = ({ title }) => {
  const sessioncheck = sessionStorage.getItem("user");
  if (!sessioncheck) {
    // const user = {
    //   openId: "5da34d83de18ba92f44040c10b406c8ad4bb1375",
    //   unionId: "5ba759f37b91d2a51f20c014a7c3ba2a504055c7",
    //   userMsisdn: "0345000014",
    //   userName: "Khumash",
    //   applicationUserId: "27C62D9C-AB40-450C-A6CE-7851F47190FA",
    //   // applicationgitUserId: "2F02828E-B0E0-4570-9815-CDFBFB7D6DD0",
    //   userEmail: "03145362496@webdoc.com.pk",
    //   // applicationUserId: "B11000A1-70CA-4E67-A2A1-11DF65FEE0D9",
    //   package: "",
    //   message: "User registered and authenticated successfully  .",
    // };
    // sessionStorage.setItem("user", JSON.stringify(user));
    // console.log(sessionStorage.getItem("user"));
  }

  const [show, setShow] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const type =
    {
      "/vet-doctor-chat": "vets",
      "/agri-doctor-chat": "agri",
      "/car-insurance": "car",
      "/chat": "calladoc",
      "/rsa": "rsa",
    }[pathname] || ""; // Default to "car" if no match
  const { terms } = getInsuranceData(type);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleRefresh = () => {
    //startagainFunc("start");
  };

  // List of paths where the back button should not appear
  const noBackButtonPaths = [
    "/health",
    "/income",
    "/accidental",
    "/car-insurance",
    "/bike",
    "/crop",
    "/womenHealth",
    "/liveStock",
    "/mobile",
    "/vet-doctor-chat",
    "/agri-doctor-chat",
    "/chat",
    "/rsa",
  ];

  return (
    <div className="container-fluid" style={{ backgroundColor: "#1bb367" }}>
      <div className="container">
        <div
          className="row py-2 align-items-center"
          style={{ marginBottom: "-1px" }}
        >
          <div className="col-3">
            {!noBackButtonPaths.includes(location.pathname) && (
              <button
                className="btn btn-link text-white ps-0"
                onClick={handleBackClick}
              >
                <FaChevronLeft size={15} />
              </button>
            )}
          </div>

          {/* <div className="col-3">
            <button
              className="btn btn-link text-white ps-0"
              onClick={handleBackClick}
            >
              <FaChevronLeft size={15} />
            </button>
          </div> */}

          <div className="col-6 text-center  bg-white rounded-pill fw-normal py-1">
            <img
              className="mb-0 custom-heading w-50"
              src="/assets/MiniAppAssets/logo.png"
            ></img>
            {/* <h6 className="mb-0 custom-heading bg-white rounded-pill fw-normal py-1" dangerouslySetInnerHTML={{ __html: title }}></h6> */}
          </div>
          <div className="col-3 my-auto text-end">
          <button
              onClick={handleShow}
              className="text-white text-decoration-none"
              style={{ backgroundColor: "transparent", border: "none" }}
            >
              Help
            </button>
          </div>
          {/* <div className="col-1 my-auto text-end" onClick={handleRefresh}>
         
            <IoMdRefresh  className="mt-2"/>
            
          </div> */}
        </div>
        {showTerms && (
          <Modal
            show={showTerms}
            onHide={() => setShowTerms(false)}
            centered
            className="p-3"
            backdrop="static" // This makes the modal static
          >
            <Modal.Header closeButton>
              <Modal.Title>Terms And Conditions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div dangerouslySetInnerHTML={{ __html: terms }} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowTerms(false)}>
                OK
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">Help</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-1">
          <div
            className="card p-2 text-white text-center"
            style={{ backgroundColor: "#1bb367" }}
          >
            Call Now for Help
            <a
              href="tel:0518493737"
              className="d-block fs-4 fw-bold mt-0 text-decoration-none text-white mb-2"
            >
              0518493737
            </a>
            Or email us at
            <a
              href="mailto:support@webdoc.com.pk"
              className="d-block fw-bold mb-2 text-decoration-none text-white"
            >
              support@webdoc.com.pk
            </a>
            for terms & conditions
            <span
              className="d-block fw-bold mt-0 text-decoration-none text-white"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowTerms(true);
                setShow(false);
              }}
            >
              Click here
            </span>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Header_packages_chat;
