class feedback {
  constructor(data) {
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    this.items = {
      id: 30001,
      name: "Start Text",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40012,
          text: "Excellent",
          name: "Excellent 😊",
          price: "",
          type: "excellent",
          desc: "Excellent",
          key:"feedback"
        },
        {
          id: 40012,
          text: "Satisfactory",
          name: "Satisfactory 🙂",
          price: "",
          type: "good",
          desc: "Satistactory",
          key:"feedback"
        },
        {
          id: 40012,
          text: "Un-Satistactory",
          name: "Un-Satistactory 😞",
          price: "",
          type: "bad",
          desc: "Un-Satistactory",
          key:"feedback"
        },
      ],
      backtostart: false,
      text: [`<p>Great ! Please provide feedback to improve our services</p> `],
    };
  }

  getAllItems() {
    return this.items;
  }
}

export default feedback;
