class recover {
  constructor(data) {
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    this.items = {
      id: 30001,
      name: "Start Text",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40012,
          text: "Workshop",
          name: "Workshop",
          price: "",
          type: "insurance",
          desc: "Workshop",
          key:'want_to_take_it',
        },
        {
          id: 40012,
          text: "Home",
          name: "Home",
          price: "",
          type: "insurance",
          desc: "Home",
          key:'want_to_take_it',
        },
      ],
      backtostart: false,
      text: [`<p>OK , where you want to take it?</p> `],
    };
  }

  getAllItems() {
    return this.items;
  }
}

export default recover;
