class waiting {
    constructor(data) {
      const sessionUser = JSON.parse(sessionStorage.getItem("user"));
      this.items = {
        id: 30001,
        name: "Start Text",
        type: "text",
        slideimg: "/doctorassistant/dia.jpg",
        options: [
         //   { id: 40012,text: "Mechanic arrived",name: "Mechanic arrived",price: "",type: "arrived",desc: "Mechanic arrived"},
            { id: 40012,text: "Share Location",name: "Share Location",price: "",type: "location",desc: "Share Location"},
            ],
        backtostart: true,
        text: [
          // `<p>Is our mechanic arrived?</p> `,
          `<p>Please share your current location</p> `,
        ],
      };
    }
  
    getAllItems() {
      return this.items;
    }
  }
  
  export default waiting;
  