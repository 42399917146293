import apiPayload from "../../components/rsachat/apiPayload";
import { sendEmail } from "../../services/api";
class bad {
  constructor(data) {
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    this.items = {
      id: 30001,
      name: "Start Text",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [],
      backtostart: true,
      text: [
        `<p>We’re sorry to hear that your experience wasn’t up to expectations. Your feedback is valuable, and we’d love the chance to make things right. Please share your concerns with us, and we’ll work to improve.`,
      ],
    };
  }

  async getAllItems() {
    try {
      const sessionUser = JSON.parse(sessionStorage.getItem("user"));
      const chatDetails = JSON.parse(sessionStorage.getItem("RSA-Chat"));
      apiPayload.applicationUserId = sessionUser.applicationUserId;
      apiPayload.misidn = sessionUser.userMsisdn;
      chatDetails.forEach(({ key, value }) => {
        apiPayload[key] = value;
      });
      console.log("API payload Response:>", JSON.stringify(apiPayload));

      const apiUrl =
        "https://Digital.webdoc.com.pk/Promotion/public/api/v1/chat/rsa-insurance/save";
      const response = await fetch(apiUrl, {
        method: "POST",
        body: JSON.stringify(apiPayload),
      });

      try {
        if (!sessionUser) {
          return this.items;
        }

        const message = `
             Feedback on the RSA. So please call the 
             mentioned number and resolve the complaint.
             User Number: ${sessionUser.userMsisdn}
             User Name: ${sessionUser.userName}
           `.trim();

        const response = await sendEmail(
          "support@rsapakistan.com",
          "RSA Complaint",
          message
        );
        console.log("Complaint generated message:", message);
        console.log("Complaint generated successfully:", response);
        return this.items;
      } catch (error) {
        console.error("Failed to generate complaint:", error);
        // You might want to modify this.items to show an error message
        this.items.text =
          "Failed to generate complaint. Please try again later.";
        return this.items;
      }
    } catch (error) {
      console.error("Error sending data to API:", error);
    }

    return this.items;
  }
}

export default bad;
