class insurance {
  constructor(data) {
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    this.items = {
      id: 30001,
      name: "Start Text",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40012,
          text: "YES",
          name: "YES",
          price: "",
          type: "haveinsurance",
          desc: "YES",
          key:'recovery_service',
          value:1
        },
        {
          id: 40012,
          text: "NO",
          name: "NO",
          price: "",
          type: "noNeedInsurance",
          desc: "No, I don't need",
          key:'recovery_service',
          value:0
        },
      ],
      backtostart: false,
      text: [`<p>There is a charge for the recovery. Do you agree </p> `],
    };
  }

  getAllItems() {
    return this.items;
  }
}

export default insurance;
