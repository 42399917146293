import React, { useState } from "react";
import { usePlacesWidget } from "react-google-autocomplete";

const LocationInput = () => {
  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });

  const { ref } = usePlacesWidget({
    apikey: "AIzaSyCTMZHlmNvPYuWVs66cbwuW1XO8O2VWeb4",
    onPlaceSelected: (place) => {
      console.log("places:", place);
      if (!place.geometry) return;
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      // console.log("City:", place);

      const city = place.address_components.find((component) =>
        component.types.includes("locality")
      )?.long_name;
      console.log("City:", city);

      // ✅ Filter only Islamabad & Rawalpindi
      if (city === "Islamabad" || city === "Rawalpindi") {
        setAddress(place.formatted_address);
        setCoordinates({ lat, lng });

        console.log("Selected Address:", place.formatted_address);
        console.log("Latitude:", lat);
        console.log("Longitude:", lng);
      } else {
        alert("Please select a location in Islamabad or Rawalpindi!");
        setAddress("");
        setCoordinates({}); // Clear input if invalid city
      }
    },
    options: {
      types: ["establishment"],
      componentRestrictions: { country: "PK" }, // Restrict to Pakistan
      bounds: {
        east: 73.2, // Extending to the right
        west: 72.85, // Extending to the left
        north: 33.8, // Extending to the top (Islamabad)
        south: 33.45, // Extending to the bottom (Rawalpindi)
      },
      strictBounds: true,
    },
  });
  return (
    <div>
      <input
        ref={ref}
        type="text"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        placeholder="Enter a location"
        className="border p-2 w-full"
      />
      {coordinates.lat && coordinates.lng && (
        <p className="mt-2">
          <strong>Latitude:</strong> {coordinates.lat} <br />
          <strong>Longitude:</strong> {coordinates.lng} <br />
          <strong>Address:</strong> {address}
        </p>
      )}
    </div>
  );
};

export default LocationInput;
