class start {
  constructor(data) {
    if (data) {
      this.type = data.type;
      console.log("data", this.type);
    }
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    this.items = {
      id: 30001,
      name: "Start Text",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [],
      backtostart: false,
      text: [
        `<p>Hello, ${sessionUser.userName}! 👋 Welcome to <strong>WEB<span style="color: #ff0000;">DOC</span></strong>. How can I assist you today?</p> `,
      ],
    };
  }

  async getAllItems() {
    if (this.type === "start") {
      this.items.text = [
        `<p>Great! You are searching for new car insurance</p> `,
      ];
      this.items.options = this.getStartOptions();
      this.items.backtostart = true;
      return this.items;
    } else {
      sessionStorage.removeItem("car_information");
      sessionStorage.removeItem("insuranceType");
      const savedItems = await this.saveToAPI();
      return savedItems;
    }
  }

  // Get package options for payment
  getStartOptions() {
    return [
      {
        id: 40012,
        text: "Comprehensive Car Insurance",
        name: "Comprehensive Car Insurance",
        price: "",
        type: "comprehensive",
        desc: "I’d like to get the Comprehensive Car Insurance",
        key: "type",
        value: "comprehensive",
      },
      {
        id: 40012,
        text: "Third party / Fire & Theft",
        name: "Third party / Fire & Theft",
        price: "",
        type: "thirdpartytheft",
        desc: "I’d like to get the Third party / Fire & Theft",
        key: "type",
        value: "thirdpartytheft",
      },
      {
        id: 40011,
        text: "Third Party Only",
        name: "Third Party Only",
        price: "",
        type: "thirdparty",
        desc: "I’d like to get the Third Party Only",
        key: "type",
        value: "thirdparty",
      },
    ];
  }

  getExistingUserOptions() {
    return [
      {
        id: 40012,
        text: "Searching for Car insurance",
        name: "Searching for Car insurance",
        price: this.price,
        type: "start",
        desc: "Searching for Car insurance",
        inputtype: "button",
      },
      {
        id: 40012,
        text: "Get my Quote",
        name: "Get my Quote",
        price: this.price,
        type: "getmysavequote",
        desc: "Get my Quote",
        inputtype: "button",
      },
      {
        id: 40012,
        text: "My Active Policies",
        name: "My Active Policies",
        price: this.price,
        type: "getmysavedpolicy",
        desc: "My Active Policies",
        inputtype: "button",
      },
      {
        id: 40012,
        text: "My Pending Policies",
        name: "My Pending Policies",
        price: this.price,
        type: "getmypendingpolicy",
        desc: "My Pending Policies",
        inputtype: "button",
      },
    ];
  }

  // Method to send the car information to the API
  async saveToAPI() {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const data = {
      applicationUserId: user.applicationUserId,
    };

    try {
      const response = await fetch(
        "https://digital.webdoc.com.pk/Promotion/public/api/v1/chat/car-insurance/check-status",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        const result = await response.json();

        if (result.payLoad) {
          const { pending_policy, active_policy, quotation } = result.payLoad;
          const options = [];

          // Always include "Searching for Car insurance"
          options.push(this.getExistingUserOptions()[0]);

          if (quotation === "true")
            options.push(this.getExistingUserOptions()[1]); // "Get my Quote"
          if (active_policy === "true")
            options.push(this.getExistingUserOptions()[2]); // "My Policy"
          if (pending_policy === "true")
            options.push(this.getExistingUserOptions()[3]); // "Pending Policy"

          // If no policies match, use default options
          this.items.options =
            options.length > 1 ? options : this.getStartOptions();
        } else {
          console.error("Failed to save data");
          this.items.options = this.getStartOptions();
        }
      } else {
        console.error("Failed to save data");
        this.items.options = this.getStartOptions();
      }
    } catch (error) {
      console.error("Error saving data:", error);
      this.items.text = "Your quotation save failed.";
      this.items.issuccess = false;
      this.items.options = this.getStartOptions();
    }

    return this.items;
  }
}

export default start;
