import React from "react";
import "./header.css";

const Header = (props) => {
  // console.log(props);
  // console.log(props.packageData);
  // const {isLoading, setIsLoading} = useLoading(false);

  // const user = {
  //   openId: "5da34d83de18ba92f44040c10b406c8ad4bb1375",
  //   unionId: "5ba759f37b91d2a51f20c014a7c3ba2a504055c7",
  //   userMsisdn: "03145362496",
  //   userName: "Khumash",
  //   // applicationUserId: "27C62D9C-AB40-450C-A6CE-7851F47190FA",
  //   // applicationUserId: "09ec867f-04ab-4ac2-ac76-896c68cfecab",
  //   applicationUserId: "0d8968b7-65a9-4db6-84c3-8c8b3e67f946",
  //   // applicationUserId: "2F02828E-B0E0-4570-9815-CDFBFB7D6DD0",
  //   // userEmail: "03145362496@webdoc.com.pk",
  //   // userEmail: "03145362496@kashtkaar.com.pk",
  //   userEmail: "03145362496@vetdoctor.com.pk",
  //   // applicationUserId: "B11000A1-70CA-4E67-A2A1-11DF65FEE0D9",
  //   package: "",
  //   message: "User registered and authenticated successfully  .",
  // };

  // sessionStorage.setItem("user", JSON.stringify(user));
  // // console.log(sessionStorage.getItem("user"));

  const userData = JSON.parse(sessionStorage.getItem("user"));
  return (
    <div className="header_bg text-white">
      <div className="container">
        <div className="row pb-1">
          <div className="col-8 my-auto">
            {/* <img
              src="assets/NewMiniapp/webdoc-white.webp"
              alt="Logo"
              className="img-fluid"
              width={100}
            /> */}
            <div className="">
              {props.userSession ? (
                <div className="">
                  <div className="d-flex">
                    <p className="mb-0">Hey, </p>
                    <p className="ms-2 mb-0">
                      {props.userSession.userName} 👋{" "}
                    </p>
                  </div>
                  <p className="mb-0">{props.userSession.userMsisdn}</p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-4 text-end">
            {props.packageData === "notActive" ? (
              <img
                src="assets/NewMiniapp/headerImg.png"
                className="img-fluid"
                width={100}
              />
            ) : (
              <img
                src="assets/NewMiniapp/headerImg2.png"
                className="img-fluid"
                width={100}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
