import React, { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Home1 from "./pages/Home1";
import DoctorsList from "./pages/doctors-list";
import ChatDoctorsListPage from "./pages/chat-doctors-list";
import VideoCall from "./pages/video-call";
import Packages from "./pages/packages";
import PrescriptionsList from "./pages/prescriptions-list";
import ChatPrescription from "./pages/chat-prescription";
import Prescription from "./pages/prescription";
import Swal from "sweetalert2";
import "./App.css";
import { LoadingProvider } from "./contexts/LoadingContext";
import Loader from "./components/loader";
import InsuranceScreen from "./pages/InsuranceScreen";
import PaymentConfirm from "./pages/payment-confirm";
import MyActivePackages from "./pages/my-packages";
import TransactionList from "./pages/transaction-list";
import DoctorProfile from "./pages/doctor-profile";
import StepRangeSlider from "./pages/range-slider";
import PrescriptionList from "./components/PrescriptionList";
// import InsuranceScreenHealth from "./pages/InsuranceScreenHealth";
import Health from "./pages/Health";
import Income from "./pages/Income";
import Accidental from "./pages/Accidental";
import Car from "./pages/Car";
import Bike from "./pages/Bike";
import Crop from "./pages/Crop";
import WomenHealth from "./pages/WomenHealth";
import LiveStock from "./pages/LiveStock";
import Mobile from "./pages/Mobile";
import MonthlyMobile from "./pages/MonthlyMobile";
import PocketSize from "./pages/PocketSize";
import CarInsuranceChat from "./pages/CarInsuranceChat";
import Chat from "./pages/Chat";
import ChatWeb from "./pages/ChatWeb";
import RSAChat from "./pages/rsachat";
import VetDocChat from "./pages/VetDoctorChat";
import AgriDocChat from "./pages/AgriDoctorChat";
import VetDoctorListComponent from "./components/vet-doctor-list-component";
import AgriChatDoctorList from "./components/AgriChatDoctorList";
import MonthlyBikeInsurance from "./pages/MonthlyBikeInsurance";
import CarInsurancePolicyComprehansive from "./pages/CarInsurancePolicyComprehansive";
import CarInsurancePolicyThirdParty from "./pages/CarInsurancePolicyThirdParty";
import CarInsurancePolicyThirdPartyOnly from "./pages/CarInsurancePolicyThirdPartyOnly";
import LocationInput from "./components/location_test";
function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  const ActivePackageFilter = ({ children }) => {
    const session = JSON.parse(sessionStorage.getItem("user"));
    const navigate = useNavigate();

    useEffect(() => {
      if (!session?.package) {
        Swal.fire({
          icon: "info",
          title: "Package Subscription",
          text: "Sorry, you do not have an active subscription. Please subscribe to a package to access.",
          showCancelButton: true,
          confirmButtonText: "Buy Package",
          cancelButtonText: "Back",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/insurancescreen/calladoc/70");
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            navigate(-1);
          }
        });
      }
    }, [session, navigate]);

    return session && session.package ? children : null;
  };

  const ActiveProtectedRoute = ({ children }) => {
    const session = JSON.parse(sessionStorage.getItem("userData"));
    const navigate = useNavigate();

    useEffect(() => {
      if (session?.package) {
        Swal.fire({
          icon: "info",
          title: "Subscription",
          text: "You already have an active subscription.",
          confirmButtonText: "OK",
        }).then(() => {
          navigate(-1);
        });
      }
    }, [session, navigate]);

    return !session?.package ? children : null;
  };

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "Home page";
        metaDescription = "This is home page.";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  // const Auth = () => {
  //   window.location.href = "https://demo.webdoc.com.pk/auth.php";
  //   return null;
  // };

  return (
    <LoadingProvider>
      <Loader />
      <Routes>
        <Route path="/" element={<Home1 />} />
        {/* <Route path="/home" element={<Home />} /> */}
        <Route path="/doctors-list" element={<DoctorsList />} />
        <Route path="/vet-doctors-list" element={<VetDoctorListComponent />} />
        <Route path="/agri-doctors-list" element={<AgriChatDoctorList />} />
        <Route path="/chat-doctors" element={<ChatDoctorsListPage />} />
        <Route
          path="/video-call"
          element={<ActivePackageFilter>{<VideoCall />}</ActivePackageFilter>}
        />
        <Route path="/packages" element={<Packages />} />
        <Route path="/prescriptions-list" element={<PrescriptionsList />} />
        <Route path="/chat-prescription" element={<ChatPrescription />} />
        <Route path="/prescription/:id" element={<Prescription />} />
        <Route
          path="/insurancescreen/:type/:id"
          element={<InsuranceScreen />}
        />
        {/* <Route path="/SubscribedPackage_Details" element={<SubscribedPackage_Details />} /> */}
        <Route path="/confirm" element={<PaymentConfirm />} />
        <Route path="/my-packages" element={<MyActivePackages />} />
        <Route path="/my-transaction" element={<TransactionList />} />
        <Route path="/doctor-profile/:id" element={<DoctorProfile />} />

        <Route path="/range-slider" element={<StepRangeSlider />} />

        {/* <Route path="/product/:type/:id" element={<InsuranceScreenHealth />} /> */}
        <Route path="/health" element={<Health />} />
        <Route path="/income" element={<Income />} />
        <Route path="/accidental" element={<Accidental />} />
        <Route path="/car" element={<Car />} />
        <Route path="/bike" element={<Bike />} />
        <Route path="/crop" element={<Crop />} />
        <Route path="/womenHealth" element={<WomenHealth />} />
        <Route path="/liveStock" element={<LiveStock />} />
        <Route path="/mobile" element={<Mobile />} />
        <Route path="/monthly-mobile" element={<MonthlyMobile />} />
        <Route
          path="/monthly-bike-insurance"
          element={<MonthlyBikeInsurance />}
        />
        <Route path="/pocket" element={<PocketSize />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/doctor-web" element={<ChatWeb />} />
        <Route path="/car-insurance" element={<CarInsuranceChat />} />
        <Route
          path="/car-insurance-policy-paper/:id"
          element={<CarInsurancePolicyComprehansive />}
        />
        <Route
          path="/car-insurance-policy-third-party/:id"
          element={<CarInsurancePolicyThirdParty />}
        />
        <Route
          path="/car-insurance-policy-third-party-only/:id"
          element={<CarInsurancePolicyThirdPartyOnly />}
        />
        <Route path="/rsa" element={<RSAChat />} />
        <Route path="/vet-doctor-chat" element={<VetDocChat />} />
        <Route path="/agri-doctor-chat" element={<AgriDocChat />} />
        <Route path="/address" element={<LocationInput />} />
        <Route path="/prescriptions" element={<PrescriptionList />} />
      </Routes>
    </LoadingProvider>
  );
}

export default App;
