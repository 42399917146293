import { sendEmail } from "../../services/api";
class unstaisfiedinfo {
  constructor(data) {
    console.log(data);
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    this.items = {
      id: 30001,
      name: "Start Text",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      desc: data.inputtext,
      backtostart: true,
      text: [
        `<p>Surely, this will improve our customer experience, Drive safe. </p> `,
      ],
    };
  }

  async getAllItems() {
    try {
      const message = this.items.desc;
      const response = await sendEmail(
        "support@webdoc.com.pk",
        "Car Insurance Complaint",
        message
      );
      console.log("Complaint generated message:", message);
      console.log("Complaint generated successfully:", response);
      return this.items;
    } catch (error) {
      console.error("Failed to generate complaint:", error);
      // You might want to modify this.items to show an error message
      this.items.text = "Failed to generate complaint. Please try again later.";
      return this.items;
    }
  }
}

export default unstaisfiedinfo;
