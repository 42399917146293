import feedback from "../../modals/rsa/feedback";

const apiPayload = {
    "applicationUserId": "",
    "insurance_type": "",
    "instant_type": "",
    "membership_package_id": 1,
    "member_amount": null,
    "issue": "Claim issue",
    "is_location": null,
    "location": "",
    "address": "",
    "want_to_take_it": "",
    "misidn": "",
    "is_fixed": null,
    "duration_days": "",
    "payment_status":''
};
export default apiPayload;