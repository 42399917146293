import React from "react";
import "./advisory1.css";

const Advisory2 = ({ image, title }) => {
  return (
    <div className="text-center d-flex flex-column align-items-center">
      <div className="icon-wrapper d-flex justify-content-center align-items-center">
        <img src={image} className="card-img-top mt-2" alt={title} />
      </div>
      <p className="title">{title}</p>
    </div>
  );
};

export default Advisory2;
