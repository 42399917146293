class getmysavedpolicy {
  constructor(data) {
    this.items = {
      id: 30001,
      // name: "Start Text",
      // type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      // options: [],
      backtostart: false,
      // text: [],
    };
  }

  async getAllItems() {
    sessionStorage.removeItem("car_information");
    sessionStorage.removeItem("insuranceType");
    const savedItems = await this.saveToAPI();
    return savedItems;
  }

  getExistingUserOptions() {
    return [
      {
        id: 40012,
        text: "Searching for Car insurance",
        name: "Searching for Car insurance",
        price: this.price,
        type: "comprehensive",
        desc: "Searching for Car insurance",
        inputtype: "button",
      },
      {
        id: 40012,
        text: "Get my Quote",
        name: "Get my Quote",
        price: this.price,
        type: "getmysavequote",
        desc: "Get my Quote",
        inputtype: "button",
      },
    ];
  }

  // Method to send the car information to the API
  async saveToAPI() {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const data = {
      applicationUserId: user.applicationUserId,
    };

    try {
      const response = await fetch(
        "https://Digital.webdoc.com.pk/Promotion/public/api/v1/chat/car-insurance/get-polices",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log(result);
        if (result.statusCode === 1 && result.payLoad.length > 0) {
          this.items.options = result.payLoad.map((policy) => ({
            id: policy.id,
            text: `Policy for ${policy.car_registration_no}`,
            name: `${policy.car_registration_no}`,
            price: policy.totalamount,
            type: "policypaper",
            desc: `
            <strong>Policy Date:</strong> ${policy.created_at} <br> 
            <strong>Car Registration No:</strong> ${
              policy.car_registration_no
            } <br> 
            <strong>Color:</strong> ${policy.car_color} <br> 
            <strong>Status:</strong> ${policy.status} <br> 
            <strong>Amount Paid:</strong> ${policy.amountpaid} <br> 
            <strong>GAP Insurance:</strong> ${
              policy.is_gapinsurance === "1" ? "Yes" : "No"
            } `,
            inputtype: "button",
          }));
          this.items.text = ["Here are your saved policy"];
        } else {
          this.items.text = ["No policy found"];
          this.items.options = this.getExistingUserOptions();
        }
      } else {
        this.items.text = ["No policy found"];
        this.items.options = this.getExistingUserOptions();
      }
    } catch (error) {
      this.items.text = ["No policy found"];
      this.items.options = this.getExistingUserOptions();
    }

    return this.items;
  }
}

export default getmysavedpolicy;
