class payment {
  constructor() {
    this.items = {
      id: 30003,
      name: "calladoc",
      type: "text",
      options: [],
      backtostart: true,
      text: ``,
      slideimg: "/doctorassistant/dia.jpg",
      issuccess: false,
    };
  }

  async getAllItems(price, pid) {
    const item = sessionStorage.getItem("user");
    const sess = JSON.parse(item);

    const form = new FormData();
    form.append("value", price);
    form.append("apikey", "Doctor");

    const settings = {
      method: "POST",
      body: form,
    };

    try {
      const response = await fetch(
        "https://miniappeasypaisa.webddocsystems.com/public/api/v1/create-payment",
        settings
      );
      const result = await response.json();

      if (result.paymentTransactionID) {
        const paymentStatus = await this.handlePaymentResult(
          result.paymentTransactionID
        );

        if (paymentStatus === "success") {
          const formData = new FormData();
          formData.append("pid", pid);
          formData.append("patientid", sess.applicationUserId);
          formData.append("openId", sess.openId);

          const subscriptionResponse = await fetch(
            "https://miniappeasypaisa.webddocsystems.com/public/api/v1/subscription",
            {
              method: "POST",
              body: formData,
            }
          );

          if (!subscriptionResponse.ok) {
            this.items.text =
              "Payment failed. Please recharge easypaisa account and try again";
            this.items.issuccess = false;
            this.items.options = this.getPackageOptions();
            return this.items;
          }

          const data = await subscriptionResponse.json();
          // Update the items text for success
          this.items.text =
            "<p>Payment successfully completed! Now, let me connect you with the Doctor&rsquo;s Assistant. Please hold on while we set everything up for you. We appreciate your trust in WEBDOC. Your health is our priority! 💙</p>";
          this.items.issuccess = true;
          return this.items;
        } else {
          this.items.text =
            "Payment failed. Please recharge easypaisa account and try again";
          this.items.issuccess = false;
          this.items.options = this.getPackageOptions();
          return this.items;
        }
      }
    } catch (error) {
      console.error("Error:", error);
      this.items.text =
        "Payment failed. Please recharge easypaisa account and try again";
      this.items.issuccess = false;
      this.items.options = this.getPackageOptions();
      return this.items;
    }
  }

  handlePaymentResult = async (paymentTransactionID) => {
    return new Promise((resolve, reject) => {
      try {
        //window.mp.createPayment(paymentTransactionID);

        //window.createPaymentResult = async (jsonResult) => {
          // const result = JSON.parse(jsonResult);
          // const status = result.paymentStatus;

          // if (status === "S") {
            const item = sessionStorage.getItem("user");
            const sess = JSON.parse(item);
            sess["package"] = "true";
            const updatedItem = JSON.stringify(sess);
            sessionStorage.setItem("user", updatedItem);
            resolve("success");
          // } else {
          //   resolve("fail");
          // }
       // };
      } catch (error) {
        reject("fail");
      }
    });
  };

  getPackageOptions() {
    return [
      {
        id: 70,
        text: "1 month package",
        name: "1 Month package",
        price: "150",
        type: "payment",
        desc: "<p>Unlock the ultimate convenience with unlimited audio and video consultations, giving you direct access to expert care &ndash; tailored to fit your busy lifestyle!</p>",
      },
      {
        id: 72,
        text: "12 month package",
        name: "12 Month package",
        price: "999",
        type: "payment",
        desc: "<p>Enjoy unlimited audio and video consultations for your entire family, providing you with expert care, all from the comfort of home!</p>",
      },
    ];
  }
}

export default payment;
