import { sendEmail } from "../../services/api";
class ComplaintEnd {
  constructor(msg) {
    const desc = msg.desc;
    this.complaint = desc;
    this.items = {
      id: 50001,
      name: "About",
      type: "text",
      slideimg: "/doctorassistant/natasha-agri.jpg",
      options: [],
      backtostart: true,
      text: `The complaint has now been generated. Our manager will revert in 48 hours. `,
    };
  }

  async getAllItems() {
    try {
      const session = JSON.parse(sessionStorage.getItem("user"));
      if (!session) {
        return this.items;
      }

      const message = `
          Feedback on the agri expert chat  ${this.complaint} so please call the 
          mentioned number and resolve the complaint.
          User Number: ${session.userMsisdn}
          User Name: ${session.userName}
        `.trim();

      const response = await sendEmail(
        "support@webdoc.com.pk",
        "Agri Expert Complaint",
        message
      );
      console.log("Complaint generated message:", message);
      console.log("Complaint generated successfully:", response);
      return this.items;
    } catch (error) {
      console.error("Failed to generate complaint:", error);
      // You might want to modify this.items to show an error message
      this.items.text = "Failed to generate complaint. Please try again later.";
      return this.items;
    }
  }
}

export default ComplaintEnd;
