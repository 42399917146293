class confirmpayment {
  constructor(data) {
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    this.items = {
      id: data.id,
      name: "Start Text",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      price: data.price,

      options: [
        {
          id: 40012,
          text: "Share Address",
          name: "Share Address",
          price: "",
          type: "location",
          desc: "",
          inputtype: "textinput",
          key: "address",
        },
      ],
      backtostart: false,
      text: [`<p</p> `],
    };
  }

  // getAllItems() {
  //   if (this.isSuccessPayment) {
  //     this.items.text = [
  //       `Thank you! Your payment has been successful.`,
  //       "Please Share your Vehicle registration No!",
  //     ];
  //     let existingMessages = sessionStorage.getItem("RSA-Chat");
  //     existingMessages = JSON.parse(existingMessages);
  //     existingMessages.push(
  //       { key: "duration_days", value: 1 },
  //       { key: "payment_status", value: "Active" }
  //     );
  //     sessionStorage.setItem("RSA-Chat", JSON.stringify(existingMessages));
  //     return this.items;
  //   } else {
  //     this.items.text = [`Add money to your easypaisa account and try again`];
  //     this.items.options = this.retryOptions()
  //   }
  //   return this.items;
  // }

  async getAllItems() {
    const form = new FormData();
    form.append("value", this.items.price);
    form.append("apikey", "RSA");
    const settings = {
      method: "POST",
      body: form,
    };

    try {
      const response = await fetch(
        "https://miniappeasypaisa.webddocsystems.com/public/api/v1/create-payment",
        settings
      );
      const customerId = JSON.parse(sessionStorage.getItem("customer-detail"));
      const result = await response.json();
      const paymentPayload = {
        packageId: String(this.items.id),
        isGift: false,
        transectionId: result.paymentTransactionID,
        amount: this.items.price,
        customerId: customerId,
        bankName: "EP MINIAPP",
        orderId: result.paymentTransactionID,
        type: "Instant",
      };
      // try {
      //   const savepayment = await fetch(
      //     "https://webservice.rsapakistan.com/api/Account/SavePaymentInfo",
      //     {
      //       method: "POST",
      //       headers: {
      //         "Content-Type": "application/json",
      //       },
      //       body: JSON.stringify(paymentPayload),
      //     }
      //   );
      //   const savepaymentinfo = await savepayment.json();

      //   sessionStorage.setItem("payment-info", JSON.stringify(savepaymentinfo));
      //   let existingMessages = JSON.parse(sessionStorage.getItem("RSA-Chat"));
      //   const regNo = existingMessages.find(
      //     (msg) => msg.key === "registration_no"
      //   ).value;
      //   const formData = new FormData();
      //   formData.append("RegistrationNumber", regNo);
      //   formData.append("BuyPackageId", savepaymentinfo.buyPackageId);
      //   formData.append("Make", "unknown");
      //   formData.append("Model", "unknown");
      //   formData.append("Year", "unknown");

      //   try {
      //     await fetch(
      //       "https://webservice.rsapakistan.com/api/Account/ActivatePackage",
      //       {
      //         method: "POST",
      //         body: formData,
      //       }
      //     );
      //     existingMessages.push(
      //       { key: "duration_days", value: 1 },
      //       { key: "payment_status", value: "Active" }
      //     );
      //     sessionStorage.setItem("RSA-Chat", JSON.stringify(existingMessages));
      //     this.items.text = [
      //       `Thank you. Your payment has been successful`,
      //       `No need to worry, we're on our way. If possible, please park to the side. If you're on the roadside, stay in a safe place—your safety is our top priority.`,
      //       ` Please share your location ! `,
      //     ];
      //     return this.items;
      //   } catch (error) {
      //     console.log("registration error", error);
      //     this.items.text = [`something went wrong`];
      //     return this.retryOptions();
      //   }
      // } catch (error) {
      //   console.log("backend api error:", error);
      //   this.options = this.retryOptions();
      //   return this.items;
      // }

      if (result.paymentTransactionID) {
        const paymentStatus = await this.handlePaymentResult(
          result.paymentTransactionID
        );

        if (paymentStatus === "success") {
          try {
            const savepayment = await fetch(
              "https://webservice.rsapakistan.com/api/Account/SavePaymentInfo",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(paymentPayload),
              }
            );
            const savepaymentinfo = await savepayment.json();

            sessionStorage.setItem(
              "payment-info",
              JSON.stringify(savepaymentinfo)
            );
            let existingMessages = JSON.parse(
              sessionStorage.getItem("RSA-Chat")
            );
            const regNo = existingMessages.find(
              (msg) => msg.key === "registration_no"
            ).value;
            const formData = new FormData();
            formData.append("RegistrationNumber", regNo);
            formData.append("BuyPackageId", savepaymentinfo.buyPackageId);
            formData.append("Make", "unknown");
            formData.append("Model", "unknown");
            formData.append("Year", "unknown");

            try {
              await fetch(
                "https://webservice.rsapakistan.com/api/Account/ActivatePackage",
                {
                  method: "POST",
                  body: formData,
                }
              );
              existingMessages.push(
                { key: "duration_days", value: 1 },
                { key: "payment_status", value: "Active" }
              );
              sessionStorage.setItem(
                "RSA-Chat",
                JSON.stringify(existingMessages)
              );
              this.items.text = [
                `Thank you. Your payment has been successful`,
                `No need to worry, we're on our way. If possible, please park to the side. If you're on the roadside, stay in a safe place—your safety is our top priority.`,
                ` Please share your location ! `,
              ];
              return this.items;
            } catch (error) {
              console.log("registration error", error);
              this.items.text = [`something went wrong`];
              return this.retryOptions();
            }
          } catch (error) {
            console.log("backend api error:", error);
            this.items.text = [`backend api error`];

            this.options = this.retryOptions();
            return this.items;
          }
        } else {
          this.items.text = [
            "<p>Payment failed. Please recharge easypaisa account and try again.<p>",
          ];
          this.items.options = this.retryOptions();
          return this.items;
        }
      }
    } catch (error) {
      console.error("Error:", error);
      this.items.text = [
        "Payment failed. Please recharge easypaisa account and try again",
      ];
      this.items.options = this.retryOptions();
      return this.items;
    }
  }

  handlePaymentResult = async (paymentTransactionID) => {
    return new Promise((resolve, reject) => {
      try {
        window.mp.createPayment(paymentTransactionID);

        window.createPaymentResult = async (jsonResult) => {
          const result = JSON.parse(jsonResult);
          const status = result.paymentStatus;
          console.log("createPaymentResult", result);
          if (status === "S") {
            const item = sessionStorage.getItem("user");
            const sess = JSON.parse(item);
            sess["package"] = "true";
            const updatedItem = JSON.stringify(sess);
            sessionStorage.setItem("user", updatedItem);
            resolve("success");
          } else {
            resolve("fail");
          }
        };
      } catch (error) {
        reject("fail");
      }
    });
  };
  retryOptions() {
    return [
      {
        id: 37,
        text: "Retry",
        name: "Retry",
        price: "2000",
        type: "confirmpayment",
        desc: "Retry",
      },
    ];
  }
}

export default confirmpayment;
