class startingchat {
  constructor() {
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    this.items = {
      id: 30001,
      name: "Start Text",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40012,
          text: "Connect to the Doctor",
          name: "Connect to the Doctor",
          price: "",
          type: "doctor",
          desc: "I’d like to connect with a doctor.",
        },
        {
          id: 71000,
          text: "Get Prescription",
          name: "Get Prescription",
          type: "prescriptionList",
          desc: "",
        },
        {
          id: 40012,
          text: "About us",
          name: "About us",
          price: "",
          type: "about",
          desc: "About us",
        },
        {
          id: 40011,
          text: "FAQs",
          name: "FAQ",
          price: "",
          type: "faqs",
          desc: "FAQ",
        },
      ],
      backtostart: false,
      text: `<p>Hello, ${sessionUser.userName}! 👋 Welcome to <strong>WEB<span style="color: #ff0000;">DOC</span></strong>. Let&rsquo;s get you started on your health journey. How can I assist you today?</p> `,
    };
  }

  getAllItems() {
    return this.items;
  }
}

export default startingchat;
