class monthly_bike_insurance {
  constructor() {
    this.items = [
      {
        id: 1249,
        name: "Bike Insurance",
        badge: "Monthly",
        coverage: "50000",
        price: "150",
        bgColorClass: "bg-info",
        description: `<p><strong>Hassle-Free Bike Insurance:</strong> Get covered quickly with our simple online registration process.</p>
<ul>
<li><strong>Insurance cover upto:</strong> PKR 50,000</li>
<li><strong>Accidental Bike Repair:</strong> Up to PKR 15,000</li>
<li><strong>Accidental Hospitalization:</strong> Up to PKR 35,000</li>
<li><strong>RSA Coverage:</strong> Available for Rawalpindi/Islamabad</li>
</ul>
<p><strong>Swift Claims, Superior Support:</strong> Our team ensures a smooth and speedy claims experience.</p>`,
        type: "insurance",
        apiKey: "MonthlyBike",
      },
      {
        id: 1250,
        name: "Bike Insurance",
        badge: "Monthly",
        coverage: "60000",
        price: "300",
        bgColorClass: "bg-warning",
        description: `<p><strong>Hassle-Free Bike Insurance:</strong> Get covered quickly with our simple online registration process.</p>
<ul>
<li><strong>Insurance cover upto:</strong> PKR 60,000</li>
<li><strong>Accidental Bike Repair:</strong> Up to PKR 25,000</li>
<li><strong>Accidental Hospitalization:</strong> Up to PKR 35,000</li>
<li><strong>RSA Coverage:</strong> Available for Rawalpindi / Islamabad</li>
</ul>
<p><strong>Swift Claims, Superior Support:</strong> Our team ensures a smooth and speedy claims experience.</p>`,
        type: "insurance",
        apiKey: "MonthlyBike",
      },
      {
        id: 1251,
        name: "Bike Insurance",
        badge: "Monthly",
        coverage: "100000",
        price: "500",
        bgColorClass: "bg-warning",
        description: `<p><strong>Hassle-Free Bike Insurance:</strong> Get covered quickly with our simple online registration process.</p>
<ul>
<li><strong>Insurance cover upto:</strong> PKR 100,000</li>
<li><strong>Accidental Bike Repair:</strong> Up to PKR 35,000</li>
<li><strong>Accidental Hospitalization:</strong> Up to PKR 65,000</li>
<li><strong>RSA Coverage:</strong> Available for Rawalpindi / Islamabad</li>
</ul>
<p><strong>Swift Claims, Superior Support:</strong> Our team ensures a smooth and speedy claims experience.</p>`,
        type: "insurance",
        apiKey: "MonthlyBike",
      },
    ];

    this.terms = `
    <b style="font-size: 12px;">Disclaimer: </b><small style="font-size: 10px;"> Webdoc in partnership with our underwriter East West insurance company is providing the product. This is a recurring payment model.</small><br></br>
  <ol>
<li><strong>Coverage Limitations:</strong></li>
<ul>
<li>Fire, loss, and theft are excluded from coverage.</li>
<li>Coverage is applicable only in the event of a Road Traffic Accident (RTA), as defined by the terms of this policy.</li>
</ul>
<li><strong>Eligibility Criteria:</strong></li>
<ul>
<li>The policyholder must be a licensed holder.</li>
<li>The policyholder must be the owner of the insured bike.</li>
<li>Age of the policyholder must be between <strong>18 and 59 years.</strong></li>
<li>The insured bike must be used solely for domestic or transportation purposes, excluding involvement in racing or other activities.</li>
</ul>
<li><strong>Exclusions:</strong></li>
<ul>
<li>Excluded perils include war, strikes, riots, civil commotion, and any natural or man-made disasters leading to a declaration of a state of emergency on a local or national level.</li>
<li>Coverage does not extend to preexisting conditions (accidents/damages/hospitalization ) of the insured bike and insurer.</li>
<li>Services related to vehicle modification or customization are not covered.</li>
<li>Routine maintenance services such as oil changes, tire rotations, or battery replacements are excluded.</li>
<li>Vehicles used for commercial purposes, such as Bykea or Careem, etc., are not covered.</li>
<li>Services performed at repair shops or dealerships, including repairs or maintenance, are excluded.</li>
</ul>
<li><strong>Claims Process:</strong></li>
<ul>
<li>All claims must be filed Via Whatsapp within a maximum of <strong>15 days</strong> from the date of the <strong>Road Traffic Accident (RTA).</strong></li>
<li>The following documents are required to raise a claim:</li>
<ul>
<li>Registration Book of the insured bike.</li>
<li>Police report documenting the RTA.</li>
<li>Valid license of the policyholder.</li>
<li>National Identity Card (CNIC) of the policyholder.</li>
<li>Repair receipts where applicable.</li>
<li>Hospital admission, bills, discharge slip, and emergency bills in case of RTA-related injuries.</li>
<li>Upon completion of the required documentation, claims will be processed and paid within <strong>15 days.</strong></li>
</ul>
</ul>
</ol>
`;
  }

  getAllItems() {
    return this.items;
  }

  getTerms() {
    return this.terms;
  }
}

export default monthly_bike_insurance;
