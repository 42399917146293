import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import Header_packages_chat from "../components/Header_package_chat";
import axios from "axios";
import ChatLoader from "../components/ChatLoader";
import HandleMessage from "../components/carinsurance/HandleMessage";
import HandleRsaOptions from "../components/rsachat/HandleOptions";
import CameraComponent from "../components/carinsurance/camera";
import getDataRSA from "../modals/rsa/getRsaModel";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import { checkRSA } from "../services/api";
import getPakistanGreeting from "../components/rsachat/greetingMessage";
const RSAChat = () => {
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [packages, setPackages] = useState();
  const [showCamera, setShowCamera] = useState(false);
  const [userData, setUserData] = useState(null); // Store user data from sessionStorage
  const [sideimage, setSideImage] = useState("/doctorassistant/dia.jpg");
  const [healthData, setHealthData] = useState(null);
  const [error, setError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const openId = params.get("openId");
    if (openId) {
      console.log("OpenId from URL:", openId);
    }
  }, [location]);

  const checkRsaStatus = async (openId) => {
    try {
      const response = await checkRSA(openId);
      setHealthData(response);
      return response;
    } catch (err) {
      console.error("RSA check error:", err);
      setError(err.message);
      return null;
    }
  };

  useEffect(() => {
    const verifyRsaAuth = async () => {
      try {
        const params = new URLSearchParams(location.search);
        const openId = params.get("openId");

        if (!openId) {
          console.log("No openId found, proceeding with authentication");
          authenticateUser();
          return;
        }

        const response = await checkRsaStatus(openId);
        console.log("RSA Status:", response);

        if (response && response.openId) {
          console.log("Valid user data found, setting session");
          sessionStorage.setItem("user", JSON.stringify(response));
          setUserData(response);
          await loadData("start");
        } else {
          console.log(
            "No valid user data found, proceeding with authentication"
          );
          authenticateUser();
        }
      } catch (error) {
        console.error("Error during RSA verification:", error);
        authenticateUser();
      }
    };

    verifyRsaAuth();
  }, [location.search]);

  useEffect(() => {
    let item = {
      id: 1,
      name: "Start Text",
      type: "text",
      options: [
        {
          id: 40012,
          text: "Need RSA services for Car",
          name: "Need RSA services for Car",
          price: "",
          type: "car",
          desc: "I’d like to get the RSA services for Car",
        },
        {
          id: 40012,
          text: "Need RSA services for Bike",
          name: "Need RSA services for Bike",
          price: "",
          type: "car",
          desc: "I’d like to get the RSA services for Bike",
        },
        {
          id: 40011,
          text: "About RSA Pakistan",
          name: "About RSA Pakistan",
          price: "",
          type: "about",
          desc: "I’d like to know about RSA",
        },
      ],
      backtostart: false,
      img: "/doctorassistant/dia.jpg",
      slideimg: "/doctorassistant/dia.jpg",
      display: "Natasha",
      position: "Customer Executive",
      text: [
        `<p>${getPakistanGreeting()}! I&rsquo;m Natasha, your customer executive at <strong><span style='color: #ef7e0e;'>RSA</span></strong>. Give me just a moment while I fetch your details, this won&rsquo;t take long!</p>`,
      ],
    };

    setMessages((prevMessages) => [...prevMessages, item]);
    setLoading(true);
    // The session check is now handled in verifyRsaAuth
  }, []);

  const authenticateUser = () => {
    const scopes = [
      "USER_MSISDN",
      "USER_NAME",
      "OPEN_ID",
      "UNION_ID",
      "USER_BIRTHDAY",
    ];

    const getUserAuthCodeResult = (jsonResult) => {
      const result = JSON.parse(jsonResult);
      try {
        if (result.status === "SUCCESS") {
          const form = new FormData();
          form.append("value", result.authCode);
          form.append("apikey", "RSA");
          // form.append("value", 'eb6fede2361ed929cb0f53f5f643ec06ffc312b0');

          axios
            .post(
              "https://miniappeasypaisa.webddocsystems.com/public/api/v1/rsa-auth",
              form,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              if (response.data.openId) {
                sessionStorage.setItem("user", JSON.stringify(response.data));
                setUserData(response.data);
                setTimeout(async () => {
                  await loadData("start");
                }, 2000);
              } else {
                Swal.fire({
                  icon: "info",
                  allowOutsideClick: false,
                  title: "Something went wrong. Please try again later!",
                  confirmButtonText: "Retry",
                }).then(() => {
                  authenticateUser();
                });
              }
              sessionStorage.setItem("user", JSON.stringify(response.data));
              // setUserData(response.data);
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          Swal.fire({
            allowOutsideClick: false,
            title: "Please Share the information for Services",
            confirmButtonText: "Retry",
          }).then((result) => {
            if (result.isConfirmed) {
              window.mp.getUserAuthCode(scopes);
            } else {
              console.log("User denied the request");
            }
          });
        }
      } catch (error) {
        console.error("Error fetching doctor profile:", error);
      }
    };

    window.mp.getUserAuthCode(scopes);

    window.getUserAuthCodeResult = getUserAuthCodeResult;
  };
  const startagain = async (type) => {
    let item = {
      id: 1,
      name: "Start Text",
      type: "text",
      options: [
        {
          id: 40012,
          text: "Connect to the Doctor",
          name: "Connect to the Doctor",
          price: "",
          type: "doctor",
          desc: "I’d like to connect with a doctor.",
        },
        {
          id: 40012,
          text: "About us",
          name: "About us",
          price: "",
          type: "about",
          desc: "About us",
        },
        {
          id: 40011,
          text: "FAQs",
          name: "FAQ",
          price: "",
          type: "faqs",
          desc: "FAQ",
        },
      ],
      backtostart: false,
      slideimg: "",
      img: "/doctorassistant/dia.jpg",
      slideimg: "/doctorassistant/dia.jpg",
      display: "Natasha",
      position: "Customer Executive",
      text: [
        `<p>${getPakistanGreeting()}! I&rsquo;m Natasha, your customer executive at <strong><span style='color: #ef7e0e;'>RSA</span></strong>. Give me just a moment while I fetch your details, this won&rsquo;t take long!</p>`,
      ],
    };

    setMessages([]); // Clears all messages
    setMessages((prevMessages) => [...prevMessages, item]);
    await loadData(type, null);
  };
  const loadData = async (type, data) => {
    const newMessage = await getDataRSA(type, data);
    // console.log(" calling getAllItems - Price:", newMessage);

    setMessages((prevMessages) => [...prevMessages, newMessage.modal]);
    setLoading(false);

    if (newMessage.modal.options && newMessage.modal.options.length > 0) {
      newMessage.modal.options.forEach((option) => {
        option.isOption = true;
        option.sender = "Receiver";
        setMessages((prevMessages) => [...prevMessages, option]);
      });
    }
    // console.log(messages);
  };

  const handleOptionSelect = async (msg) => {
    setLoading(true); // Show loader first
    let existingMessages = sessionStorage.getItem("RSA-Chat");
    existingMessages = existingMessages ? JSON.parse(existingMessages) : [];
    if (msg.key && msg.name !== "Share Location") {
      const existingIndex = existingMessages.findIndex(
        (item) => item.key === "duration_days"
      );
      let value = msg.value;

      if (msg.key === "member_amount") value = msg.price;
      else if (
        !["is_fixed", "recovery_service", "duration_days"].includes(msg.key)
      )
        value = msg.name;
      if (msg.key === "duration_days" && existingIndex !== -1) {
        existingMessages[existingIndex].value = value; // Update existing
      } else {
        existingMessages.push({ key: msg.key, value, id: msg.id }); // Add new entry
      }

      sessionStorage.setItem("RSA-Chat", JSON.stringify(existingMessages));
    }

    setMessages((prevMessages) => prevMessages.filter((msg) => !msg.isOption));
    if (msg.type === "opencamera") {
      setShowCamera(true);
      setLoading(false);
    } else {
      // Remove all option messages before doing anything

      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "You", text: [msg.desc] },
      ]);

      setTimeout(() => {
        loadData(msg.type, msg);
      }, 2000); // 2 second delay
    }
  };

  const handleTextSubmit = async (msg, text) => {
    setLoading(true);
    if (msg.inputtype === "textinput") {
      const existingMessages = JSON.parse(sessionStorage.getItem("RSA-Chat"));
      if (msg.key === "address") {
        existingMessages.push({
          key: msg.key,
          value: text,
          coordinates: msg.coords,
        });
      } else existingMessages.push({ key: msg.key, value: text });
      sessionStorage.setItem("RSA-Chat", JSON.stringify(existingMessages));
    }
    // Store updated messages in sessionStorage
    console.log("msg", msg);
    msg.desc = text;
    setMessages((prevMessages) => prevMessages.filter((msg) => !msg.isOption));
    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: "You", text: [text] },
    ]);
    setTimeout(() => {
      loadData(msg.type, msg);
    }, 2000);
  };
  return (
    <>
      <Header_packages_chat title="Chat" />
      <Container>
        <Row>
          <Col className="chat_container" md={12}>
            <div className="chat_window pt-2">
              <div className="messages">
                <HandleMessage messages={messages} startagain={startagain} />
                <>
                  <HandleRsaOptions
                    messages={messages}
                    handleOptionSelect={handleOptionSelect}
                    handleTextSubmit={handleTextSubmit}
                  />
                </>
                {showCamera && (
                  <CameraComponent
                    messages={messages}
                    setMessages={setMessages}
                    setShowCamera={setShowCamera}
                    setLoading={setLoading}
                  />
                )}
                {loading && <ChatLoader />}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RSAChat;
