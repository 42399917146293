class callend {
  constructor() {
    this.items = {
      id: 32000,
      name: "Precription",
      type: "text",
      slideimg: "/doctorassistant/natasha.jpg",
      options: [
        {
          id: 71000,
          text: "Get Prescription",
          name: "Get Prescription",
          type: "consultation",
          desc: "",
        },
        {
          id: 71001,
          text: "Connect to Doctor Again",
          name: "Connect to Doctor Again",
          type: "againconnect",
          desc: "",
        },
      ],
      backtostart: true,
      text: `I hope you had a smooth and helpful consultation with the doctor leaving you feeling confident and cared for. Would you like me to get your prescription or need to connect the doctor again`,
    };
  }

  getAllItems(patientemail, doctoremail) {
    // let link='https://webdoc.com.pk/webcalling/ptcl/consultation.php?patientemail='+patientemail+'&DoctorEmail='+doctoremail+'&location=Miniapp';
    // this.items.options[0].desc='Get the prescription from the below link </br> <a target="_blank" href="'+link+'">Get Prescription</a>';
    // return this.items;

    return {
      modal: this.items,
    };
  }
}

export default callend;
