import React, { useState, useEffect } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";

const TextInputOption = ({ msg, handleTextSubmit }) => {
  const [text, setText] = useState("");
  const [error, setError] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateInput()) {
      handleTextSubmit(msg, text);
      setText("");
      setError("");
    }
  };

  const validateInput = () => {
    if (msg.type === "vehiclemake" && typeof text !== "string") {
      setError("Please enter a valid vehicle make.");
      return false;
    }
    if (msg.type === "vehiclemodel" && typeof text !== "string") {
      setError("Please enter a valid vehicle model.");
      return false;
    }
    if (msg.type === "confirmprice") {
      const price = Number(text); // Convert text to number
      if (isNaN(price) || price < 2000000 || price > 200000000) {
        setError("Please enter a price between 2,000,000 and 200,000,000.");
        return false;
      }
    }
    if (msg.type === "vehicalcolor" && typeof text !== "string") {
      setError("Please enter a valid price.");
      return false;
    }
    if (msg.type === "providecnic" && typeof text !== "string") {
      setError("Please enter valid value.");
      return false;
    }
    if (msg.type === "providecnicConfirm" && !/^\d{13}$/.test(text)) {
      setError("Please enter a valid 13-digit CNIC number.");
      return false;
    }

    return true;
  };

  let placeholder = "Enter text";
  let intype = "text";
  if (msg.type === "vehiclemake") {
    placeholder = "Enter your vehicle make";
    intype = "text";
  } else if (msg.type === "vehiclemodel") {
    placeholder = "Enter your vehicle model";
    intype = "text";
  } else if (msg.type === "confirmprice") {
    placeholder = "Enter your vehicle price";
    intype = "number";
  } else if (msg.type === "vehicalcolor") {
    placeholder = "Example: BU 17 / 1234567";
    intype = "text";
  } else if (msg.type === "providecnic") {
    placeholder = "Please enter color of your vehicle";
    intype = "text";
  } else if (msg.type === "policy") {
    placeholder = "Please enter your email address";
    intype = "email";
  } else if (msg.type === "providecnicConfirm") {
    placeholder = "13 Digit: Example: 1234567890123";
    intype = "number";
  } else if (msg.type === "vehicalregistrationConfirm") {
    placeholder = "Please enter the registration no";
    intype = "text";
  } else if (msg.type === "vehicalcolorConfirm") {
    placeholder = "Please enter your vehical color";
    intype = "text";
  } else placeholder = "Enter text";

  return (
    <div style={{ overflowY: "scroll", padding: "20px" }}>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3 mx-2">
          <InputGroup>
            <Form.Control
              type={intype}
              placeholder={placeholder}
              value={text}
              // onChange={(e) => setText(e.target.value)}
              onChange={(e) => {
                if (msg.type === "providecnicConfirm") {
                  const regex = /^[A-Za-z0-9 -]{0,13}$/;
                  if (regex.test(e.target.value)) {
                    setText(e.target.value);
                  }
                } else {
                  setText(e.target.value);
                }
              }}
              required
            />
            <Button
              variant="secondary"
              style={{ backgroundColor: "#1bb367" }}
              className="border-0 chatOptions"
              type="submit"
            >
              Submit
            </Button>
          </InputGroup>
          {error && <div style={{ color: "red" }}>{error}</div>}
        </Form.Group>
      </Form>
    </div>
  );
};

export default TextInputOption;
